import React, { useEffect, useState } from 'react'
import { TModalComponent } from '~components/Common/Modal/Modal'
import EditableParamsModal from '~components/Edition/Common/EditableParamsModal/EditableParamsModal'
import { TEditableVideo } from '../EditableVideo.types'

import './EditableVideoParamsModal.scss'

const EditableVideoParamsModal: TModalComponent<{
	onChange: (data: TEditableVideo) => void
}> = ({ payload, onChange, ...modalProps }) => {
	const [data, setData] = useState<TEditableVideo>(payload)

	useEffect(() => {
		if (modalProps.isOpen) {
			setData(payload)
		} else {
			setData(null)
		}
	}, [modalProps.isOpen])

	const handleConfirm = () => {
		onChange(data)
		modalProps.close()
	}

	return (
		<EditableParamsModal handleConfirm={handleConfirm} {...modalProps}>
			<EditableParamsModal.Field
				type="input"
				label="URL de la vidéo (Youtube, Vimeo, Dailymotion)"
				value={data?.url}
				placeholder="Youtube, Vimeo, Dailymotion"
				onChange={(url) => {
					setData({
						...data,
						url
					})
				}}
			/>

			<EditableParamsModal.Field
				type="input"
				label="Description de la vidéo (SEO)"
				value={data?.params?.description}
				placeholder="Ex : Pot de confiture maison"
				onChange={(description) => {
					setData({
						...data,
						params: {
							...data.params,
							description
						}
					})
				}}
			/>
		</EditableParamsModal>
	)
}

export default EditableVideoParamsModal
