import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import SectionPreviewImage from '~sections/common/SectionPreviewImage'

import { TSectionDefinition } from '~types/Section.types'
import RestaurantClassicSection from './RestaurantClassicSection'
import RestaurantClassicSectionParams from './RestaurantClassicSection.params'
import defaultData from './RestaurantClassicSection.defaultData'

import { TRestaurantClassicSection } from './RestaurantClassicSection.types'

const restaurantClassicSection: TSectionDefinition<TRestaurantClassicSection> = {
	name: 'restaurant-classic-section',
	Params: RestaurantClassicSectionParams,
	Component: RestaurantClassicSection,
	categories: ['restaurant'],
	presets: [
		{
			imagePreview: <PreviewImage name="restaurant" />,
			defaultData
		}
	]
}

export default restaurantClassicSection

export const query = graphql`
	query {
		restaurant: file(relativePath: { eq: "section-preview/restaurant.PNG" }) {
			...SectionPreviewImage
		}
	}
`

function PreviewImage({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
