/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React from 'react'
import ToggleButton from '~components/Common/Basics/ToggleButton/ToggleButton'
import FacebookIcon from '~components/Template/common/FaceBookIcon'
import InstagramIcon from '~components/Template/common/InstagramIcon'
import TwitterIcon from '~components/Template/common/TwitterIcon'
import { YoutubeIcon } from '~components/Template/common/YoutubeIcon'
// import useWebsite from '~hooks/useWebsite'
// import { getActivityContact } from '~queries/activity.queries'
import { linkRegex } from '~utils/misc'
import './SocialEditor.scss'

type SocialEditorProps = {
	social: boolean
	setSocial: (soc: boolean) => void
	socials: any
	setSocials: (soc: any) => void
	label?: string
}

const SocialEditor: React.FC<SocialEditorProps> = ({
	social,
	setSocial,
	socials,
	setSocials,
	label = 'Afficher les réseaux sociaux dans le header'
}) => {
	const handler = (key: string) => ({
		value: socials?.[key],
		onChange: (e) => {
			setSocials({
				...socials,
				[key]: e.target.value
			})
		}
	})
	// const website = useWebsite()

	// useEffect(() => {
	// 	if (
	// 		social &&
	// 		!socials?.facebook &&
	// 		!socials?.twitter &&
	// 		!socials?.instagram
	// 	) {
	// 		getActivityContact(website?.activitiesId?.[0]).then((res) => {
	// 			const socials = res.contacts?.socials
	// 			setSocials({
	// 				facebook: socials?.facebook || '',
	// 				twitter: socials?.twitter || '',
	// 				instagram: socials?.instagram || '',
	// 				linkedIn: ''
	// 			})
	// 		})
	// 	}
	// }, [social])

	const isLink = (key) => {
		return !socials?.[key]?.trim() || linkRegex.test(socials?.[key])
	}

	return (
		<div className="SocialEditor" style={{ marginTop: '6px' }}>
			<div>
				<div
					style={{
						display: 'flex',
						alignItems: 'center'
					}}
				>
					<ToggleButton value={social} onChange={setSocial} />
					<span
						style={{
							marginLeft: 8
						}}
					>
						{label}
					</span>
				</div>
			</div>
			{social && (
				<div className="linksInput">
					<label htmlFor="fbk">
						<FacebookIcon color="#172b49" />
						<input
							type="text"
							name="fbk"
							id="fbk"
							placeholder="URL Facebook"
							{...handler('facebook')}
							className={isLink('facebook') ? '' : 'bad'}
						/>
					</label>
					<label htmlFor="insta">
						<InstagramIcon color="#172b49" />
						<input
							type="text"
							name="insta"
							id="insta"
							placeholder="URL Instagram"
							{...handler('instagram')}
							className={isLink('instagram') ? ' ' : 'bad'}
						/>
					</label>
					<label htmlFor="insta">
						<TwitterIcon color="#172b49" />
						<input
							type="twitter"
							name="twitter"
							id="twitter"
							placeholder="URL Twitter"
							{...handler('twitter')}
							className={isLink('twitter') ? '' : 'bad'}
						/>
					</label>
					<label htmlFor="insta">
						<YoutubeIcon color="#172b49" />
						<input
							type="youtube"
							name="youtube"
							id="youtube"
							placeholder="URL Youtube"
							{...handler('youtube')}
							className={isLink('youtube') ? '' : 'bad'}
						/>
					</label>
				</div>
			)}
		</div>
	)
}

export default SocialEditor
