import React, { useState } from 'react'
import ThemeSelector from '~components/Common/ThemeSelector/ThemeSelector'
import { TSectionTheme } from '~types/Website.types'
import SectionEditableParamsImage from '../SectionEditableParamsImage/SectionEditableParamsImage'

import { postEditorMessage } from '~contexts/page/pageContext'

import './SectionEditableParamsConfig.scss'

const SectionEditableParamsConfig: TSectionEditableParamsConfig = ({
	isOpen,
	children,
	position
}) => {
	return (
		<div
			className={`SectionEditableParamsConfig ${
				isOpen ? 'active' : ''
			} position-${position}`}
		>
			{children}
		</div>
	)
}

export const SectionEditableParamsConfigLeft: TSectionEditableParamsConfigLeft = ({
	isOpen,
	imageUrl,
	onChange,
	setIsLoading
}) => {
	return (
		<SectionEditableParamsConfig position="left" isOpen={isOpen}>
			<div className="content">
				<SectionEditableParamsImage
					value={imageUrl}
					onChange={onChange}
					setIsLoading={setIsLoading}
				/>
			</div>
		</SectionEditableParamsConfig>
	)
}

export const SectionEditableParamsConfigRight: TSectionEditableParamsConfigRight = ({
	isOpen,
	handleChangeTheme,
	children,
	currentTheme
}) => {
	const [currentTab, setCurrentTab] = useState<TTabName>('general')

	const matchTab = (name: TTabName) => ({
		className: `${currentTab === name ? 'active' : ''}`,
		onClick: () => {
			setCurrentTab(name)
		}
	})
	const tabIsGeneral = currentTab === 'general'
	return (
		<SectionEditableParamsConfig position="right" isOpen={isOpen}>
			<div className="tabs">
				<button {...matchTab('general')}>Général</button>
				<button {...matchTab('theme')}>Theme</button>
			</div>

			<div className={'content' + (tabIsGeneral ? ' nopad' : '')}>
				{tabIsGeneral ? (
					<div className="custom-params">{children}</div>
				) : (
					<>
						<ThemeSelector
							value={currentTheme}
							handleChange={handleChangeTheme}
						/>

						<a
							className="theme-admin-link"
							onClick={() => {
								postEditorMessage('GOTO', {
									url: '/admin/apparence/mes-themes'
								})
							}}
							style={{
								cursor: 'pointer'
							}}
						>
							Ajouter / modifier un thème
						</a>
					</>
				)}
			</div>
		</SectionEditableParamsConfig>
	)
}

type TTabName = 'general' | 'theme'

type TSectionEditableParamsConfigLeft = React.FC<{
	isOpen: boolean
	imageUrl: string
	onChange: (imageUrl: string) => void
	setIsLoading: (isLoading: boolean) => void
}>

type TSectionEditableParamsConfigRight = React.FC<{
	isOpen: boolean
	handleChangeTheme: (theme: TSectionTheme) => void
	currentTheme: string
}>

type TSectionEditableParamsConfig = React.FC<{
	isOpen: boolean
	position: 'left' | 'right'
}>
