import React from 'react'

import Params from '~components/Edition/ParamsPresets/Params/Params'

import { TRestaurantSectionParamsComponent } from './RestaurantSection.types'

const RestaurantSectionParams: TRestaurantSectionParamsComponent = () => {
	return (
		<Params className="RestaurantSectionParams">
			Add here Params edition stuff.
		</Params>
	)
}

export default RestaurantSectionParams
