import { editableButton } from '~components/Edition/Editables/EditableButton/EditableButton'
import { editableElement } from '~components/Edition/Editables/EditableElement/EditableElement'
import { editableText } from '~components/Edition/Editables/EditableText/EditableText'
import { generateLoremIpsum } from '~utils/misc'
import { TListTitleTextButtonSection } from './ListTitleTextButtonSection.types'

const ListTitleTextButtonSectionDefaultData: TListTitleTextButtonSection = {
	data: {
		title: editableElement('Dites-le avec le cœur'),
		items: [
			{
				title: editableElement('Inspirez'),
				text: editableText(generateLoremIpsum('big'))
			},
			{
				title: editableElement('Inspirez'),
				text: editableText(generateLoremIpsum('big'))
			},
			{
				title: editableElement('Inspirez'),
				text: editableText(generateLoremIpsum('big'))
			},
			{
				title: editableElement('Inspirez'),
				text: editableText(generateLoremIpsum('big'))
			},
			{
				title: editableElement('Inspirez'),
				text: editableText(generateLoremIpsum('big'))
			},
			{
				title: editableElement('Inspirez'),
				text: editableText(generateLoremIpsum('big'))
			},
			{
				title: editableElement('Inspirez'),
				text: editableText(generateLoremIpsum('big'))
			},
			{
				title: editableElement('Inspirez'),
				text: editableText(generateLoremIpsum('big'))
			}
		],
		button: editableButton('Bouton')
	},
	params: {
		sectionHeight: 'medium',
		sectionWidth: 'large',
		alignText: 'center',
		alignHorizontal: 'center',
		alignVertical: 'center',
		designLayout: 'basic'
	}
}

export default ListTitleTextButtonSectionDefaultData
