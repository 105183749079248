import {
	TBoutiqueSectionData,
	TBoutiqueSectionParams
} from './BoutiqueSection.types'
import { TSectionDefaultData } from '~types/Section.types'

const BoutiqueSectionDefaultData: TSectionDefaultData<
	TBoutiqueSectionData,
	TBoutiqueSectionParams
> = {
	data: {
		// Write here default data
		marketItems: []
	},
	params: {
		// Write here default params
		categories: [],
		marketItems: [],
		type: null
	}
}

export default BoutiqueSectionDefaultData
