import React from 'react'
import Button from '~components/Common/Button/Button'

import './EditableParamsModalActions.scss'

const EditableParamsModalActions: TEditableParamsModalActions = ({
	handleCancel,
	handleConfirm
}) => {
	return (
		<div className="EditableParamsModalActions">
			<Button
				theme="light"
				textAlign="center"
				className="cancel"
				onClick={handleCancel}
			>
				Retour
			</Button>
			<Button
				theme="blue"
				textAlign="center"
				className="save"
				onClick={handleConfirm}
			>
				Valider
			</Button>
		</div>
	)
}

export default EditableParamsModalActions

type TEditableParamsModalActions = React.FC<{
	handleCancel: () => void
	handleConfirm: () => void
}>
