import { editableElement } from '~components/Edition/Editables/EditableElement/EditableElement'
import { editableText } from '~components/Edition/Editables/EditableText/EditableText'
import { editableVideo } from '~components/Edition/Editables/EditableVideo/EditableVideo'
import { generateLoremIpsum } from '~utils/misc'
import { TVideoTitleTextSection } from './VideoTitleTextSection.types'

const VideoTitleTextSectionDefaultData: TVideoTitleTextSection = {
	data: {
		title: editableElement('Dites-le avec le cœur'),
		text: editableText(generateLoremIpsum('normal')),
		video: editableVideo('https://www.dailymotion.com/video/x5ik4n9')
	},
	params: {
		videoPosition: 'bottom',
		sectionHeight: 'medium',
		sectionWidth: 'large',
		alignText: 'center',
		alignHorizontal: 'center',
		alignVertical: 'center'
	}
}

export default VideoTitleTextSectionDefaultData
