import { useState } from 'react'

import { getUrlParams } from '~utils/misc'

export type TBoutiqueFilters = {
	search: string
	budget: {
		min: number
		max: number
	}
	who: string[]
	gender: string[]
	category: string
}

const filterToPath = (filters: TBoutiqueFilters) => {
	const query = []

	if (filters.search) query.push(`s=${filters.search}`)
	if (filters.category) query.push(`c=${filters.category}`)
	if (filters.budget.min) query.push(`min=${filters.budget.min}`)
	if (filters.budget.max) query.push(`max=${filters.budget.max}`)
	if (filters.who.length) query.push(`who=${filters.who.join(',')}`)

	window.history.replaceState(
		null,
		null,
		document.location.pathname + '?' + query.join('&')
	)
}

const pathToFilters = () => {
	const params = getUrlParams()

	const filters: TBoutiqueFilters = {
		search: params.s || '',
		budget: {
			min: params.min ? parseInt(params.min, 10) : null,
			max: params.max ? parseInt(params.max, 10) : null
		},
		who: params.who ? params.who.split(',') : [],
		gender: [],
		category: params.c || null
	}

	return filters
}

export const useBoutiqueFilters = (): [
	TBoutiqueFilters,
	(TBoutiqueFilters) => void
] => {
	const [filters, setFilters] = useState<TBoutiqueFilters>(pathToFilters())

	const updateFilters = (update) => {
		const data = {
			...filters,
			...update
		}

		filterToPath(data)
		setFilters(data)
	}

	return [filters, updateFilters]
}
