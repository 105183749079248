import React, { useState } from 'react'
import AddSectionButton from './AddSectionButton/AddSectionButton'
import AddSectionModal from './AddSectionModal/AddSectionModal'
import SectionEditableParams from './SectionEditableParams/SectionEditableParams'
import { useModalWithPayload } from '~components/Common/Modal/Modal'
import { TSectionEditableProps } from './SectionEditable.types'
import sectionMap from '~core/sections'
import { TSectionDefinition } from '~types/Section.types'
import { useGlobalContext } from '~contexts/globalContext'
import { TSectionTheme } from '~types/Website.types'

import useIsMounted from '~hooks/useIsMounted'

import { usePageContext } from '~contexts/page/pageContext'

import './SectionEditable.scss'
import SectionContext from './SectionContext/SectionContext'

const SectionEditable = ({
	section,
	onChange,
	handleAddBefore,
	handleAddAfter,
	handleMoveSectionDown,
	handleMoveSectionUp,
	canMoveDown,
	canMoveUp,
	handleRemove
}: TSectionEditableProps) => {
	const globalContext = useGlobalContext()
	const addSectionModal = useModalWithPayload<any>()
	const pageContext = usePageContext()

	const definition: TSectionDefinition<any> = sectionMap[section.type]

	const handleChangeTheme = (theme: TSectionTheme) => {
		onChange(
			{
				params: {
					...section.params,
					theme: theme.id
				}
			},
			true
		)

		pageContext.refresh()
	}

	const currentTheme = section.params?.theme

	const { getPaletteColor, getSectionTheme } = globalContext.website

	const _theme = getSectionTheme(currentTheme)

	const theme: TSectionTheme = {
		..._theme,
		title: getPaletteColor(_theme?.title),
		text: getPaletteColor(_theme?.text),
		background: getPaletteColor(_theme?.background),
		button: {
			background: getPaletteColor(_theme?.button?.background),
			color: getPaletteColor(_theme?.button?.color)
		}
	}

	return (
		<div
			className="SectionEditable"
			style={{ position: 'relative' }}
			id={section.id}
		>
			{definition ? (
				<SectionContext sectionId={section.id} onChange={onChange}>
					<definition.Component
						section={section}
						onChange={onChange}
						globalContext={globalContext}
						theme={theme}
					/>
				</SectionContext>
			) : (
				<div
					style={{
						textAlign: 'center',
						padding: '60px 0',
						border: '4px solid #ff2e48',
						color: '#ff2e48',
						fontWeight: 500
					}}
				>
					MODULE INCONNU
				</div>
			)}
			<AddSectionButton
				position="top"
				onClick={() => {
					addSectionModal.open({ position: -1 })
				}}
			/>
			<AddSectionButton
				position="bottom"
				onClick={() => {
					addSectionModal.open({ position: 1 })
				}}
			/>

			<SectionEditableParams
				handleMoveSectionDown={handleMoveSectionDown}
				handleMoveSectionUp={handleMoveSectionUp}
				canMoveDown={canMoveDown}
				canMoveUp={canMoveUp}
				handleChangeTheme={handleChangeTheme}
				params={section.params}
				onChangeParams={(params) => {
					onChange({ ...section, params }, true)
					pageContext.refresh()
				}}
				currentTheme={currentTheme}
			>
				{definition?.Params && (
					<definition.Params
						params={section.params}
						onChange={(params) => {
							onChange(
								{
									params
								},
								true
							)
							// pageContext.refresh()
						}}
					/>
				)}
				<SectionEditableDefaultParams handleRemove={handleRemove} />
			</SectionEditableParams>
			<AddSectionModal
				{...addSectionModal}
				onSelect={(section) => {
					const { position } = addSectionModal.payload

					addSectionModal.close()

					if (position === -1) {
						handleAddBefore(section)
					} else {
						handleAddAfter(section)
					}

					window.requestAnimationFrame(() => {
						document.getElementById(section.id).scrollIntoView({
							behavior: 'smooth'
						})
					})
				}}
			/>
		</div>
	)
}

export default SectionEditable

const SectionEditableDefaultParams = ({ handleRemove }) => {
	const [confirmDelete, setConfirmDelete] = useState(false)

	const isMounted = useIsMounted()

	return (
		<div className="SectionEditableDefaultParams">
			<button
				onClick={() => {
					if (confirmDelete) {
						handleRemove()
					} else {
						setConfirmDelete(true)
						setTimeout(() => {
							if (!isMounted()) return
							setConfirmDelete(false)
						}, 3000)
					}
				}}
				className="delete"
			>
				{confirmDelete ? 'Confirmer la suppression ?' : 'Supprimer'}
			</button>
		</div>
	)
}
