import { editableButton } from '~components/Edition/Editables/EditableButton/EditableButton'
import { editableElement } from '~components/Edition/Editables/EditableElement/EditableElement'
import { generateLoremIpsum } from '~utils/misc'
import { TTitleButtonSection } from './TitleButtonSection.types'

const TitleTextButtonSectionDefaultData: TTitleButtonSection = {
	data: {
		title: editableElement(generateLoremIpsum('normal')),
		button: editableButton('Bouton')
	},
	params: {
		backgroundImage:
			'https://res.cloudinary.com/duopq85lp/image/upload/v1617901871/ovidiu-cozma-sr90ZsVcJjo-unsplash_mn8txy.png',
		sectionHeight: 'medium',
		sectionWidth: 'medium',
		alignText: 'center',
		alignVertical: 'center',
		alignHorizontal: 'center'
	}
}

export default TitleTextButtonSectionDefaultData
