import React, { useState } from 'react'
import Basket from '../Basket/Basket'
import './BannerBasket.scss'

const BannerBasket = () => {
	const [showBasket, setShowBasket] = useState(false)
	return (
		<div className="BannerBasket">
			<div className="bannerBasket--container">
				<div className="bannerBasket--content">
					{/* <div className="basket-empty">
						<span>Votre panier est vide</span>
					</div> */}

					<div className="bannerBasket--toggle">
						<span
							className="baskeToggle__button"
							onClick={() => setShowBasket(!showBasket)}
						>
							Votre panier - 20€
							<i
								className={`mdi mdi-chevron-${showBasket ? 'down' : 'up'}`}
							></i>
						</span>
						<Basket active={showBasket} />
					</div>
					<span className="bannerBasket__valid">Valider</span>
				</div>
			</div>
		</div>
	)
}

export default BannerBasket
