import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SectionPreviewImage from '~sections/common/SectionPreviewImage'
import { TSectionDefinition } from '~types/Section.types'
import LastArticlesSection from './LastArticlesSection'
import LastArticlesSectionParams from './LastArticlesSection.params'
import defaultData from './LastArticlesSection.defaultData'
import { TLastArticlesSection } from './LastArticlesSection.types'

export const lastArticlesSectionName = 'last-articles-section'

const lastArticlesSection: TSectionDefinition<TLastArticlesSection> = {
	name: lastArticlesSectionName,
	Component: LastArticlesSection,
	Params: LastArticlesSectionParams,
	categories: ['ecommerce'],
	presets: [
		{
			label: 'Les dernières offres',
			imagePreview: <HeaderSectionPreview />,
			defaultData
		}
	]
}

export default lastArticlesSection

export const query = graphql`
	query {
		file(relativePath: { eq: "section-preview/last-articles.png" }) {
			...SectionPreviewImage
		}
	}
`

function HeaderSectionPreview({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
