import {
	TPartenaireSectionData,
	TPartenaireSectionParams
} from './PartenaireSection.types'
import { TSectionDefaultData } from '~types/Section.types'
import { editableText } from '~components/Edition/Editables/EditableText/EditableText'
import { editableElement } from '~components/Edition/Editables/EditableElement/EditableElement'
import { generateLoremIpsum } from '~utils/misc'
import { editableImage } from '~components/Edition/Editables/EditableImage/EditableImage'
import { v4 as uuidv4 } from 'uuid'

const PartenaireSectionDefaultData: TSectionDefaultData<
	TPartenaireSectionData,
	TPartenaireSectionParams
> = {
	data: {
		title: editableElement('Dites-le avec le cœur'),
		text: editableText(generateLoremIpsum('normal')),
		items: [
			{
				id: uuidv4(),
				image: editableImage(
					'https://res.cloudinary.com/duopq85lp/image/upload/v1617959618/Logo_m2x8lx.png'
				)
			},
			{
				id: uuidv4(),
				image: editableImage(
					'https://res.cloudinary.com/duopq85lp/image/upload/v1617959618/Logo_m2x8lx.png'
				)
			},
			{
				id: uuidv4(),
				image: editableImage(
					'https://res.cloudinary.com/duopq85lp/image/upload/v1617959618/Logo_m2x8lx.png'
				)
			}
		]
	},
	params: {
		sectionHeight: 'medium',
		sectionWidth: 'medium',
		alignText: 'center',
		alignVertical: 'center',
		alignHorizontal: 'center'
	}
}

export default PartenaireSectionDefaultData
