import React from 'react'

import './ToggleButton.scss'

const ToggleButton: TToggleButton = ({ value, onChange }) => {
	return (
		<button
			className={`ToggleButton ${value ? 'active' : ''}`}
			onClick={() => {
				onChange?.(!value)
			}}
		>
			<input
				type="checkbox"
				checked={value}
				readOnly
				onChange={() => {
					//
				}}
			/>
		</button>
	)
}

export default ToggleButton

type TToggleButton = React.FC<{
	value: boolean
	onChange: (value: boolean) => void
}>
