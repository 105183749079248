import React from 'react'
import { TSectionDefinition } from '~types/Section.types'
import PartenaireSection from './PartenaireSection'
import PartenaireSectionParams from './PartenaireSection.params'
import defaultData from './PartenaireSection.defaultData'

import { TPartenaireSection } from './PartenaireSection.types'
import { graphql, useStaticQuery } from 'gatsby'
import SectionPreviewImage from '~sections/common/SectionPreviewImage'

const partenaireSection: TSectionDefinition<TPartenaireSection> = {
	name: 'partenaire-section',
	Params: PartenaireSectionParams,
	Component: PartenaireSection,
	categories: ['partenaire'],
	presets: [
		{
			imagePreview: <ImagePreview name="A" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					text: null
				}
			}
		},
		{
			imagePreview: <ImagePreview name="B" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					text: null
				},
				params: {
					...defaultData.params,
					alignText: 'left',
					alignHorizontal: 'left'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="C" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					text: null
				},
				params: {
					...defaultData.params,
					alignText: 'right',
					alignHorizontal: 'right'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="D" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params
				}
			}
		},
		{
			imagePreview: <ImagePreview name="E" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					alignText: 'left',
					alignHorizontal: 'left'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="F" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					alignText: 'right',
					alignHorizontal: 'right'
				}
			}
		}
	]
}

export default partenaireSection

export const query = graphql`
	query {
		A: file(relativePath: { eq: "section-preview/partenaire-center.PNG" }) {
			...SectionPreviewImage
		}
		B: file(relativePath: { eq: "section-preview/partenaire-left.PNG" }) {
			...SectionPreviewImage
		}
		C: file(relativePath: { eq: "section-preview/partenaire-right.PNG" }) {
			...SectionPreviewImage
		}
		D: file(
			relativePath: { eq: "section-preview/partenaire-text-center.PNG" }
		) {
			...SectionPreviewImage
		}
		E: file(relativePath: { eq: "section-preview/partenaire-text-left.PNG" }) {
			...SectionPreviewImage
		}
		F: file(relativePath: { eq: "section-preview/partenaire-text-right.PNG" }) {
			...SectionPreviewImage
		}
	}
`

function ImagePreview({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
