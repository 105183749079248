import { Link } from '@reach/router'
import React from 'react'
import './MenuRestaurant.scss'

const MenuRestaurant = () => {
	return (
		<div className={`MenuRestaurant`}>
			<div className="menuRestaurant--container">
				<div className="menuRestaurant--content">
					<Link to="" className="menuRestaurant__item active">
						Nos entrées
					</Link>
					<Link to="" className="menuRestaurant__item">
						Nos plats
					</Link>
					<Link to="" className="menuRestaurant__item">
						Nos desserts 🍩
					</Link>
				</div>
			</div>
		</div>
	)
}

export default MenuRestaurant
