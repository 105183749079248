/*
 * List all sections.
 * Used to display sections & section edition.
 * /!\ WARNING, YOU ARE NOT SUPPOSED TO EDIT THIS FILE.
 * To create, rename or delete sections, please use this command :
 *     npx coq
 */

import headerSection from '~sections/HeaderSection/HeaderSection.definition'
// import instagramSection from '~sections/InstagramSection/InstagramSection.definition'
import lastArticlesSection from '~sections/LastArticlesSection/LastArticlesSection.definition'
import titleTextButtonSection from '~sections/TitleTextButtonSection/TitleTextButtonSection.definition'
import titleButtonSection from '~sections/TitleButtonSection/TitleButtonSection.definition'
import titleTextImageButtonSection from '~sections/TitleTextImageButtonSection/TitleTextImageButtonSection.definition'
import titleTextImageButtonBasicSection from '~sections/TitleTextImageButtonBasicSection/TitleTextImageButtonBasicSection.definition'
import listTitleTextImageButtonSection from '~sections/ListTitleTextImageButtonSection/ListTitleTextImageButtonSection.definition'
import listDecorationTitleTextImageButtonSection from '~sections/ListDecorationTitleTextImageButtonSection/ListDecorationTitleTextImageButtonSection.definition'
import listTitleTextSection from '~sections/ListTitleTextSection/ListTitleTextSection.definition'
import galerieSection from '~sections/GalerieSection/GalerieSection.definition'
import contactSection from '~sections/ContactSection/ContactSection.definition'
import textSection from '~sections/TextSection/TextSection.definition'
import citationTextSection from '~sections/CitationTextSection/CitationTextSection.definition'
import citationTitleTextSection from '~sections/CitationTitleTextSection/CitationTitleTextSection.definition'
import citationTextTextSection from '~sections/CitationTextTextSection/CitationTextTextSection.definition'
import listTitleTextButtonSection from '~sections/ListTitleTextButtonSection/ListTitleTextButtonSection.definition'
import citationTextImageSection from '~sections/CitationTextImageSection/CitationTextImageSection.definition'
import videoTitleTextSection from '~sections/VideoTitleTextSection/VideoTitleTextSection.definition'
import partenaireSection from '~sections/PartenaireSection/PartenaireSection.definition'
import restaurantSection from '~sections/RestaurantSection/RestaurantSection.definition'
import boutiqueSection from '~sections/BoutiqueSection/BoutiqueSection.definition'
import articleSection from '~sections/ArticleSection/ArticleSection.definition'
import restaurantClassicSection from '~sections/RestaurantSection/RestaurantClassicSection/RestaurantClassicSection.definition'
// DO NOT REMOVE THIS LINE.

const sectionList = [
	headerSection,
	// instagramSection,
	lastArticlesSection,
	titleTextButtonSection,
	titleButtonSection,
	titleTextImageButtonSection,
	titleTextImageButtonBasicSection,
	listTitleTextImageButtonSection,
	listDecorationTitleTextImageButtonSection,
	listTitleTextSection,
	contactSection,
	textSection,
	citationTextSection,
	citationTitleTextSection,
	citationTextTextSection,
	listTitleTextButtonSection,
	citationTextImageSection,
	videoTitleTextSection,
	galerieSection,
	partenaireSection,
	restaurantSection,
	restaurantClassicSection,
	boutiqueSection,
	videoTitleTextSection,
	articleSection
	// DO NOT REMOVE THIS LINE NEITHER.
]

export default sectionList
	.filter((cur) => !!cur)
	.reduce((acc, cur) => {
		return {
			...acc,
			[cur.name]: cur
		}
		return null
	}, {})
