import React from 'react'

const ContactPinIcon = ({ color = '#F4F5FD', bgColor = '#172B49' }) => (
	<svg width="30" height="30" viewBox="0 0 30 30" fill="none" className="icon">
		<path
			d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
			fill={bgColor}
		/>
		<path
			d="M14.9044 6.5C11.7798 6.5 9.23779 9.042 9.23779 12.1667C9.23779 15.1893 14.4184 22.076 14.6391 22.368C14.7011 22.4513 14.8005 22.5 14.9045 22.5C15.0085 22.5 15.1078 22.4513 15.1698 22.368C15.3905 22.076 20.5712 15.1893 20.5712 12.1667C20.5711 9.042 18.0291 6.5 14.9044 6.5ZM14.9044 15.1667C13.2504 15.1667 11.9044 13.8207 11.9044 12.1667C11.9044 10.5127 13.2504 9.16666 14.9044 9.16666C16.5584 9.16666 17.9044 10.5127 17.9044 12.1667C17.9044 13.8207 16.5584 15.1667 14.9044 15.1667Z"
			fill={color}
		/>
	</svg>
)
export default ContactPinIcon
