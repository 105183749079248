import React, { useState } from 'react'
// import CheckBox from '~components/Common/CheckBox/CheckBox'
import { useConfirmationModalContext } from '~components/Common/Modal/ConfirmationModal/ConfirmationModal'
import Modal from '~components/Common/Modal/Modal'
import ThemeSelector from '~components/Common/ThemeSelector/ThemeSelector'
import Footer from '~components/Template/Footer/Footer'
import { useGlobalContext } from '~contexts/globalContext'
import { TWebsiteThemeFooter } from '~types/Website.types'
import MenuEditor from '../Common/MenuEditor/MenuEditor'
import {
	menuIsValid,
	useMenuEditor
} from '../Common/MenuEditor/MenuEditor.hooks'
import SocialEditor from '../Common/SocialEditor/SocialEditor'
import './FooterEditionModal.scss'
import { ValidationErrorPopup } from '../Common/ValidationErrorPopup/ValidationErrorPopup'
import ToggleButton from '~components/Common/Basics/ToggleButton/ToggleButton'

const FooterEditionModal = (props) => {
	const globalContext = useGlobalContext()
	const footer = globalContext.website?.theme?.footer
	const [menus, setMenus] = useState(footer?.nav)

	const [validationError, setValidationError] = useState(false)

	const [showSocialLinks, setShowSocialLinks] = useState(
		footer?.showSocialLinks
	)
	const [socials, setSocials] = useState(globalContext.website?.socials)
	const [params, setParams] = useState<TWebsiteThemeFooter>(footer)
	const [copyright, setCopyright] = useState(footer?.copyright || 'FIALURE')
	const [showNewsLetter, setShowNewsLetter] = useState<boolean>(
		footer?.showNewsLetter || true
	)

	const [theme, setTheme] = useState<any>(
		globalContext.website.getSectionTheme(params.footerTheme)
	)

	const maxMenus = 3
	return (
		<Modal
			{...props}
			className="FooterEditionModal"
			allowQuitOnClickOverlay={false}
		>
			<ValidationErrorPopup
				show={validationError}
				setShow={setValidationError}
			/>
			<div className="main">
				<Footer
					data={{
						showSocialLinks,
						showNewsLetter,
						socials,
						menus,
						copyright,
						theme
					}}
					editionOverlay={false}
					disableLinks
				/>

				<div className="parameters">
					<div>
						<h4>Nom du copyright</h4>
						<label>
							Text
							<input
								type="text"
								className="cpr_input"
								placeholder="Copyright"
								value={copyright}
								onChange={(e) => setCopyright(e.target.value)}
							/>
						</label>
					</div>
					<div className="select_theme">
						<div>
							<h4>Choix du thème</h4>
							<ThemeSelector
								value={theme}
								handleChange={(theme) => {
									setTheme(theme)
									setParams({
										...params,
										footerTheme: theme.id
									})
								}}
							/>
						</div>
					</div>
				</div>
				<div>
					<h4>Newsletter et réseaux sociaux</h4>
					<div
						style={{
							display: 'flex',
							alignItems: 'center'
						}}
					>
						<ToggleButton
							value={showNewsLetter}
							onChange={(value) => {
								setShowNewsLetter(value)
							}}
						/>
						<span
							style={{
								marginLeft: 8
							}}
						>
							Permettre aux visiteurs de laisser leur mail pour la newsletter
						</span>
					</div>

					<SocialEditor
						social={showSocialLinks}
						socials={socials}
						setSocial={setShowSocialLinks}
						setSocials={setSocials}
						label="Afficher les réseaux sociaux dans le footer"
					/>
				</div>

				<div className="hr"></div>

				{menus.map((menu, i) => (
					<FooterMenuEditor
						key={i}
						menu={menu}
						onChange={(men) => {
							menus[i] = men
							setMenus([...menus])
						}}
						onDelete={() => {
							menus.splice(i, 1)
							setMenus([...menus])
						}}
						showErrors={validationError}
					/>
				))}

				{menus.length < maxMenus && (
					<button
						className="btn_rubrique"
						onClick={() => {
							menus.push()
							setMenus([...menus, { label: '', items: [] }])
						}}
					>
						+ Nouvelle rubrique
					</button>
				)}
			</div>
			<div className="buttons">
				<button
					className="retour"
					onClick={() => {
						props?.close?.()
					}}
				>
					Retour
				</button>
				<button
					onClick={() => {
						let menusAreValid = true
						menus.forEach((navItem) => {
							if (!menuIsValid(navItem.items) || !navItem.label?.trim())
								menusAreValid = false
						})
						if (menusAreValid) {
							globalContext.website.update({
								socials,
								theme: {
									...globalContext.website.theme,
									footer: {
										...params,
										showSocialLinks,
										nav: menus,
										copyright,
										showNewsLetter
									}
								}
							})
							props.close()
						} else {
							setValidationError(true)
						}
					}}
				>
					Valider
				</button>
			</div>
		</Modal>
	)
}

export default FooterEditionModal

const FooterMenuEditor = ({ menu, onChange, onDelete, showErrors }) => {
	const editor = useMenuEditor(menu.items, (items) => {
		onChange({
			...menu,
			items
		})
	})
	const confirmationModal = useConfirmationModalContext()

	return (
		<MenuEditor menuEditor={editor} maxSubMenu={1} showErrors={showErrors}>
			<div className="menu_label">
				<input
					type="text"
					value={menu.label}
					className={showErrors && !menu.label ? 'bad' : ''}
					placeholder="Nom de la rubrique..."
					onChange={(e) => {
						onChange({
							...menu,
							label: e.target.value
						})
					}}
				/>

				<button
					onClick={() => {
						confirmationModal?.open({
							title: 'Suppression',
							description: 'Êtes-vous sûr de vouloir cela ?',
							acceptCallback: onDelete,
							theme: 'danger',
							acceptText: 'Supprimer la rubrique',
							refuseText: 'Annuler'
						})
					}}
				>
					Supprimer la rubrique
				</button>
			</div>
		</MenuEditor>
	)
}
