import React from 'react'
import Container from '~components/Common/Container/Container'
import { TTitleTextImageButtonSectionComponent } from './TitleTextImageButtonSection.types'
import EditableElement from '~components/Edition/Editables/EditableElement/EditableElement'
import EditableButton from '~components/Edition/Editables/EditableButton/EditableButton'
import EditableImage from '~components/Edition/Editables/EditableImage/EditableImage'
import EditableText from '~components/Edition/Editables/EditableText/EditableText'

import './TitleTextImageButtonSection.scss'
import { generateClassNames } from '~utils/misc'

const TitleTextImageButtonSection: TTitleTextImageButtonSectionComponent = ({
	section,
	theme
}) => {
	const {
		sectionHeight,
		sectionWidth,
		alignText,
		alignVertical,
		imagePosition,
		designLayout,
		alignHorizontal
	} = section.params

	return (
		<div
			className={`Section TitleTextImageButtonSection  ${generateClassNames({
				imagePosition,
				designLayout,
				alignText,
				sectionHeight,
				sectionWidth,
				alignHorizontal,
				alignVertical
			})}`}
			style={{
				background: theme?.background,
				color: theme?.text
			}}
		>
			<Container>
				<div className="content-wrapper">
					<div className="__content">
						<div className="image-content">
							<EditableImage field="image" />
						</div>
						<div className="image-card-wrapper">
							<div className="image-card">
								<EditableElement
									style={{
										color: theme?.title
									}}
									field="title"
									tag="h1"
								/>

								<EditableText field="text" />

								<EditableButton theme={theme?.button} field="button" />
							</div>
						</div>
					</div>
				</div>
			</Container>
		</div>
	)
}

export default TitleTextImageButtonSection
