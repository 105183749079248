import {
	TRestaurantSectionData,
	TRestaurantSectionParams
} from './RestaurantSection.types'
import { TSectionDefaultData } from '~types/Section.types'

const RestaurantSectionDefaultData: TSectionDefaultData<
	TRestaurantSectionData,
	TRestaurantSectionParams
> = {
	data: {
		// Write here default data
	},
	params: {
		// Write here default params
	}
}

export default RestaurantSectionDefaultData
