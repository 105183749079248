import React from 'react'
import Container from '~components/Common/Container/Container'

import { Link } from '~components/Sections/common/SectionLink'
import EditableImage from '~components/Edition/Editables/EditableImage/EditableImage'
import { usePageContext } from '~contexts/page/pageContext'
import EditableElement from '~components/Edition/Editables/EditableElement/EditableElement'

import { THeaderSectionComponent } from './HeaderSection.types'

import EditableText from '~components/Edition/Editables/EditableText/EditableText'
import { generateClassNames, getUrlLink } from '~utils/misc'

import './HeaderSection.scss'
import { useGlobalContext } from '~contexts/globalContext'

const HeaderSection: THeaderSectionComponent = ({ section, theme }) => {
	const { sections } = section.data
	const { sectionHeight, sectionWidth, alignVertical } = section.params

	return (
		<div
			className={`HeaderSection ${generateClassNames({
				sectionHeight,
				sectionWidth,
				alignVertical
			})}`}
			style={{
				backgroundColor: theme?.background,
				color: theme?.text
			}}
		>
			<div className="headerSection-content">
				<header>
					<Container>
						<div className="_content">
							<EditableElement field="title" tag="h1" />
							<EditableText field="subtitle" />
						</div>
					</Container>
				</header>

				<div className="columns">
					{sections.map((column, index) => (
						<Section key={index} section={column} index={index} />
					))}
				</div>
			</div>
		</div>
	)
}

export default HeaderSection

const Section = ({ section, index }) => {
	const pageContext = usePageContext()
	return (
		<React.Fragment>
			{pageContext.editMode ? (
				<div className="column">
					<div className="image-container">
						<EditableImage field={`sections.${index}.image`} />
					</div>
					<div className="button">
						<EditableElement field={`sections.${index}.title`} tag="span" />
					</div>
				</div>
			) : section.image?.params?.actionType === 'external-link' ? (
				<a href={getUrlLink(section.image.params.link)} className="column">
					<div className="image-container">
						<EditableImage field={`sections.${index}.image`} />
					</div>
					<div className="button">
						<EditableElement field={`sections.${index}.title`} tag="span" />
					</div>
				</a>
			) : section.image?.params?.actionType === 'internal-link' ? (
				<InternalLink
					pageId={section.image.params.pageLinkId}
					field={`sections.${index}`}
				/>
			) : (
				<Link to={section.link} className="column">
					<div className="image-container">
						<EditableImage field={`sections.${index}.image`} />
					</div>
					<div className="button">
						<EditableElement field={`sections.${index}.title`} tag="span" />
					</div>
				</Link>
			)}
		</React.Fragment>
	)
}

const InternalLink = ({ pageId, field }) => {
	const globalContext = useGlobalContext()
	const page = globalContext.website.getPage(pageId)

	return (
		<Link to={`/${page?.slug}`} className="column">
			<div className="image-container">
				<EditableImage field={field + '.image'} />
			</div>
			<div className="button">
				<EditableElement field={field + '.title'} tag="span" />
			</div>
		</Link>
	)
}
