import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SectionPreviewImage from '~sections/common/SectionPreviewImage'

import CitationTextImageSection from './CitationTextImageSection'
import defaultData from './CitationTextImageSection.defaultData'
import CitationTextImageSectionParams from './CitationTextImageSection.params'
import { TCitationTextImageSection } from './CitationTextImageSection.types'
import { TSectionDefinition } from '~types/Section.types'
import { editableImage } from '~components/Edition/Editables/EditableImage/EditableImage'

const citationTextImageSection: TSectionDefinition<TCitationTextImageSection> = {
	name: 'citation-text-image-section',
	Component: CitationTextImageSection,
	Params: CitationTextImageSectionParams,
	categories: ['citation'],
	presets: [
		{
			imagePreview: <ImagePreview name="citationTextImage" />,
			defaultData
		},
		{
			imagePreview: <ImagePreview name="citationTextImageRight" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					image: editableImage(
						'https://images.unsplash.com/photo-1597638620263-dd007c83ce3e?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
					)
				},
				params: {
					...defaultData.params,
					imagePosition: 'right'
				}
			}
		},

		{
			imagePreview: <ImagePreview name="citationTextImageCollege" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					image: editableImage(
						'https://images.unsplash.com/photo-1505392017323-4fc611cf62cc?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
					)
				},
				params: {
					...defaultData.params,
					designLayout: 'collage',
					alignText: 'left',
					titleTag: 'h3'
				}
			}
		},

		{
			imagePreview: <ImagePreview name="citationTextImageCollegeRight" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					image: editableImage(
						'https://images.unsplash.com/photo-1505392017323-4fc611cf62cc?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
					)
				},
				params: {
					...defaultData.params,
					designLayout: 'collage',
					imagePosition: 'right',
					alignText: 'left',
					titleTag: 'h3'
				}
			}
		}
	]
}

export default citationTextImageSection

export const query = graphql`
	query {
		citationTextImage: file(
			relativePath: { eq: "section-preview/citationTextImage.PNG" }
		) {
			...SectionPreviewImage
		}
		citationTextImageRight: file(
			relativePath: { eq: "section-preview/citationTextImageRight.PNG" }
		) {
			...SectionPreviewImage
		}
		citationTextImageCollege: file(
			relativePath: { eq: "section-preview/citationTextImageCollege.PNG" }
		) {
			...SectionPreviewImage
		}
		citationTextImageCollegeRight: file(
			relativePath: { eq: "section-preview/citationTextImageCollegeRight.PNG" }
		) {
			...SectionPreviewImage
		}
	}
`

function ImagePreview({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
