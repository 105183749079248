import React from 'react'

const BurgerIcon = ({ color = "#172B49"}) => {
	return (
		<svg
			width="24"
			height="11"
			viewBox="0 0 24 11"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				y="7.81958"
				width="16.8197"
				height="2.58764"
				rx="1.29382"
				fill={color}
			/>
			<rect
				y="0.703613"
				width="23.2888"
				height="2.58764"
				rx="1.29382"
				fill={color}
			/>
		</svg>
	)
}

export default BurgerIcon
