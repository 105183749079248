import React from 'react'

import BoutiqueProductList from './BoutiqueProductList/BoutiqueProductList'
// import BoutiqueServiceList from './BoutiqueServiceList/BoutiqueServiceList'

import './BoutiqueResult.scss'

const BoutiqueResult = ({ items, type, loading }) => {
	if (loading) {
		return (
			<div className="loading">
				<div>
					<i className="mdi mdi-loading"></i>
				</div>
			</div>
		)
	}

	return type == 'product' ? (
		<BoutiqueProductList products={items} />
	) : (
		<BoutiqueProductList products={items} />
	)
}
export default BoutiqueResult
