import { editableElement } from '~components/Edition/Editables/EditableElement/EditableElement'
import { editableImage } from '~components/Edition/Editables/EditableImage/EditableImage'
import { editableText } from '~components/Edition/Editables/EditableText/EditableText'
import { TArticleSectionBlockType } from './ArticleSectionBlock/ArticleSectionBlock'
import { editableSeparator } from '~components/Edition/Editables/EditableSeparator/EditableSeparator'
import { editableButton } from '~components/Edition/Editables/EditableButton/EditableButton'

export const getEditableInitializerFromType = (
	type: TArticleSectionBlockType
) => {
	if (type === 'editable-text') return editableText()
	if (type === 'editable-element') return editableElement()
	if (type === 'editable-image')
		return editableImage(
			'https://coq-trotteur-prod.s3.eu-west-3.amazonaws.com/res/coq-trotteur/image/upload/coq-trotteur-og/1601043108.jpg'
		)

	if (type === 'editable-button') return editableButton()
	if (type === 'editable-separator') return editableSeparator()
	if (type === 'editable-vertical-spacing')
		return {
			...editableSeparator(),
			showRule: false
		}

	return null
}
