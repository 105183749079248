import React, { useEffect } from 'react'
import './ValidationErrorPopup.scss'

type ValidationErrorPopupProps = {
	show: boolean
	setShow: (val: boolean) => void
}

export const ValidationErrorPopup: React.FC<ValidationErrorPopupProps> = ({
	show,
	setShow
}) => {
	useEffect(() => {
		if (show) {
			setTimeout(() => {
				setShow(false)
			}, 8000)
		}
	}, [show])

	if (!show) return <></>
	return (
		<div className="ValidationErrorPopup">
			Certains champs semblent incorrects
		</div>
	)
}
