import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import SectionPreviewImage from '~sections/common/SectionPreviewImage'

import { TSectionDefinition } from '~types/Section.types'
import RestaurantSection from './RestaurantSection'
import RestaurantSectionParams from './RestaurantSection.params'
import defaultData from './RestaurantSection.defaultData'

import { TRestaurantSection } from './RestaurantSection.types'

const restaurantSection: TSectionDefinition<TRestaurantSection> = {
	name: 'restaurant-section',
	Params: RestaurantSectionParams,
	Component: RestaurantSection,
	categories: ['restaurant'],
	presets: [
		{
			imagePreview: <PreviewImage name="restaurant" />,
			defaultData
		}
	]
}

export default restaurantSection

export const query = graphql`
	query {
		restaurant: file(relativePath: { eq: "section-preview/restaurant.PNG" }) {
			...SectionPreviewImage
		}
	}
`

function PreviewImage({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
