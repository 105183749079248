import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SectionPreviewImage from '~sections/common/SectionPreviewImage'

import { TSectionDefinition } from '~types/Section.types'
import ListTitleTextSection from './ListTitleTextSection'
import defaultData from './ListTitleTextSection.defaultData'
import ListTitleTextSectionParams from './ListTitleTextSection.params'
import { TListTitleTextSection } from './ListTitleTextSection.types'

const listTitleTextSection: TSectionDefinition<TListTitleTextSection> = {
	name: 'list-title-text-button-section',
	Component: ListTitleTextSection,
	Params: ListTitleTextSectionParams,
	categories: ['text'],
	presets: [
		{
			imagePreview: <ImagePreview name="A" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: defaultData.data.items.filter((item, index) => index < 2)
				},
				params: {
					...defaultData.params,
					sectionHeight: 'medium',
					sectionWidth: 'medium',
					alignText: 'left'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="B" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: defaultData.data.items.filter((item, index) => index < 3)
				},
				params: {
					...defaultData.params,
					sectionHeight: 'medium',
					sectionWidth: 'large',
					alignText: 'left'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="C" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: defaultData.data.items.filter((item, index) => index < 2)
				},
				params: {
					...defaultData.params,
					sectionHeight: 'medium',
					sectionWidth: 'large',
					alignText: 'left',
					designLayout: 'aside'
				}
			}
		}
	]
}

export default listTitleTextSection

export const query = graphql`
	query {
		A: file(relativePath: { eq: "section-preview/list-text-left-two.PNG" }) {
			...SectionPreviewImage
		}

		B: file(relativePath: { eq: "section-preview/list-text-left-three.PNG" }) {
			...SectionPreviewImage
		}

		C: file(
			relativePath: { eq: "section-preview/list-text-left-two-row.PNG" }
		) {
			...SectionPreviewImage
		}
	}
`

function ImagePreview({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
