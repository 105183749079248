import React from 'react'
import { GatsbyLinkProps, Link as Lnk } from 'gatsby'
import { useGlobalContext } from '~contexts/globalContext'
import { setCachedPage } from '~contexts/page/page'
import { fetchPage } from '~queries/page.queries'
import { getCachedPage } from '~contexts/page/pageContext.utils'
import { usePageContext } from '~contexts/page/pageContext'

type LinkPrps = GatsbyLinkProps<any>

export const Link: React.FC<LinkPrps> = ({ children, ref: _ref, ...props }) => {
	const { website } = useGlobalContext()
	const { editMode } = usePageContext()
	return (
		// @ts-ignore
		<Lnk
			{...props}
			onMouseEnter={() => {
				const page = website.getPageFromSlug(props.to.slice(1))

				if (page) {
					const slug = props.to.slice(1)

					if (!getCachedPage(`page-/${slug}`))
						fetchPage(slug).then((page) => {
							setCachedPage(`page-/${slug}`, page)
						})
				}
			}}
			onClick={(e) => {
				if (editMode) {
					e.preventDefault()
				} else {
					props.onClick?.(e)
				}
			}}
		>
			{children}
		</Lnk>
	)
}
