import React from 'react'

import './TopBarMenu.scss'
import MenuItem from '~components/Template/common/MenuItem'

type TopBarMenuProps = { menu: any[]; disableLinks?: boolean }
const TopBarMenu: React.FC<TopBarMenuProps> = ({ menu, disableLinks }) => {
	return (
		<div className="TopBarMenu">
			{menu.map((menuItem) => (
				<MenuItem
					key={menuItem.id}
					menuItem={menuItem}
					disableLinks={disableLinks}
				/>
			))}
		</div>
	)
}

export default TopBarMenu


