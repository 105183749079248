import React from 'react'
import FacebookIcon from './FaceBookIcon'
import InstagramIcon from './InstagramIcon'
import TwitterIcon from './TwitterIcon'
import { YoutubeIcon } from './YoutubeIcon'

import './SocialLinks.scss'

type TSocialLinks = {
	disableLinks?: boolean
	socials: any
	color: string
	bgColor: string
}
const SocialLinks: React.FC<TSocialLinks> = ({
	disableLinks,
	socials,
	color,
	bgColor
}) => {
	if (!socials?.instagram && !socials?.twitter && !socials?.facebook)
		return null

	return (
		<div className="socials">
			{socials.facebook && (
				<a
					href={socials?.facebook}
					target="_blank"
					rel="noopener noreferrer"
					onClick={(e) => {
						if (disableLinks) {
							e.preventDefault()
						}
					}}
				>
					<FacebookIcon color={color} />
				</a>
			)}

			{socials.instagram && (
				<a
					href={socials?.instagram}
					target="_blank"
					rel="noopener noreferrer"
					onClick={(e) => {
						if (disableLinks) {
							e.preventDefault()
						}
					}}
				>
					<InstagramIcon color={color} />
				</a>
			)}

			{socials.twitter && (
				<a
					href={socials?.twitter}
					target="_blank"
					rel="noopener noreferrer"
					onClick={(e) => {
						if (disableLinks) {
							e.preventDefault()
						}
					}}
				>
					<TwitterIcon color={color} />
				</a>
			)}

			{socials.youtube && (
				<a
					href={socials?.youtube}
					target="_blank"
					rel="noopener noreferrer"
					onClick={(e) => {
						if (disableLinks) {
							e.preventDefault()
						}
					}}
				>
					<YoutubeIcon color={color} bgColor={bgColor} />
				</a>
			)}
		</div>
	)
}

export default SocialLinks
