import React from 'react'
// import { useGlobalContext } from '~contexts/globalContext'
import ContactMailIcon from '../icons/ContactMailIcon'
import ContactPhoneIcon from '../icons/ContactPhoneIcon'
import ContactPinIcon from '../icons/ContactPinIcon'

import './ContactSectionInformations.scss'

const ContactSectionInformations: React.FC<{
	data: any
	colors: { btnColor: string; btnBackgroundColor: string }
	title: any
	typo: any
}> = ({ data, colors, title, typo }) => {
	const { btnColor, btnBackgroundColor } = colors
	if (!data) return <></>
	return (
		<div className="ContactSectionInformations">
			<h3 style={{ ...typo }}>{title ? <>{title}</> : 'Contact'}</h3>

			<div className="items">
				<div>
					<span>
						<ContactPinIcon color={btnColor} bgColor={btnBackgroundColor} />
					</span>
					{data.address?.full_address}
				</div>
				<a href={`tel:${data.contacts?.phone}`}>
					<span>
						<ContactPhoneIcon color={btnColor} bgColor={btnBackgroundColor} />
					</span>
					{data.contacts?.phone}
				</a>
				<a href={`mailto:${data.contacts?.mail}`}>
					<span style={{ lineHeight: 1 }}>
						<ContactMailIcon color={btnColor} bgColor={btnBackgroundColor} />
					</span>
					{data.contacts?.mail}
				</a>
			</div>
		</div>
	)
}

export default ContactSectionInformations
