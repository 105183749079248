import {
	TRestaurantClassicSectionData,
	TRestaurantClassicSectionParams
} from './RestaurantClassicSection.types'
import { TSectionDefaultData } from '~types/Section.types'

const RestaurantClassicSectionDefaultData: TSectionDefaultData<
	TRestaurantClassicSectionData,
	TRestaurantClassicSectionParams
> = {
	data: {
		// Write here default data
	},
	params: {
		// Write here default params
	}
}

export default RestaurantClassicSectionDefaultData
