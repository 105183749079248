import React, { useState } from 'react'
import { useNotificationContext } from '~components/Common/Notification/Notification'
import { useNewsletterQueries } from '~queries/newsletter.queries'
import './NewsletterForm.scss'

export const NewsletterForm = ({ colors }) => {
	const { textColor, btnColor, btnBackgroundColor } = colors
	const [email, setEmail] = useState('')
	const [error, setError] = useState(false)
	const newsletterQueries = useNewsletterQueries()
	const notification = useNotificationContext()

	return (
		<div
			className="NewsletterForm"
			style={{ borderBottom: `2px solid ${textColor}` }}
		>
			<input
				className={error ? 'error' : ''}
				placeholder="Votre mail"
				value={email}
				type="email"
				onChange={(e) => {
					setError(false)
					setEmail(e.target.value)
				}}
			/>
			<button
				onClick={() => {
					const { validate } = require('email-validator')

					if (validate(email)) {
						newsletterQueries.subscribeToNewsletter(email).then(() => {
							notification?.push({
								theme: 'success',
								text: 'Email enregistrée dans le Newsletter'
							})
							setEmail('')
						})
					} else {
						setError(true)
					}
				}}
				style={{ backgroundColor: btnBackgroundColor, color: btnColor }}
			>
				Valider
			</button>
		</div>
	)
}
