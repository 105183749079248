import React from 'react'

import { useModalWithPayload } from '~components/Common/Modal/Modal'
import { usePageContext } from '~contexts/page/pageContext'
import { TEditableButton } from '../EditableButton.types'
import EditableButtonParamsModal from '../EditableButtonParamsModal/EditableButtonParamsModal'

import './EditableButtonToolBar.scss'

const EditableButtonToolBar: TEditableButtonToolBar = ({
	value,
	onChange,
	handleRemove
}) => {
	const editableButtonParamsModal = useModalWithPayload()
	const pageContext = usePageContext()

	return (
		<>
			<EditableButtonParamsModal
				{...editableButtonParamsModal}
				onChange={(data) => {
					onChange(data)
					editableButtonParamsModal.close()
					pageContext.refresh()
				}}
				handleRemove={handleRemove}
			/>
			<div
				className={`EditableButtonToolBar`}
				onClick={() => {
					editableButtonParamsModal.open(value)
				}}
			>
				<button className="button">
					<i className="mdi mdi-lead-pencil" />
				</button>
			</div>
		</>
	)
}

export default EditableButtonToolBar

type TEditableButtonToolBar = React.FC<{
	value: TEditableButton
	onChange: (value: TEditableButton) => void
	handleRemove?: () => void
}>
