import React from 'react'
import Container from '~components/Common/Container/Container'
import { TListTitleTextButtonSectionComponent } from './ListTitleTextButtonSection.types'
import EditableElement from '~components/Edition/Editables/EditableElement/EditableElement'

import EditableButton from '~components/Edition/Editables/EditableButton/EditableButton'

import EditableText from '~components/Edition/Editables/EditableText/EditableText'

import './ListTitleTextButtonSection.scss'
import { generateClassNames } from '~utils/misc'

const ListTitleTextButtonSection: TListTitleTextButtonSectionComponent = ({
	section,
	theme
}) => {
	const {
		sectionHeight,
		sectionWidth,
		alignText,
		alignHorizontal,
		designLayout,
		alignVertical
	} = section.params

	const items = section.data.items.map((item, index) => {
		return (
			<div className="list-item-content" key={index}>
				<div className="item-content-text">
					<EditableElement
						style={{
							color: theme?.title
						}}
						field={`items.${index}.title`}
						tag="h3"
					/>
					<EditableText field={`items.${index}.text`} />
				</div>
			</div>
		)
	})
	return (
		<div
			className={`Section ListTitleTextButtonSection 
		${generateClassNames({
			designLayout,
			alignText,
			sectionHeight,
			sectionWidth,
			alignVertical,
			alignHorizontal
		})}`}
			style={{
				background: theme?.background,
				color: theme?.text
			}}
		>
			<Container>
				<div className="content-wrapper">
					<div className="__content">
						<div className="title-section">
							<EditableElement
								style={{
									color: theme?.title
								}}
								field={`title`}
								tag="h2"
							/>
						</div>
						<div className="list-content">{items}</div>

						<EditableButton theme={theme?.button} field={`button`} />
					</div>
				</div>
			</Container>
		</div>
	)
}

export default ListTitleTextButtonSection
