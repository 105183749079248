import React, { useEffect, useState } from 'react'
import Error404Page from '~components/Pages/Error404Page/Error404Page'
import { useGlobalContext } from '~contexts/globalContext'
import { fetchPage } from '~queries/page.queries'
import PageContent from './PageContent'
import { PageProvider } from './pageContext'

import useIsMounted from '~hooks/useIsMounted'
import { useAuthContext } from '~contexts/authContext/authContext'

const getCachedPage = (key: string) => {
	const cachedData = sessionStorage.getItem(key)

	if (cachedData) {
		const json = JSON.parse(cachedData)

		if (Date.now() - json.date < 1000 * 60) return json.data
	}

	return null
}

export const setCachedPage = (key: string, data: any) => {
	sessionStorage.setItem(
		key,
		JSON.stringify({
			date: Date.now(),
			data
		})
	)
}

const page = () => {
	const Page = () => {
		const authContext = useAuthContext()

		const storageKey = `page-${window.location.pathname}`
		const globalContext = useGlobalContext()
		const [page, setPage] = useState(
			authContext.isConnected ? null : getCachedPage(storageKey)
		)
		const [pageError, setPageError] = useState(null)
		const isMounted = useIsMounted()

		const fetchPageData = () => {
			const slug = window.location.pathname
				.replace(/^\//, '')
				.replace(/\/$/, '')
			fetchPage(slug)
				.then((page) => {
					if (!isMounted()) return
					setCachedPage(storageKey, page)
					setPage(page)
				})
				.catch(() => {
					if (!isMounted()) return
					setPageError(404)
				})
		}

		useEffect(fetchPageData, [])

		if (pageError) return <Error404Page />
		if (page === null || !globalContext.website) return null

		return (
			<PageProvider pageId={page.id} page={page}>
				<PageContent />
			</PageProvider>
		)
	}

	return Page
}

export default page
