import React, { useRef, useState } from 'react'

import { usePageContext } from '~contexts/page/pageContext'
import { useSectionContext } from '../../SectionEditable/SectionContext/SectionContext'
import useClickOutside from '~hooks/useClickOutside'

import EditableVideoParams from './EditableVideoParams/EditableVideoParams'

import './EditableVideo.scss'
import EditableVideoParamsModal from './EditableVideoParamsModal/EditableVideoParamsModal'
import { useModalWithPayload } from '~components/Common/Modal/Modal'
import {
	getDailymotionIdFromURL,
	getVimeoIdFromURL,
	getYoutubeIdFromURL
} from '~utils/services-url-parser/services.url.parser'

export const editableVideo = (url: string) => ({
	url,
	params: {
		description: ''
	}
})

const EditableVideo: TEditableVideoComponent = ({ field }) => {
	const sectionContext = useSectionContext()
	const value = sectionContext.getField(field)

	const [paramIsOpen, setParamIsOpen] = useState(false)

	const { editMode } = usePageContext()

	const rootRef = useRef(null)
	const imageRef = useRef<HTMLIFrameElement>(null)
	const editableVideoParamsModal = useModalWithPayload()

	useClickOutside(paramIsOpen, setParamIsOpen, rootRef)

	if (!value) return null

	const getVideoURL = () => {
		if (value.url.includes('youtube.com') || value.url.includes('youtu.be')) {
			const id = getYoutubeIdFromURL(value.url)
			return `https://www.youtube.com/embed/${id}`
		} else if (
			value.url.includes('dailymotion.com') ||
			value.url.includes('dai.ly')
		) {
			const id = getDailymotionIdFromURL(value.url)
			return `https://www.dailymotion.com/embed/video/${id}`
		} else if (value.url.includes('vimeo.com')) {
			const id = getVimeoIdFromURL(value.url)
			return `//player.vimeo.com/video/${id}`
		}
		return value.url
	}

	const videoURL = getVideoURL()

	return (
		<div
			className={`${editMode ? 'EditableVideo' : 'Image'} ${
				paramIsOpen ? 'active' : ''
			}`}
			ref={rootRef}
		>
			{editMode && (
				<>
					<EditableVideoParamsModal
						onChange={(change) => {
							sectionContext.updateField(field, {
								...value,
								...change
							})
						}}
						{...editableVideoParamsModal}
					/>

					<EditableVideoParams
						isOpen={paramIsOpen}
						onClickOpen={() => {
							editableVideoParamsModal.open(value)
						}}
					/>
				</>
			)}

			<iframe src={videoURL} ref={imageRef}></iframe>
		</div>
	)
}

export default EditableVideo

// const EditableVideoHover = ({ imageRef, value, onChange, canBeLink }) => {
// 	const inputFileRef = useRef<HTMLInputElement>()
// 	const { token } = useAuthContext()

// 	const globalContext = useGlobalContext()

// 	const changeImage = async (file: File) => {
// 		// First display an image preview (blob).
// 		imageRef.current.src = URL.createObjectURL(file)

// 		const result = await postPicture(file, token)

// 		onChange({
// 			...value,
// 			url: result.url
// 		})
// 	}

// 	return (
// 		<div className={`EditableVideoHover`}>
// 			<div>
// 				<div className="alt-input">
// 					<div>Description</div>
// 					<textarea
// 						defaultValue={value.url}
// 						onChange={(e) => {
// 							onChange({
// 								...value,
// 								url: e.target.value
// 							})
// 						}}
// 						onKeyDown={(e) => {
// 							if (e.key === 'Enter') e.preventDefault()
// 						}}
// 					/>
// 				</div>
// 			</div>
// 		</div>
// 	)
// }

type TEditableVideoComponent = React.FC<{
	field: string
	canBeLink?: boolean
}>
