import React from 'react'
import ProductItem from './ProductItemClassic/ProductItemClassic'
import './RestaurantClassicRow.scss'

const RestaurantClassicRow = ({ field }) => {
	const products = field.productList.map((product, index) => {
		return <ProductItem product={product} key={index} />
	})

	return (
		<div className="RestaurantClassicRow">
			<div className="restaurantRow--container">
				<h1 className="restaurantRow-title">{field.title}</h1>
				<div className="restaurentRow-items">{products}</div>
			</div>
		</div>
	)
}

export default RestaurantClassicRow
