import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SectionPreviewImage from '~sections/common/SectionPreviewImage'

import { TSectionDefinition } from '~types/Section.types'
import TextSection from './TextSection'
import TextSectionParams from './TextSection.params'
import defaultData from './TextSection.defaultData'
import { TTextSection } from '~sections/TextSection/TextSection.types'
import { generateLoremIpsum } from '~utils/misc'
import { editableText } from '~components/Edition/Editables/EditableText/EditableText'

const textSection: TSectionDefinition<TTextSection> = {
	name: 'text-section',
	Component: TextSection,
	Params: TextSectionParams,
	categories: ['text'],
	presets: [
		{
			imagePreview: <ImagePreview name="A" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params
				}
			}
		},
		{
			imagePreview: <ImagePreview name="B" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					alignText: 'left'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="C" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					alignText: 'right'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="D" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					text: editableText(generateLoremIpsum('two-pg'))
				},
				params: {
					...defaultData.params,
					sectionWidth: 'large',
					alignText: 'left',
					designLayout: 'aside'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="E" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					title: null
				},
				params: {
					...defaultData.params,
					sectionWidth: 'small'
				}
			}
		}
	]
}

export default textSection

export const query = graphql`
	query {
		A: file(relativePath: { eq: "section-preview/text-center.PNG" }) {
			...SectionPreviewImage
		}
		B: file(relativePath: { eq: "section-preview/text-left.PNG" }) {
			...SectionPreviewImage
		}
		C: file(relativePath: { eq: "section-preview/text-right.PNG" }) {
			...SectionPreviewImage
		}
		D: file(relativePath: { eq: "section-preview/text-left-row.PNG" }) {
			...SectionPreviewImage
		}
		E: file(relativePath: { eq: "section-preview/text.PNG" }) {
			...SectionPreviewImage
		}
	}
`

function ImagePreview({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
