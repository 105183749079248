import React, { useRef, useState } from 'react'
import ToggleButton from '~components/Common/Basics/ToggleButton/ToggleButton'
import Modal from '~components/Common/Modal/Modal'
import { useNotificationContext } from '~components/Common/Notification/Notification'
import RadioButton from '~components/Common/RadioButton/RadioButton'
import ThemeSelector from '~components/Common/ThemeSelector/ThemeSelector'
import TopBar from '~components/Template/TopBar/TopBar'
import { useAuthContext } from '~contexts/authContext/authContext'
import { useGlobalContext } from '~contexts/globalContext'
import { postPicture } from '~queries/images.queries'
import { TLogo, TWebsiteThemeTopBar } from '~types/Website.types'
import MenuEditor from '../Common/MenuEditor/MenuEditor'
import { useMenuEditor } from '../Common/MenuEditor/MenuEditor.hooks'
import SocialEditor from '../Common/SocialEditor/SocialEditor'
import { ValidationErrorPopup } from '../Common/ValidationErrorPopup/ValidationErrorPopup'
// import ThemeColors from '../ThemeColors/ThemeColors'

import './TopbarEditionModal.scss'

const TopbarEditionModal = (props) => {
	const globalContext = useGlobalContext()

	const menuEditor = useMenuEditor(globalContext.website.theme.topbar.menu)

	const [params, setParams] = useState<TWebsiteThemeTopBar>(
		globalContext.website.theme.topbar
	)
	const push = useNotificationContext()?.push
	const [validationError, setValidationError] = useState(false)

	const { token } = useAuthContext()
	const fileRef = useRef<HTMLInputElement>()
	const imgRef = useRef<HTMLImageElement>()
	const [fixed, setFixed] = useState<boolean | undefined>(
		globalContext.website.theme?.topbar?.fixed
	)

	const [logo, setLogo] = useState<TLogo | undefined>(
		globalContext.website.theme?.logo || { type: 'text', text: 'M&L', url: '' }
	)

	const [social, setSocial] = useState(
		globalContext.website?.theme?.topbar?.showSocialLinks
	)
	const [socials, setSocials] = useState(globalContext.website?.socials)

	//const { getPaletteColor } = globalContext.website
	const [theme, setTheme] = useState<any>()

	const handleChangeImage = async (e) => {
		const oldUrl = logo.url
		try {
			const files = Array.from<any>(e.target.files)
			if (files.length === 0) return
			const [file] = files
			const src = URL.createObjectURL(file)
			setLogo({
				...logo,
				url: src
			})

			const result = await postPicture(file, token, 'logo', 200)
			const { url } = result
			setLogo({
				...logo,
				url
			})
		} catch (error) {
			setLogo({ ...logo, url: oldUrl })
			// console.error(error)
			push?.({
				text: 'Image non enregistrée!'
			})
		}
	}

	return (
		<Modal {...props} className="TopbarEditionModal">
			<ValidationErrorPopup
				show={validationError}
				setShow={setValidationError}
			/>
			<div className="main">
				<TopBar
					disableLinks
					editionOverlay={false}
					data={{
						...params,
						showSocialLinks: social,
						socials,
						menu: menuEditor.menu
					}}
					logo={logo}
				/>

				<div className="parameters">
					<div>
						<div className="logo_input">
							<h4>Logo</h4>
							<div>
								<RadioButton
									name="type"
									checked={logo.type !== 'image'}
									label="Text"
									onChange={() =>
										setLogo({
											...logo,
											type: 'text'
										})
									}
								/>

								{logo?.type !== 'image' && (
									<input
										className="logo_txt_input"
										type="text"
										maxLength={28}
										value={logo?.text || ''}
										onChange={(e) => {
											setLogo({
												...logo,
												text: e.target.value
											})
										}}
									/>
								)}
							</div>
							<div>
								<RadioButton
									name="type"
									label="Image"
									checked={logo.type === 'image'}
									onChange={() =>
										setLogo({
											...logo,
											type: 'image'
										})
									}
								/>

								{logo?.type === 'image' && (
									<>
										<input
											ref={fileRef}
											type="file"
											name="image"
											hidden
											onChange={handleChangeImage}
											accept="image/*"
										/>
										{logo?.url ? (
											<>
												<img src={logo.url} alt="Logo" ref={imgRef} />
												<button
													onClick={() => {
														fileRef.current.click()
													}}
												>
													Changer
												</button>
											</>
										) : (
											<>
												<button
													onClick={() => {
														fileRef.current.click()
													}}
												>
													Choisir une image
												</button>
											</>
										)}
									</>
								)}
							</div>
						</div>
					</div>
					<div className="select_theme">
						<h4>Choix du thème</h4>
						<ThemeSelector
							value={theme}
							handleChange={(theme) => {
								setTheme(theme)
								setParams({
									...params,
									backgroundColor: theme.background,
									textColor: theme.text
								})
							}}
						/>
					</div>
				</div>

				<div className="hr" />

				<div>
					<h4>Comportement et réseaux sociaux</h4>

					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							marginBottom: 10
						}}
					>
						<ToggleButton value={fixed} onChange={setFixed} />
						<span
							style={{
								marginLeft: 8
							}}
						>
							Rendre l’en-tête / le header fixe au scroll
						</span>
					</div>

					<SocialEditor
						social={social}
						socials={socials}
						setSocial={setSocial}
						setSocials={setSocials}
					/>
				</div>

				<div className="hr" />

				<MenuEditor
					menuEditor={menuEditor}
					maxSubMenu={3}
					showErrors={validationError}
				/>
			</div>

			<div className="buttons">
				<button
					className="retour"
					onClick={() => {
						props?.close?.()
					}}
				>
					Retour
				</button>
				<button
					onClick={() => {
						if (menuEditor.menuIsValid()) {
							globalContext.website.update({
								socials,
								theme: {
									...globalContext.website.theme,
									topbar: {
										...params,
										menu: menuEditor.menu,
										fixed,
										showSocialLinks: social
									},
									logo
								}
							})
							props.close()
						} else {
							setValidationError(true)
						}
					}}
				>
					Valider
				</button>
			</div>
		</Modal>
	)
}

export default TopbarEditionModal
