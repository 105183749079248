import { editableElement } from '~components/Edition/Editables/EditableElement/EditableElement'
import { editableText } from '~components/Edition/Editables/EditableText/EditableText'
import { generateLoremIpsum } from '~utils/misc'
import { TCitationTitleTextSection } from './CitationTitleTextSection.types'

const CitationTitleTextSectionDefaultData: TCitationTitleTextSection = {
	data: {
		title: editableElement('Dites-le avec le cœur'),
		items: [
			{
				avatar: false,
				title: editableElement(generateLoremIpsum('normal')),
				text: editableText('— Citer la source')
			},
			{
				title: editableElement(generateLoremIpsum('normal')),
				text: editableText('— Citer la source')
			},
			{
				title: editableElement(generateLoremIpsum('normal')),
				text: editableText('— Citer la source')
			},
			{
				title: editableElement(generateLoremIpsum('normal')),
				text: editableText('— Citer la source')
			}
		]
	},
	params: {
		sectionHeight: 'medium',
		sectionWidth: 'medium',
		alignText: 'center',
		alignVertical: 'center',
		alignHorizontal: 'center',
		designLayout: 'wrap',
		gridItems: 'two'
	}
}

export default CitationTitleTextSectionDefaultData
