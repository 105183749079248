/*
 */
import { Link } from 'gatsby'
import React, { useRef } from 'react'
import { useGlobalContext } from '~contexts/globalContext'
import { usePageContext } from '~contexts/page/pageContext'
import { useSectionContext } from '../../SectionEditable/SectionContext/SectionContext'

import './EditableButton.scss'

import {
	TEditableButton,
	TEditableButtonComponent
} from './EditableButton.types'

import EditableButtonToolBar from './EditableButtonToolBar/EditableButtonToolBar'

export const editableButton = (defaultText = 'Nom du bouton') => {
	const defaultValue: TEditableButton = {
		__element: 'editable-button',
		text: defaultText,
		params: {
			actionType: null,
			visible: true
		}
	}

	return defaultValue
}

const EditableButton: TEditableButtonComponent = ({
	field,
	theme,
	handleRemove,
	style = {}
}) => {
	const sectionContext = useSectionContext()
	const value = sectionContext.getField(field)
	const rootRef = useRef<HTMLDivElement>()
	const pageContext = usePageContext()

	if (!value) return null

	return (
		<div
			ref={rootRef}
			className={`EditableButton ${
				!pageContext.editMode && !value.params.visible ? 'hide' : ''
			}`}
			style={style}
		>
			{pageContext.editMode && (
				<EditableButtonToolBar
					value={value}
					onChange={(change) => {
						sectionContext.updateField(field, {
							...value,
							...change
						})
						pageContext.refresh()
					}}
					handleRemove={handleRemove}
				/>
			)}
			{pageContext.editMode ? (
				<Button value={value} theme={theme} />
			) : (
				<ButtonContainer value={value} theme={theme} />
			)}
		</div>
	)
}

const ButtonContainer = ({ theme, value }) => {
	const { website } = useGlobalContext()
	if (value.params.actionType === 'external-link') {
		return (
			<a href={value.params.link} rel="noopener noreferrer" target="_blank">
				<Button theme={theme} value={value} />
			</a>
		)
	} else if (value.params.actionType === 'internal-link') {
		const page = website.getPage(value.params.pageLinkId)
		if (!page?.enabled) return <Button theme={theme} value={value} />
		return (
			<Link to={`/${page?.slug}`}>
				<Button theme={theme} value={value} />
			</Link>
		)
	}

	return <Button theme={theme} value={value} />
}

const Button = ({ theme, value }) => (
	<button
		className="Button"
		style={{
			background: theme?.background,
			color: theme?.color,
			opacity: value.params.visible ? '1' : '0.3'
		}}
	>
		{value?.text}
	</button>
)

export default EditableButton
