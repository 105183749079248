import { Link } from '@reach/router'
import React from 'react'
import Basket from '~sections/RestaurantSection/Basket/Basket/Basket'
import { useMediaQuery } from '~utils/mediaQuery/useMediaQuery'

import './HeaderRestaurantClassicMain.scss'

const HeaderRestaurantClassicMain = () => {
	const screenPhone = useMediaQuery('(max-width: 692px)')
	const screenIPad = useMediaQuery('(max-width: 878px)')

	return (
		<div className="HeaderRestaurantClassicMain">
			<div className="headerMain--container">
				<div className="headerRestaurant--details">
					<h1 className="headerRestaurant__title">La carte</h1>
					<p className="headerRestaurant__desc">
						Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
						sint. Velit officia
					</p>
					<div className="headerRestaurant--contact">
						<p>
							<span className="contactItem__icon">
								<i className="mdi mdi-clock-outline"></i>
							</span>
							Ouvert jusqu'à 21h30
						</p>
						<p>
							<span className="contactItem__icon">
								<i className="mdi mdi-map-marker"></i>
							</span>
							101 Rue Barreyre, Bordeaux, 33000
							<Link to="" className="headerMaps-link">
								Voir la carte
							</Link>
						</p>
						<p>
							<span>
								<span className="contactItem__icon">
									<i className="mdi mdi-check"></i> Vente à emporter
								</span>
							</span>
							<span>
								<span className="contactItem__icon">
									<i className="mdi mdi-check"></i> Livraison
								</span>
							</span>
							<span>
								<span className="contactItem__icon">
									<i className="mdi mdi-check"></i> Repas sur place
								</span>
							</span>
						</p>
					</div>
				</div>
				{!screenPhone && !screenIPad && <Basket active={true} />}
			</div>
		</div>
	)
}

export default HeaderRestaurantClassicMain
