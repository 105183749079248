import React from 'react'

import Params from '~components/Edition/ParamsPresets/Params/Params'

import { TRestaurantClassicSectionParamsComponent } from './RestaurantClassicSection.types'

const RestaurantClassicSectionParams: TRestaurantClassicSectionParamsComponent = () => {
	return (
		<Params className="RestaurantClassicSectionParams">
			Add here Params edition stuff.
		</Params>
	)
}

export default RestaurantClassicSectionParams
