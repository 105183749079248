import React from 'react'

const CloseNavIcon = ({ size = '36' }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="7.61745"
				y="9.74219"
				width="3.00502"
				height="25.5502"
				rx="1.50251"
				transform="rotate(-45 7.61745 9.74219)"
				fill="#172B49"
				stroke="#B7BDC7"
				strokeWidth="0.5"
			/>
			<rect
				x="25.6841"
				y="7.61739"
				width="3.00502"
				height="25.5502"
				rx="1.50251"
				transform="rotate(45 25.6841 7.61739)"
				fill="#172B49"
				stroke="#B7BDC7"
				strokeWidth="0.5"
			/>
		</svg>
	)
}

export default CloseNavIcon
