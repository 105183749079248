import React, { useRef, useState } from 'react'
import { useGlobalContext } from '~contexts/globalContext'
import { usePageContext } from '~contexts/page/pageContext'
import { useSectionContext } from '../../SectionEditable/SectionContext/SectionContext'

import EditableElementToolbar from './EditableElementToolbar/EditableElementToolbar'

import {
	TEditableElement,
	TEditableElementParamsTag
} from './EditableElement.types'
import useRefresh from '~hooks/useRefresh'

import {
	generateLoremIpsum,
	getCursorSelectionRange,
	setCursorSelectionRange
} from '~utils/misc'

import './EditableElement.scss'

const EditableElement: TEditableElementComponent = ({
	field,
	style = {},
	availableTags = ['h1', 'h2', 'h3'],
	handleRemove
}) => {
	const sectionContext = useSectionContext()
	const value: TEditableElement = sectionContext.getField(field)
	const [refresh] = useRefresh()
	const valueRef = useRef(value)
	const pageContext = usePageContext()
	const globalContext = useGlobalContext()
	const rootRef = useRef<HTMLElement>()

	const [toolbarIsVisible, setToolbarIsVisible] = useState(false)

	const { theme } = globalContext.website

	const elementTheme = theme.typo[value.params?.tag || 'h2']

	const globalStyle = elementTheme ? elementTheme : {}

	if (!value) return null

	const element = React.createElement(
		value.params?.tag || 'h2',
		{
			style: {
				...globalStyle,
				...style
			},
			className: 'EditableElement',
			contentEditable: pageContext.editMode,
			suppressContentEditableWarning: true,
			onFocus: () => {
				setToolbarIsVisible(true)
			},
			onBlur: () => {
				setToolbarIsVisible(false)
			},

			onKeyDown: (e) => {
				if (e.ctrlKey && ['b', 'i', 'u'].includes(e.key)) {
					e.preventDefault()
					return
				}

				if (e.key === 'Enter') {
					e.preventDefault()
					return
				}
			},
			ref: rootRef,
			onKeyUp: () => {
				const val = rootRef.current.textContent
				sectionContext.updateField(field, {
					...value,
					value: val
				})
				valueRef.current.value = val
			},
			onPaste: (e) => {
				e.preventDefault()
				const text = e.clipboardData.getData('text/plain')
				document.execCommand('insertHTML', false, text)
			}
		},
		valueRef.current.value
	)

	if (pageContext.editMode) {
		return (
			<div style={{ position: 'relative' }}>
				<EditableElementToolbar
					visible={toolbarIsVisible}
					availableTags={availableTags}
					params={value.params}
					handleRemove={handleRemove}
					onChangeParams={(params) => {
						const cursorSelectionRange = getCursorSelectionRange()

						sectionContext.updateField(field, {
							...value,
							params: {
								...value.params,
								...params
							}
						})

						refresh()

						window.requestAnimationFrame(() => {
							setCursorSelectionRange(rootRef.current, cursorSelectionRange)
						})
					}}
				/>
				{element}
			</div>
		)
	}

	return element
}

export default EditableElement

type TEditableElementComponent = React.FC<{
	field: string
	tag?: string
	style?: React.CSSProperties
	availableTags?: TEditableElementParamsTag[]
	handleRemove?: () => void
}>

type TeditableElementInitializer = (
	value?: string,
	defaultTag?: TEditableElementParamsTag
) => TEditableElement

export const editableElement: TeditableElementInitializer = (
	value = generateLoremIpsum('small'),
	defaultTag = 'h2'
) => ({
	__element: 'editable-element',
	value,
	params: {
		tag: defaultTag
	}
})
