import React, { useState } from 'react'
import Select from '~components/Common/Basics/Select/Select'
import { useGlobalContext } from '~contexts/globalContext'
import { linkRegex } from '~utils/misc'

import './MenuEditor.scss'
import { TMenuItem, TMenuMenuItem } from './MenuEditor.types'

const uniqid = require('uniqid')
type MenuEditorProps = {
	menuEditor: any
	maxSubMenu: number
	showErrors: boolean
}
const MenuEditor: React.FC<MenuEditorProps> = ({
	menuEditor,
	maxSubMenu,
	showErrors,
	children
}) => {
	const { menu } = menuEditor

	return (
		<div className="MenuEditor">
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end'
				}}
			>
				{children}
				<button
					className="add-item"
					style={{
						marginBottom: 10
					}}
					onClick={() => {
						menuEditor.addItem()
					}}
				>
					+ Ajouter un lien
				</button>
			</div>
			{menu?.map((item) => (
				<Item
					key={item.id}
					item={item}
					{...menuEditor.item(item.id)}
					maxSubMenu={maxSubMenu}
					showErrors={showErrors}
				/>
			))}
		</div>
	)
}

export default MenuEditor

const Item = ({
	item,
	maxSubMenu,
	...props
}: {
	maxSubMenu: number
	item: TMenuItem
	showErrors?: boolean
	canMoveUp?: boolean
	canMoveDown?: boolean
	handleMoveUp?: () => void
	handleRemove?: () => void
	handleMoveDown?: () => void
	handleUpdate?: (par: any) => void
}) => {
	if (item.type === 'menu' && maxSubMenu > 0)
		return <MenuItem item={item} {...props} maxSubMenu={maxSubMenu - 1} />
	if (item.type === 'externalLink')
		return (
			<ExternalLinkItem item={item} maxSubMenu={maxSubMenu - 1} {...props} />
		)
	return <InternalLinkItem item={item} maxSubMenu={maxSubMenu - 1} {...props} />
}

const MenuItem = ({
	item,
	handleUpdate,
	maxSubMenu,
	showErrors,
	...props
}: {
	item: TMenuMenuItem
	maxSubMenu: number
	showErrors?: boolean
	handleUpdate?: (params: any) => void
}) => {
	const [isOpen, setIsOpen] = useState(true)

	return (
		<div className="MenuItem">
			<div className="head">
				<button
					className="caret"
					onClick={() => {
						setIsOpen(!isOpen)
					}}
				>
					<i className={isOpen ? 'fas fa-caret-down' : 'fas fa-caret-up'} />
				</button>
				<div className="data">
					<input
						type="text"
						placeholder="Nom du menu..."
						value={item.label}
						className={showErrors && !item?.label?.trim() ? 'bad' : ''}
						onChange={(e) => {
							handleUpdate({
								label: e.target.value
							})
						}}
					/>
					<ItemTypeSelect
						maxSubMenu={maxSubMenu}
						type={item.type}
						handleChange={handleUpdate}
					/>
					<button
						className="add-item sub"
						onClick={() => {
							handleUpdate({
								children: [
									{
										id: uniqid(),
										type: 'externalLink',
										pageId: '',
										label: ''
									},
									...(item?.children || [])
								]
							})
						}}
						style={{
							marginLeft: 6
						}}
					>
						+ Ajouter un lien
					</button>
				</div>
				<ItemButtons {...props} />
			</div>

			{isOpen && (
				<div className="children">
					{item.children?.map((child, index) => (
						<Item
							showErrors={showErrors}
							maxSubMenu={maxSubMenu}
							item={child}
							key={child.id}
							canMoveUp={index > 0}
							canMoveDown={index < item.children.length - 1}
							handleMoveUp={() => {
								handleUpdate({
									children: [
										...item.children.slice(0, index - 1),
										item.children[index],
										item.children[index - 1],
										...item.children.slice(index + 1)
									]
								})
							}}
							handleMoveDown={() => {
								handleUpdate({
									children: [
										...item.children.slice(0, index),
										item.children[index + 1],
										item.children[index],
										...item.children.slice(index + 2)
									]
								})
							}}
							handleRemove={() => {
								handleUpdate({
									children: item.children.filter((_) => _.id !== child.id)
								})
							}}
							handleUpdate={(update) => {
								handleUpdate({
									children: item.children.map((_) =>
										_.id === child.id
											? {
													..._,
													...update
											  }
											: _
									)
								})
							}}
						/>
					))}
				</div>
			)}
		</div>
	)
}

const InternalLinkItem = ({
	item,
	handleUpdate,
	maxSubMenu,
	showErrors,
	...props
}: {
	item: any
	maxSubMenu: number
	showErrors?: boolean
	handleUpdate?: (params: any) => void
}) => {
	const { pages } = useGlobalContext().website

	const bindField = (key) => ({
		className: showErrors && !item?.[key] ? 'bad' : '',
		value: item?.[key],
		onChange: (e) =>
			handleUpdate({
				...item,
				[key]: e.target.value
			})
	})

	return (
		<div className="InternalLinkItem">
			<div className="data">
				<input
					type="text"
					placeholder="Nom du lien..."
					{...bindField('label')}
				/>
				<ItemTypeSelect
					type={item.type}
					handleChange={handleUpdate}
					maxSubMenu={maxSubMenu}
				/>
				<select {...bindField('pageId')}>
					<option value="" disabled>
						Choisissez un lien
					</option>
					{pages.map((page) => {
						if (page.enabled)
							return (
								<option value={page.id} key={page.id}>
									/{page.slug}
								</option>
							)
					})}
					{/* <option value="/a-propos">A propos</option>
					<option value="/contact">Conact</option>
					<option value="/cguv">CGUV</option>
					<option value="/boutique">Boutique</option>
					<option value="/mentions">Mentions légales</option>
					<option value="/cookies">Politique de cookies</option> */}
				</select>
			</div>

			<ItemButtons {...props} />
		</div>
	)
}

const ExternalLinkItem = ({
	item,
	handleUpdate,
	maxSubMenu,
	showErrors,
	...props
}: {
	maxSubMenu: number
	item: any
	showErrors?: boolean
	handleUpdate?: (params: any) => void
}) => {
	const bindField = (key) => ({
		value: item?.[key],
		onChange: (e) =>
			handleUpdate({
				...item,
				[key]: e.target.value
			})
	})

	return (
		<div className="ExternalLinkItem">
			<div className="data">
				<input
					type="text"
					placeholder="Nom du lien..."
					className={showErrors && !item?.label?.trim() ? 'bad' : ''}
					{...bindField('label')}
				/>
				<ItemTypeSelect
					type={item.type}
					handleChange={handleUpdate}
					maxSubMenu={maxSubMenu}
				/>

				<input
					placeholder="URL de redirection..."
					style={{
						marginLeft: 6
					}}
					className={showErrors && !linkRegex.test(item?.url) ? 'bad' : ''}
					{...bindField('url')}
				/>

				<label style={{ fontSize: '13px' }}>
					<input
						type="checkbox"
						checked={item.targetBlank}
						onClick={() => {
							handleUpdate({
								...item,
								targetBlank: !item.targetBlank
							})
						}}
					/>{' '}
					Ouvrir dans nouvel onglet
				</label>
			</div>
			<ItemButtons {...props} />
		</div>
	)
}

const ItemButtons = ({
	handleRemove,
	handleMoveUp,
	canMoveUp,
	handleMoveDown,
	canMoveDown
}: any) => (
	<div className="ItemButtons">
		<button className="delete" onClick={handleRemove}>
			<i className="fas fa-trash" />
		</button>
		<button className="move" disabled={!canMoveUp} onClick={handleMoveUp}>
			<i className="fas fa-chevron-up" />
		</button>
		<button className="move" disabled={!canMoveDown} onClick={handleMoveDown}>
			<i className="fas fa-chevron-down" />
		</button>
	</div>
)

const ItemTypeSelect = ({ type, handleChange, maxSubMenu }) => {
	const defaultData = {
		internalLink: {
			type: 'internalLink',
			targetBlank: false,
			url: ''
		},
		externalLink: {
			type: 'externalLink',
			pageId: '',
			url: ''
		},
		menu: {
			type: 'menu',
			children: []
		}
	}

	return (
		<Select
			value={type}
			onChange={(type) => {
				handleChange(defaultData[type])
			}}
			options={[
				{
					name: 'Lien interne',
					value: 'internalLink'
				},
				{
					name: 'URL',
					value: 'externalLink'
				},
				...(maxSubMenu > 0
					? [
							{
								name: 'Menu',
								value: 'menu'
							}
					  ]
					: [])
			]}
		/>
	)
}

{
	/* <select
			className="ItemTypeSelect"
			value={type}
			onChange={(e) => {
				handleChange(defaultData[e.target.value])
			}}
		>
			<option value="internalLink">Lien interne</option>
			<option value="externalLink">URL</option>
			{maxSubMenu > 0 && <option value="menu">Menu</option>}
		</select> */
}
