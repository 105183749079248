import React from 'react'
import Container from '~components/Common/Container/Container'
import { TVideoTitleTextSectionComponent } from './VideoTitleTextSection.types'
import EditableElement from '~components/Edition/Editables/EditableElement/EditableElement'

import EditableVideo from '~components/Edition/Editables/EditableVideo/EditableVideo'
import EditableText from '~components/Edition/Editables/EditableText/EditableText'

import './VideoTitleTextSection.scss'
import { generateClassNames } from '~utils/misc'

const VideoTitleTextSection: TVideoTitleTextSectionComponent = ({
	section,
	theme
}) => {
	const {
		sectionHeight,
		sectionWidth,
		alignText,
		alignHorizontal,
		alignVertical,
		videoPosition
	} = section.params

	return (
		<div
			className={`Section VideoTitleTextSection  ${generateClassNames({
				videoPosition,
				alignText,
				sectionHeight,
				sectionWidth,
				alignVertical,
				alignHorizontal
			})}`}
			style={{
				background: theme?.background,
				color: theme?.text
			}}
		>
			<Container>
				<div className="content-wrapper">
					<div className="__content">
						<div className="text-content">
							<EditableElement
								style={{
									color: theme?.title
								}}
								field="title"
								tag="h2"
							/>
							<EditableText field="text" />
						</div>

						<div className="video-content">
							<EditableVideo field="video" />
						</div>
					</div>
				</div>
			</Container>
		</div>
	)
}

export default VideoTitleTextSection
