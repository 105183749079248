import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SectionPreviewImage from '~sections/common/SectionPreviewImage'

import { TSectionDefinition } from '~types/Section.types'
import TitleTextImageButtonBasicSection from './TitleTextImageButtonBasicSection'
import defaultData from './TitleTextImageButtonBasicSection.defaultData'
import TitleTextImageButtonSectionBasicParams from './TitleTextImageButtonBasicSection.params'
import { TTitleTextImageButtonBasicSection } from './TitleTextImageButtonBasicSection.types'

const titleTextImageButtonBasicSection: TSectionDefinition<TTitleTextImageButtonBasicSection> = {
	name: 'title-text-image-button-basic-section',
	Component: TitleTextImageButtonBasicSection,
	Params: TitleTextImageButtonSectionBasicParams,
	categories: ['title', 'image'],
	presets: [
		{
			imagePreview: <ImagePreview />,
			defaultData
		}
	]
}

export default titleTextImageButtonBasicSection

export const query = graphql`
	query {
		file(
			relativePath: { eq: "section-preview/title-text-image-button-basic.PNG" }
		) {
			...SectionPreviewImage
		}
	}
`

function ImagePreview({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
