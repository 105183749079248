import React, { useEffect, useRef, useState } from 'react'
import { useGlobalContext } from '~contexts/globalContext'
import useMessageListener from '~hooks/useMessageListener'
import useRefresh from '~hooks/useRefresh'
import { TPageContext } from './pageContext.types'
import { getCachedPage, saveCachePage } from './pageContext.utils'

import useIsMounted from '~hooks/useIsMounted'
import BasketBottomBar from '~components/Common/BasketBottomBar/BasketBottomBar'
import sectionList from '~core/sections'

export const pageContext = React.createContext<TPageContext>({
	editMode: false,
	setEditMode: null,
	isLoading: false,
	setIsLoading: null,
	modified: false,
	setModified: null,
	pageId: null,
	dataRef: null,
	refresh: null,
	getSectionById: null,
	getSectionDefaultDataById: null
})

export const usePageContext = () => React.useContext<TPageContext>(pageContext)

export const postEditorMessage = (type, payload = null) => {
	window.parent.postMessage(
		{
			type,
			payload
		},
		document.location.origin
	)
}

export const PageProvider = ({ children, pageId, page }) => {
	const dataRef = useRef(page)
	const { website } = useGlobalContext()
	const isMounted = useIsMounted()
	const [editMode, setEditMode] = useState(false)
	const [isLoading, _setIsLoading] = useState(false)
	const currentLoadings = useRef<number>(0)
	const [modified, setModified] = useState(false)
	const [refresh] = useRefresh()

	const setIsLoading = (isLoading: boolean) => {
		if (isLoading) {
			currentLoadings.current++
			if (currentLoadings.current === 1) postEditorMessage('LOADING_STARTS')
		} else {
			currentLoadings.current--
			if (currentLoadings.current === 0) postEditorMessage('LOADING_ENDS')
		}

		_setIsLoading(currentLoadings.current > 0)
	}

	useMessageListener((e) => {
		if (!isMounted()) return
		switch (e.type) {
			case 'ENABLE_EDIT_MODE':
				setEditMode(true)
				break

			case 'DISABLE_EDIT_MODE':
				setEditMode(false)
				break

			case 'SAVE':
				{
					saveCachePage(pageId, dataRef.current)
					postEditorMessage('SAVED')
					const data = {
						...dataRef.current
					}

					delete data.enabled

					website.updatePage(data)
					setModified(false)
				}
				break

			case 'CANCEL':
				dataRef.current = getCachedPage(pageId)
				setModified(false)
				break

			case 'METADATA_UPDATE':
				dataRef.current.metadata = e.payload
				setModified(true)
				break
		}
	})

	useEffect(() => {
		if (modified && window.parent) {
			postEditorMessage('MODIFIED')
		}
	}, [modified])

	useEffect(() => {
		if (window.parent) {
			postEditorMessage('HELLO', dataRef.current)
		}
	}, [])

	const getSectionById = (id: string) =>
		dataRef.current.sections.find((section) => section.id === id)

	const getSectionDefaultDataById = (id: string) => {
		const section = getSectionById(id)
		if (section) {
			return sectionList[section.type]?.presets[0]?.defaultData
		}
		return null
	}
	return (
		<pageContext.Provider
			value={{
				editMode,
				setEditMode,
				isLoading,
				setIsLoading,
				modified,
				setModified,
				pageId,
				dataRef,
				refresh,
				getSectionById,
				getSectionDefaultDataById
			}}
		>
			{children}

			<BasketBottomBar />
		</pageContext.Provider>
	)
}
