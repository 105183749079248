import { Link } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import Button from '~components/Common/Button/Button'
import { ConfirmationModalProvider } from '~components/Common/Modal/ConfirmationModal/ConfirmationModal'

import { useModalWithPayload } from '~components/Common/Modal/Modal'
import { NotificationProvider } from '~components/Common/Notification/Notification'
import FooterEditionModal from '~components/Edition/FooterEditionModal/FooterEditionModal'
import AddSectionModal from '~components/Edition/SectionEditable/AddSectionModal/AddSectionModal'
import SectionEditable from '~components/Edition/SectionEditable/SectionEditable'
import SectionStatic from '~components/Edition/SectionEditable/SectionStatic'
import TopbarEditionModal from '~components/Edition/TopbarEditionModal/TopbarEditionModal'
import { fonts } from '~components/Pages/AdminPage/RouteAdminTheme/AdminThemeTypo/TypoSelector/TypoSelector'
import Footer from '~components/Template/Footer/Footer'
import TopBar from '~components/Template/TopBar/TopBar'
import { useAuthContext } from '~contexts/authContext/authContext'
import { useGlobalContext } from '~contexts/globalContext'
import useRefresh from '~hooks/useRefresh'
import { usePageContext } from './pageContext'
import usePageEditor from './usePageEditor'
import {
	getWebsiteTypefaces,
	getWebsiteTypefacesLinkHref
} from '~utils/fonts.utils'
import Error404Page from '~components/Pages/Error404Page/Error404Page'

const PageContent = () => {
	const { dataRef, editMode, setModified } = usePageContext()
	const pageEditor = usePageEditor()
	const { sections, enabled } = dataRef.current
	const SectionElement = editMode ? SectionEditable : SectionStatic
	const [refresh] = useRefresh()
	const addSectionModal = useModalWithPayload()
	const { website } = useGlobalContext()
	const authContext = useAuthContext()

	const footerEditionModal = useModalWithPayload()
	const topbarEditionModal = useModalWithPayload()

	const handleChange = () => {
		setModified(true)
	}

	if (!enabled && !editMode) return <Error404Page />

	if (!website?.theme) return null

	const typos = getWebsiteTypefaces(website.theme.typo)

	return (
		<>
			<Helmet>
				{typos.map((typo, i) => (
					<GoogleFontsLink key={i} fontName={typo} />
				))}
			</Helmet>
			<div style={{ ...website.theme?.typo.p }}>
				<TopBar
					handleEdit={() => {
						topbarEditionModal.open({})
					}}
					data={website.theme?.topbar}
				/>
			</div>

			{!editMode && authContext.isConnected && <StartEditionButton />}

			{editMode && (
				<>
					<NotificationProvider>
						<ConfirmationModalProvider>
							{/* condition added to flush unsaved data when closing the modal */}
							{footerEditionModal.isOpen && (
								<FooterEditionModal {...footerEditionModal} />
							)}
							{topbarEditionModal.isOpen && (
								<TopbarEditionModal {...topbarEditionModal} />
							)}
						</ConfirmationModalProvider>
					</NotificationProvider>
				</>
			)}

			{editMode && (
				<>
					{sections.length === 0 && (
						<button
							className="first-section"
							onClick={() => {
								addSectionModal.open({})
							}}
							style={{
								height: 200,
								outline: 'none',
								width: '100%',
								border: 0,
								margin: '60px 0',
								backgroundColor: '#f4f5fd'
							}}
						>
							Ajouter ma première section
						</button>
					)}

					<AddSectionModal
						{...addSectionModal}
						onSelect={(section) => {
							dataRef.current.sections = [section]
							refresh()
							handleChange()
						}}
					/>
				</>
			)}
			<div style={{ ...website.theme?.typo.p }}>
				<NotificationProvider>
					{sections.map((section, index) => {
						const props = editMode
							? {
									section,
									...pageEditor.section(section)
							  }
							: {
									section
							  }

						return <SectionElement key={`${section.id}-${index}`} {...props} />
					})}
				</NotificationProvider>

				<Footer
					handleEdit={() => {
						footerEditionModal.open({})
					}}
				/>
			</div>
		</>
	)
}

export default PageContent

const StartEditionButton = () => {
	const pageContext = usePageContext()

	return (
		<div
			style={{
				position: 'fixed',
				bottom: 20,
				right: 20,
				display: 'flex',
				zIndex: 1000
			}}
		>
			<Link to={`/editor?p=${pageContext.pageId}`}>
				<Button theme="dark">
					<i className="mdi mdi-pencil" /> Editer la page
				</Button>
			</Link>
		</div>
	)
}

const GoogleFontsLink = ({ fontName }) => {
	const font = fonts.find((x) => x.fontFamily === fontName)
	if (!font) return <></>
	return <link href={getWebsiteTypefacesLinkHref(font)} rel="stylesheet"></link>
}
