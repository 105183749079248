import React from 'react'

const BoutiqueSearchIcon = () => (
	<svg width="22" height="22" viewBox="0 0 22 22" className="icon">
		<path
			d="M21.618 19.7745L17.3889 15.5463C18.6706 13.8688 19.3735 11.8341 19.3738 9.68703C19.3738 7.0996 18.3659 4.66686 16.5356 2.83729C14.7057 1.00773 12.2727 0 9.68444 0C7.09647 0 4.66322 1.00773 2.83327 2.83729C-0.944424 6.61451 -0.944424 12.7602 2.83327 16.5368C4.66322 18.3666 7.09647 19.3744 9.68444 19.3744C11.832 19.3741 13.8671 18.6713 15.545 17.3899L19.774 21.618C20.0284 21.8727 20.3623 22 20.696 22C21.0296 22 21.3636 21.8727 21.618 21.618C22.1273 21.1091 22.1273 20.2834 21.618 19.7745ZM4.67723 14.6932C1.91639 11.9329 1.9167 7.44145 4.67723 4.68086C6.01467 3.34402 7.79305 2.60748 9.68444 2.60748C11.5762 2.60748 13.3542 3.34402 14.6917 4.68086C16.0291 6.01803 16.7658 7.79603 16.7658 9.68703C16.7658 11.5783 16.0291 13.356 14.6917 14.6932C13.3542 16.0304 11.5762 16.7669 9.68444 16.7669C7.79305 16.7669 6.01467 16.0304 4.67723 14.6932Z"
			fill="#172B49"
		/>
	</svg>
)
export default BoutiqueSearchIcon
