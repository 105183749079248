import React from 'react'
import Container from '~components/Common/Container/Container'
import EditableElement from '~components/Edition/Editables/EditableElement/EditableElement'
import EditableImage from '~components/Edition/Editables/EditableImage/EditableImage'
import { TCitationTextImageSectionComponent } from './CitationTextImageSection.types'

import EditableText from '~components/Edition/Editables/EditableText/EditableText'

import './CitationTextImageSection.scss'
import { generateClassNames } from '~utils/misc'

const CitationTextImageSection: TCitationTextImageSectionComponent = ({
	section,
	theme
}) => {
	const {
		sectionHeight,
		sectionWidth,
		alignHorizontal,
		alignText,
		alignVertical,
		imagePosition,
		designLayout,
		titleTag
	} = section.params

	return (
		<div
			className={`Section CitationTextImageSection  ${generateClassNames({
				designLayout,
				alignText,
				sectionHeight,
				alignHorizontal,
				sectionWidth,
				alignVertical,
				imagePosition
			})}`}
			style={{
				background: theme?.background,
				color: theme?.text
			}}
		>
			<Container>
				<div className="content-wrapper">
					<div className="__content">
						<div className="image-content">
							<EditableImage field="image" />
						</div>
						<div className="image-card-wrapper">
							<div className="image-card">
								<EditableElement field="title" tag={titleTag} />

								<EditableText field="text" />
							</div>
						</div>
					</div>
				</div>
			</Container>
		</div>
	)
}

export default CitationTextImageSection
