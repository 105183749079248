import React from 'react'
import Container from '~components/Common/Container/Container'
import EditableElement from '~components/Edition/Editables/EditableElement/EditableElement'

import EditableButton from '~components/Edition/Editables/EditableButton/EditableButton'
import EditableImage from '~components/Edition/Editables/EditableImage/EditableImage'

import { TListTitleTextImageButtonSectionComponent } from './ListTitleTextImageButtonSection.types'

import EditableText from '~components/Edition/Editables/EditableText/EditableText'

import './ListTitleTextImageButtonSection.scss'
import { generateClassNames } from '~utils/misc'

const ListTitleTextImageButtonSection: TListTitleTextImageButtonSectionComponent = ({
	section,
	theme
}) => {
	const {
		sectionHeight,
		sectionWidth,
		alignText,
		alignHorizontal,
		designLayout,
		alignVertical
	} = section.params

	const items = section.data.items.map((_item, index) => {
		return (
			<div className="list-item-content" key={index}>
				<div className="image-content">
					<EditableImage field={`items.${index}.image`} />
				</div>
				<div className="item-content-text">
					<EditableElement field={`items.${index}.title`} tag="h3" />
					<EditableText field={`items.${index}.text`} />
				</div>
			</div>
		)
	})

	return (
		<div
			className={`Section ListTitleTextImageButtonSection ${generateClassNames({
				designLayout,
				alignText,
				sectionHeight,
				sectionWidth,
				alignVertical,
				alignHorizontal
			})}`}
			style={{
				background: theme?.background,
				color: theme?.text
			}}
		>
			<Container>
				<div className="content-wrapper">
					<div className="__content">
						<EditableElement
							style={{
								color: theme?.title
							}}
							field={`title`}
							tag="h2"
						/>

						<div className="list-content">{items}</div>

						<EditableButton field={`button`} theme={theme?.button} />
					</div>
				</div>
			</Container>
		</div>
	)
}

export default ListTitleTextImageButtonSection
