import React, { useRef } from 'react'

import { getPictureURL } from '~utils/cloudinary'

import { preloadDataQuery } from '~utils/useDataQuery'
import { getBoutiqueItem } from '~queries/boutique.queries'

import { getMarketItemMinPrice } from '~utils/market'

import { Link } from '~sections/common/SectionLink'

import './BoutiqueProductItem.scss'

const BoutiqueProductItem = ({ item }) => {
	const imageURL =
		getPictureURL(item.miniature, {
			w: 201,
			h: 240,
			c: 'fill'
		}) || '/images/empty.png'

	const timeoutRef = useRef(null)

	const minPrice = getMarketItemMinPrice(item)

	return (
		<Link
			to={`/a/${item.id}`}
			className="BoutiqueProductItem"
			state={{ item, fromResult: true }}
			onMouseEnter={() => {
				timeoutRef.current = setTimeout(() => {
					preloadDataQuery(() => getBoutiqueItem(item.id), `item-${item.id}`)
				}, 500)
			}}
			onMouseLeave={() => {
				if (timeoutRef.current) {
					clearTimeout(timeoutRef.current)
				}
			}}
		>
			<div className="image">
				<img src={imageURL} />
			</div>

			<h3>{item.title}</h3>
			<div className="price">{minPrice}€</div>
		</Link>
	)
}

export default BoutiqueProductItem
