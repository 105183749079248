import { editableButton } from '~components/Edition/Editables/EditableButton/EditableButton'
import { editableElement } from '~components/Edition/Editables/EditableElement/EditableElement'
import { editableImage } from '~components/Edition/Editables/EditableImage/EditableImage'
import { editableText } from '~components/Edition/Editables/EditableText/EditableText'
import { generateLoremIpsum } from '~utils/misc'
import { TTitleTextImageButtonSection } from './TitleTextImageButtonSection.types'

const TitleTextImageButtonSectionDefaultData: TTitleTextImageButtonSection = {
	data: {
		title: editableElement('Dites-le avec le cœur'),
		text: editableText(generateLoremIpsum('normal')),
		button: editableButton('Bouton'),
		image: editableImage(
			'https://res.cloudinary.com/duopq85lp/image/upload/v1617901755/photo-1491866766009-1292fd127204_ivv7lm.png'
		)
	},
	params: {
		sectionHeight: 'large',
		sectionWidth: 'large',
		alignText: 'center',
		alignHorizontal: 'center',
		alignVertical: 'center',
		designLayout: 'card',
		imagePosition: 'left'
	}
}

export default TitleTextImageButtonSectionDefaultData
