import React from 'react'
import ParamAlignHorizontal from '~components/Edition/ParamsPresets/ParamAlignHorizontal/ParamAlignHorizontal'
import ParamHauteur from '~components/Edition/ParamsPresets/ParamHauteur/ParamHauteur'
import ParamLargeur from '~components/Edition/ParamsPresets/ParamLargeur/ParamLargeur'

import Params from '~components/Edition/ParamsPresets/Params/Params'

import { TArticleSectionParamsComponent } from './ArticleSection.types'

const ArticleSectionParams: TArticleSectionParamsComponent = ({
	params,
	onChange
}) => {
	return (
		<Params className="ArticleSectionParams">
			<ParamHauteur
				label="Hauteur de section"
				onChange={(sectionHeight) => {
					onChange({
						...params,
						sectionHeight
					})
				}}
				value={params.sectionHeight}
				options={[
					{
						label: 'S',
						value: 'small'
					},
					{
						label: 'M',
						value: 'medium'
					},
					{
						label: 'L',
						value: 'large'
					}
				]}
			/>
			<ParamLargeur
				label="Largeur de section"
				onChange={(containerSize) => {
					onChange({
						...params,
						containerSize
					})
				}}
				value={params.containerSize}
				options={[
					{
						label: 'S',
						value: 'measure'
					},
					{
						label: 'M',
						value: 'small'
					}
				]}
			/>

			<ParamAlignHorizontal
				label="Alignement du contenu"
				onChange={(contentAlign) => {
					onChange({
						...params,
						contentAlign
					})
				}}
				value={params.contentAlign}
				options={[
					{
						label: 'mdi-arrow-collapse-left',
						value: 'left'
					},
					{
						label: 'mdi-arrow-collapse-horizontal',
						value: 'center'
					},
					{
						label: 'mdi-arrow-collapse-right',
						value: 'right'
					}
				]}
			/>
		</Params>
	)
}

export default ArticleSectionParams
