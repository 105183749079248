/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef } from 'react'
import L from 'leaflet'

const LP = require('leaflet-providers')
const LGPX = require('leaflet-gpx')

import 'leaflet/dist/leaflet.css'
import './OSMap.scss'
import { GestureHandling } from 'leaflet-gesture-handling'
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css'

import { isBrowser } from '~utils/misc'

type MapOptions = {
	gestureHandling?: 'no-scroll' | 'scroll' | 'no-scroll-no-warning'
}

const mapUtilFunctions = {
	map: null,
	initMap({ gestureHandling }: MapOptions) {
		const noScroll = gestureHandling?.startsWith('no-scroll')
		// Prevent auto pan
		if (isBrowser() && noScroll)
			L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling)
		// map is the #map element
		this.map = L.map('mape', {
			zoom: 12,
			// @ts-ignore
			gestureHandling: noScroll ? true : undefined
		})

		// @ts-ignore
		L.tileLayer.provider('CartoDB.Positron').addTo(this.map)
	},
	// Renders a marker on the map
	renderMarker(point: Marker) {
		const icon = L.icon({
			iconUrl: `/images/map/pin.svg`,
			iconSize: [39, 44],
			className: 'map_pin_'
		})
		const marker = L.marker([point.lat, point.lng], {
			icon,
			title: point.title
		})

		if (point.title) marker.bindPopup(`${point.title}`).openPopup()

		marker.addTo(this.map)

		this.map.setView([point.lat, point.lng])
	},
	// Renders a GPX file on the map
	renderGPX(url: string) {
		const that = this
		// @ts-ignore
		const gpx = new L.GPX(url, {
			async: true,
			marker_options: {
				// @ts-ignore
				startIconUrl: '/images/map/pin_start.svg',
				endIconUrl: '/images/map/pin_end.svg',
				shadowUrl: '',
				wptIconUrls: {
					'': '/images/map/info_pin.svg'
				}
			},
			polyline_options: {
				color: '#ffbdd0',
				opacity: 1,
				weight: 3,
				lineCap: 'round'
			}
		})
			.on('loaded', function (e) {
				that.map.fitBounds(e.target.getBounds())
			})
			.addTo(this.map)
	}
}

export const OSMap: React.FC<{
	center: { lat: number; lng: number }
	markers?: Marker[]
	traceGPX?: { url: string }[]
	hideFullscreen?: boolean
	options?: MapOptions
}> = ({
	markers,
	center,
	traceGPX,
	hideFullscreen = false,
	options = {
		gestureHandling: 'scroll'
	}
}) => {
	useEffect(() => {
		if (isBrowser()) {
			mapUtilFunctions.initMap({ ...options })
			// mapUtilFunctions.renderGPX('/gpx.gpx')
			if (traceGPX?.length > 0) {
				for (const gpx of traceGPX) {
					if (gpx?.url) mapUtilFunctions.renderGPX(gpx.url)
				}
			} else if (markers?.length > 0) {
				for (const marker of markers) {
					if (marker?.lat && marker?.lng) mapUtilFunctions.renderMarker(marker)
				}
			}
		}
	}, [])

	const [mapRef, toggleFS] = useFullScreen()
	return (
		<div
			ref={mapRef}
			className={'OSMap ' + options.gestureHandling}
			id="mape"
			style={{
				borderRadius: '8px'
			}}
		>
			{!hideFullscreen && (
				<button className="fullScreen" onClick={toggleFS}>
					<img src="/images/map/fullscreen.svg" />
				</button>
			)}
		</div>
	)
}

type Marker = {
	lat: number
	lng: number
	title?: string
}

const useFullScreen = (): [
	React.MutableRefObject<HTMLDivElement>,
	() => void
] => {
	const ref = useRef<HTMLDivElement>(null)
	const toggle = () => {
		if (document.fullscreenElement) {
			if (document.exitFullscreen) {
				document.exitFullscreen()
			}
		} else {
			if (ref.current?.requestFullscreen) {
				ref.current.requestFullscreen()
			}
		}
	}

	return [ref, toggle]
}
