import { Link } from '@reach/router'
import React from 'react'
import './Basket.scss'

const Basket = ({ active }) => {
	return (
		<div className={`Baskete  ${active ? 'active' : ''}`}>
			{/*<span className="basket-empty">Votre panier est vide</span>*/}
			<div className="basket--wrapper">
				<div className="basket--item">
					<p className="basketItem__text">Oeufs mimose</p>
					<div className="basketItem--count">
						<span className="count__iconLeft">
							<i className="mdi mdi-minus"></i>
						</span>
						<span className="count__number">1</span>
						<span className="count__iconRight">
							<i className="mdi mdi-plus"></i>
						</span>
					</div>
					<span className="basketItem__total">4€</span>
				</div>
				<div className="basket--item">
					<p className="basketItem__text">Hot-Dog</p>
					<div className="basketItem--count">
						<span className="count__iconLeft">
							<i className="mdi mdi-minus"></i>
						</span>
						<span className="count__number">2</span>
						<span className="count__iconRight">
							<i className="mdi mdi-plus"></i>
						</span>
					</div>
					<span className="basketItem__total">16€</span>
				</div>
				<div className="hr-separator"></div>
				<div className="basket--item">
					<p className="basketItem__text">Total</p>
					<span className="basketItem__total">20€</span>
				</div>
				<Link to="" className="basketButton">
					Valider mon panier
				</Link>
			</div>
		</div>
	)
}

export default Basket
