import React from 'react'
import ParamAlignHorizontal from '~components/Edition/ParamsPresets/ParamAlignHorizontal/ParamAlignHorizontal'
import ParamAlignVertical from '~components/Edition/ParamsPresets/ParamAlignVertical/ParamAlignVertical'
import ParamHauteur from '~components/Edition/ParamsPresets/ParamHauteur/ParamHauteur'
import ParamLargeur from '~components/Edition/ParamsPresets/ParamLargeur/ParamLargeur'

import Params from '~components/Edition/ParamsPresets/Params/Params'
import { TGalerieSectionParamsComponent } from './GalerieSection.types'

const GalerieSectionParams: TGalerieSectionParamsComponent = ({
	params,
	onChange
}) => {
	return (
		<Params className="GalerieSectionParams">
			<ParamHauteur
				label="Hauteur de section"
				onChange={(sectionHeight) => {
					onChange({
						...params,
						sectionHeight
					})
				}}
				value={params.sectionHeight}
				options={[
					{
						label: 'S',
						value: 'small'
					},
					{
						label: 'M',
						value: 'medium'
					},
					{
						label: 'L',
						value: 'large'
					}
				]}
			/>
			{!params.backgroundImage && (
				<>
					<ParamLargeur
						label="Largeur de section"
						onChange={(sectionWidth) => {
							onChange({
								...params,
								sectionWidth
							})
						}}
						value={params.sectionWidth}
						options={[
							{
								label: 'S',
								value: 'small'
							},
							{
								label: 'M',
								value: 'medium'
							},
							{
								label: 'L',
								value: 'large'
							}
						]}
					/>
					<ParamAlignHorizontal
						label="Alignement du contenu"
						onChange={(alignHorizontal) => {
							onChange({
								...params,
								alignHorizontal
							})
						}}
						value={params.alignHorizontal}
						options={[
							{
								label: 'mdi-arrow-collapse-left',
								value: 'left'
							},
							{
								label: 'mdi-arrow-collapse-horizontal',
								value: 'center'
							},
							{
								label: 'mdi-arrow-collapse-right',
								value: 'right'
							}
						]}
					/>
					<ParamAlignVertical
						label="Alignement du contenu"
						onChange={(alignVertical) => {
							onChange({
								...params,
								alignVertical
							})
						}}
						value={params.alignVertical}
						options={[
							{
								label: 'mdi-arrow-collapse-up',
								value: 'top'
							},
							{
								label: 'mdi-arrow-collapse-vertical',
								value: 'center'
							},
							{
								label: 'mdi-arrow-collapse-down',
								value: 'bottom'
							}
						]}
					/>
				</>
			)}
		</Params>
	)
}

export default GalerieSectionParams
