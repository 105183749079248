import uniqid from 'uniqid'
import {
	TArticleSectionData,
	TArticleSectionParams
} from './ArticleSection.types'
import { TSectionDefaultData } from '~types/Section.types'
import { editableText } from '~components/Edition/Editables/EditableText/EditableText'
import { editableElement } from '~components/Edition/Editables/EditableElement/EditableElement'
import { editableSeparator } from '~components/Edition/Editables/EditableSeparator/EditableSeparator'
import { editableImage } from '~components/Edition/Editables/EditableImage/EditableImage'

const ArticleSectionDefaultData: TSectionDefaultData<
	TArticleSectionData,
	TArticleSectionParams
> = {
	data: {
		blocks: [
			{
				id: uniqid(),
				editable: editableElement('Dites-le avec le cœur', 'h1')
			},

			{
				id: uniqid(),
				editable: editableText()
			},
			{
				id: uniqid(),
				editable: editableText()
			},

			{
				id: uniqid(),
				editable: {
					...editableSeparator(),
					showRule: false
				}
			},

			{
				id: uniqid(),
				editable: editableImage(
					'https://coq-trotteur-prod.s3.eu-west-3.amazonaws.com/res/coq-trotteur/image/upload/coq-trotteur-og/1601043108.jpg'
				)
			},

			{
				id: uniqid(),
				editable: {
					...editableSeparator(),
					showRule: false
				}
			},

			{
				id: uniqid(),
				editable: editableText()
			}
		]
	},
	params: {
		sectionHeight: 'small',
		containerSize: 'small',
		contentAlign: 'center'
	}
}

export default ArticleSectionDefaultData
