import PropTypes from 'prop-types'

import { RichUtils } from 'draft-js'
import React from 'react'
import { TEditableTextToolbarConfig } from '../EditableText.types'

import './EditableTextToolBar.scss'

const EditableTextToolBar: TEditableTextToolBar = ({
	isVisible,
	state,
	onChange,
	config,
	editLink,
	handleRemove
}) => {
	const handleChangeStyle = (style) => () => {
		onChange(RichUtils.toggleInlineStyle(state.editorState, style))
	}

	const handleChangeBlockType = (type) => () => {
		onChange(RichUtils.toggleBlockType(state.editorState, type))
	}

	const currentBlock = RichUtils.getCurrentBlockType(state.editorState)

	const currentStyles = state.editorState.getCurrentInlineStyle()

	return (
		<div className={`EditableTextToolBar ${isVisible ? 'visible' : ''}`}>
			{config.buttons.includes('bold') && (
				<button
					className={`${currentStyles.has('BOLD') ? 'active' : ''}`}
					onClick={handleChangeStyle('BOLD')}
					onMouseDown={(e) => {
						e.preventDefault()
					}}
				>
					<i className="mdi mdi-format-bold" />
				</button>
			)}

			{config.buttons.includes('italic') && (
				<button
					className={`${currentStyles.has('ITALIC') ? 'active' : ''}`}
					onClick={handleChangeStyle('ITALIC')}
					onMouseDown={(e) => {
						e.preventDefault()
					}}
				>
					<i className="mdi mdi-format-italic" />
				</button>
			)}

			{config.buttons.includes('underline') && (
				<button
					className={`${currentStyles.has('UNDERLINE') ? 'active' : ''}`}
					onClick={handleChangeStyle('UNDERLINE')}
					onMouseDown={(e) => {
						e.preventDefault()
					}}
				>
					<i className="mdi mdi-format-underline" />
				</button>
			)}

			{config.buttons.includes('link') && (
				<button
					onClick={editLink}
					onMouseDown={(e) => {
						e.preventDefault()
					}}
				>
					<i className="mdi mdi-link" />
				</button>
			)}

			{config.buttons.includes('unordered-list') && (
				<button
					className={`${
						currentBlock === 'unordered-list-item' ? 'active' : ''
					}`}
					onClick={handleChangeBlockType('unordered-list-item')}
					onMouseDown={(e) => {
						e.preventDefault()
					}}
				>
					<i className="mdi mdi-format-list-bulleted" />
				</button>
			)}

			{config.buttons.includes('ordered-list') && (
				<button
					className={`${currentBlock === 'ordered-list-item' ? 'active' : ''}`}
					onClick={handleChangeBlockType('ordered-list-item')}
					onMouseDown={(e) => {
						e.preventDefault()
					}}
				>
					<i className="mdi mdi-format-list-numbered" />
				</button>
			)}

			{config.buttons.includes('remove') && (
				<button
					onClick={() => {
						if (handleRemove) handleRemove()
					}}
					onMouseDown={(e) => {
						e.preventDefault()
					}}
				>
					<i className="mdi mdi-trash-can-outline" />
				</button>
			)}
		</div>
	)
}

export default EditableTextToolBar

EditableTextToolBar.propTypes = {
	isVisible: PropTypes.bool.isRequired,
	state: PropTypes.any,
	onChange: PropTypes.any,
	config: PropTypes.any,
	handleRemove: PropTypes.any
}

type TEditableTextToolBar = React.FC<{
	isVisible: boolean
	state: any
	onChange: any
	config: TEditableTextToolbarConfig
	handleRemove?: () => void
	editLink?: () => void
}>
