import { editableImage } from '~components/Edition/Editables/EditableImage/EditableImage'
import { TGalerieSection } from './GalerieSection.types'

const GalerieSectionDefaultData: TGalerieSection = {
	data: {
		items: [
			{
				image: editableImage(
					'https://images.unsplash.com/photo-1499202361610-95f5a9ad8345?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
				)
			},
			{
				image: editableImage(
					'https://images.unsplash.com/photo-1499202361610-95f5a9ad8345?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
				)
			},
			{
				image: editableImage(
					'https://images.unsplash.com/photo-1499202361610-95f5a9ad8345?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
				)
			},
			{
				image: editableImage(
					'https://images.unsplash.com/photo-1495088259459-bc37e1849089?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
				)
			},
			{
				image: editableImage(
					'https://images.unsplash.com/photo-1495300146178-72c79633b1fa?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
				)
			},
			{
				image: editableImage(
					'https://images.unsplash.com/photo-1495171081156-cc1209ddf6a0?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
				)
			}
		]
	},
	params: {
		backgroundImage: '',
		sectionHeight: 'medium',
		sectionWidth: 'large',
		alignHorizontal: 'center',
		alignVertical: 'center',
		designLayout: 'card',
		gridItems: 'three',
		backgroundWidth: 'full'
	}
}

export default GalerieSectionDefaultData
