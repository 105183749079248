import { gql } from 'apollo-boost'
import { doGraphqlQuery } from './common.queries'
import WebsitePageFragment from './fragments/websitePage.fragment'

export const fetchPage = async (slug: string) => {
	const websiteId: string = process.env.GATSBY_WEBSITE_ID

	const query = gql(`
        query FetchPageFromSlug ($websiteId: ID!, $slug: String!){
            fetchPageFromSlug(websiteId: $websiteId, slug: $slug){
                ${WebsitePageFragment}
            }
        }
    `)

	const variables = {
		websiteId,
		slug
	}

	const { fetchPageFromSlug: page } = await doGraphqlQuery({
		query,
		variables,
		apiVersion: 2
	})

	if (!page) throw ''

	return page
}
