import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SectionPreviewImage from '~sections/common/SectionPreviewImage'

import VideoTitleTextSection from './VideoTitleTextSection'
import defaultData from './VideoTitleTextSection.defaultData'
import VideoTitleTextSectionParams from './VideoTitleTextSection.params'
import { TVideoTitleTextSection } from './VideoTitleTextSection.types'
import { TSectionDefinition } from '~types/Section.types'

const videoTitleTextSection: TSectionDefinition<TVideoTitleTextSection> = {
	name: 'video-title-text-section',
	Component: VideoTitleTextSection,
	Params: VideoTitleTextSectionParams,
	categories: ['video'],
	presets: [
		{
			imagePreview: <ImagePreview name="videoTitleTextSection" />,
			defaultData
		},
		{
			imagePreview: <ImagePreview name="videoTitleTextTopSection" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					videoPosition: 'top'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="videoTitleTextLeftSection" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					videoPosition: 'left',
					alignText: 'left'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="videoTitleTextRightSection" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					videoPosition: 'right',
					alignText: 'left'
				}
			}
		}
	]
}

export default videoTitleTextSection

export const query = graphql`
	query {
		videoTitleTextSection: file(
			relativePath: { eq: "section-preview/videoTitleTextSection.PNG" }
		) {
			...SectionPreviewImage
		}
		videoTitleTextTopSection: file(
			relativePath: { eq: "section-preview/videoTitleTextTopSection.PNG" }
		) {
			...SectionPreviewImage
		}
		videoTitleTextLeftSection: file(
			relativePath: { eq: "section-preview/videoTitleTextLeftSection.PNG" }
		) {
			...SectionPreviewImage
		}
		videoTitleTextRightSection: file(
			relativePath: { eq: "section-preview/videoTitleTextRightSection.PNG" }
		) {
			...SectionPreviewImage
		}
	}
`

function ImagePreview({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
