import React from 'react'
const TimesIcon = ({ color = '#000000', height = 15 }) => (
	<svg width={height} height={height} viewBox="0 0 30 30" version="1.1">
		<title>Fermer</title>
		<path
			d="M17.233 15L29.5376 2.69542C30.1541 2.07878 30.1541 1.07906 29.5376 0.462479C28.9209 -0.15416 27.9211 -0.15416 27.3046 0.462479L15 12.767L2.69542 0.462479C2.07878 -0.15416 1.07906 -0.15416 0.462479 0.462479C-0.154101 1.07912 -0.15416 2.07884 0.462479 2.69542L12.767 15L0.462479 27.3045C-0.15416 27.9211 -0.15416 28.9209 0.462479 29.5374C1.07912 30.1541 2.07884 30.154 2.69542 29.5374L15 17.2329L27.3044 29.5374C27.9211 30.1541 28.9209 30.154 29.5374 29.5374C30.154 28.9208 30.154 27.9211 29.5374 27.3045L17.233 15Z"
			id="Shape"
			fill={color}
			stroke="none"
		/>
	</svg>
)
export default TimesIcon
