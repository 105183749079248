import React, { useState } from 'react'

import './CategoryFilterItems.scss'
import { setAllSelected } from './CategoryFilterItems.utils'

const CategoryFilterItems: React.FC<{
	items: any[]
	onChange: (data: any) => void
	sectionType: string
}> = ({ items, onChange, sectionType }) => {
	return (
		<div className="CategoryFilterItems">
			{items?.map((item, i) => (
				<MarketCatItem
					key={i}
					item={item}
					sectionType={sectionType}
					onChange={(itema) => {
						item = itema
						onChange(items)
					}}
				/>
			))}
		</div>
	)
}

export default CategoryFilterItems

const MarketCatItem = ({ item, onChange, sectionType }) => {
	const [open, setOpen] = useState(false)
	return (
		<div className="MarketCatItem">
			<div className="item">
				<span
					className={'selector' + (!item.selected ? ' inactive' : '')}
					onClick={() => {
						item = setAllSelected(item, !item.selected)
						onChange(item)
					}}
				>
					<i className="fa fa-check"></i>
				</span>
				<div
					className="drop"
					onClick={() => {
						setOpen(!open)
					}}
				>
					<span className="label">{item.label}</span>
					<span className={'arrow' + (open ? ' opened' : '')}>
						<i className="fa fa-chevron-down"></i>
					</span>
				</div>
			</div>
			<MarketsubcategoryItem
				sectionType={sectionType}
				item={item}
				opened={open}
				onChange={(_item, unselectParent = false) => {
					item = _item
					if (unselectParent) {
						item.selected = false
					}
					onChange(item)
				}}
			/>
		</div>
	)
}

const MarketsubcategoryItem = ({ item, onChange, sectionType, opened }) => {
	if (!opened) return <></>
	return (
		<>
			{item.children && (
				<CategoryFilterItems
					sectionType={sectionType}
					items={item.children}
					onChange={(chng) => {
						item.children = chng
						onChange(item)
					}}
				/>
			)}
			{item.marketItems?.map((mi, j) => (
				<div
					className="item market_item"
					key={j}
					onClick={() => {
						const selected = !mi.selected
						mi.selected = selected

						onChange(item, selected !== true)
					}}
				>
					<span className={'selector' + (!mi.selected ? ' inactive' : '')}>
						<i className="fa fa-check"></i>
					</span>
					<span className="label">{mi.title}</span>
					<span className="arrow">
						{mi.url ? <img src={mi.url} alt="" /> : ' '}
					</span>
				</div>
			))}
		</>
	)
}
