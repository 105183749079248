import React from 'react'
import Container from '~components/Common/Container/Container'
import { TTextSectionComponent } from './TextSection.types'

import EditableElement from '~components/Edition/Editables/EditableElement/EditableElement'

import EditableText from '~components/Edition/Editables/EditableText/EditableText'

import './TextSection.scss'
import { generateClassNames } from '~utils/misc'

const TextSection: TTextSectionComponent = ({ section, theme }) => {
	const {
		sectionHeight,
		sectionWidth,
		alignText,
		alignVertical,
		alignHorizontal,
		designLayout
	} = section.params

	return (
		<div
			className={`Section TextSection ${generateClassNames({
				designLayout,
				alignText,
				sectionWidth,
				sectionHeight,
				alignVertical,
				alignHorizontal
			})}`}
			style={{
				background: theme?.background,
				color: theme?.text
			}}
		>
			<Container>
				<div className="content-wrapper">
					<div className="__content">
						{section.data.title && (
							<div
								className="title-content"
								style={{
									color: theme?.title
								}}
							>
								<EditableElement
									field="title"
									tag="h2"
									availableTags={['h1', 'h2']}
								/>
							</div>
						)}

						<div className="text-content">
							<EditableText field="text" />
						</div>
					</div>
				</div>
			</Container>
		</div>
	)
}

export default TextSection
