import React from 'react'

import { TRestaurantSectionComponent } from './RestaurantSection.types'

import './RestaurantSection.scss'
import HeaderRestaurantCover from './HeaderRestaurant/HeaderRestaurantCover/HeaderRestauranCover'
import HeaderRestaurantMain from './HeaderRestaurant/HeaderRestaurantMain/HeaderRestaurantMain'
import RestaurantRow from './RestaurantRow/RestaurantRow'
import MenuRestaurant from './MenuRestaurant/MenuRestaurant'
import BannerBasket from './Basket/BannerBasket/BannerBasket'
import { useMediaQuery } from '~utils/mediaQuery/useMediaQuery'
// import AddBasketModal from './AddBasketModal/AddBasketModal'

const RestaurantSection: TRestaurantSectionComponent = ({ theme }) => {
	const screenPhone = useMediaQuery('(max-width: 692px)')
	const screenIPad = useMediaQuery('(max-width: 878px)')

	const restaurentItemsList = [
		{
			title: 'Nos entrées',
			productList: [
				{
					image:
						'https://images.unsplash.com/photo-1482049016688-2d3e1b311543?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80',
					title: 'Oeufs mimosa',
					price: 4,
					description: 'Avocat & deux oeufs mimosa'
				},
				{
					image:
						'https://images.unsplash.com/photo-1498837167922-ddd27525d352?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80',
					title: 'Salade à la carte',
					price: 8,
					description: 'Amet minim mollit non deserunt ullamco est'
				},
				{
					image:
						'https://images.unsplash.com/photo-1576021182211-9ea8dced3690?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80',
					title: 'Salade de steak',
					price: 12,
					description: 'Salade de steak'
				},
				{
					image:
						'https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80',
					title: 'Assiette macabre',
					price: 9,
					description: 'Amet minim mollit non deserunt '
				}
			]
		},
		{
			title: 'Nos plats',
			productList: [
				{
					image:
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617957573/picture_kzwe3j.png',
					title: 'Hot-Dog',
					price: 8,
					description: 'Ptit hot-dog au calmus'
				},
				{
					image:
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617957571/picture_1_g4vhsh.png',
					title: 'Double Hot-Dog',
					price: 14,
					description: 'Ptit double hot-dog au calmus'
				},
				{
					image:
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617957574/picture_2_xcgb6m.png',
					title: 'Boules de viande',
					price: 10,
					description: 'ullamco est sit'
				},
				{
					image:
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617957571/picture_3_hjsyhf.png',
					title: 'Gourmet shit',
					price: 17,
					description: 'Amet minim mollit non deserunt ullamco est s'
				},
				{
					image:
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617957572/picture_4_lsjfak.png',
					title: 'Steak confiture',
					price: 16,
					description: 'Amet minim mollit'
				},
				{
					image:
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617957572/picture_5_jote5d.png',
					title: 'Dindon frites',
					price: 14,
					description: 'Amet minim mollit non deseru'
				},
				{
					image:
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617957572/picture_6_tidbme.png',
					title: 'Tarte à l’olive',
					price: 12,
					description: 'A deserunt ullamco est sit'
				}
			]
		},
		{
			title: 'Nos desserts 🍩',
			productList: [
				{
					image:
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617964144/picture_7_mmdaaa.png',
					title: 'Donuts',
					price: 7,
					description: 'Amet  mollit non deseru'
				},
				{
					image:
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617963914/picture_8_aogcm1.png',
					title: 'Crèpes fraise',
					price: 7,
					description: 'Amet  non deseru'
				},
				{
					image:
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617963914/picture_9_wu8t3l.png',
					title: 'Cookie',
					price: 2,
					description: 'Amet minim mollit deseru'
				},
				{
					image:
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617963913/picture_10_g07jzm.png',
					title: 'Truc sale',
					price: 4,
					description: 'Amet minim mollit non deseru'
				}
			]
		}
	]

	const restaurantItems = restaurentItemsList.map((item, index) => {
		return <RestaurantRow key={index} field={item} />
	})

	return (
		<div
			className="RestaurantSection"
			style={{
				background: theme.background,
				color: theme.text
			}}
		>
			{(!screenIPad || screenPhone) && <HeaderRestaurantCover />}
			<HeaderRestaurantMain />
			<MenuRestaurant />
			<div className="restaurant--content">{restaurantItems}</div>
			<BannerBasket />
		</div>
	)
}

export default RestaurantSection
