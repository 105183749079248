import React from 'react'

import Container from '~components/Common/Container/Container'

import { TPartenaireSectionComponent } from './PartenaireSection.types'

import './PartenaireSection.scss'
import { generateClassNames } from '~utils/misc'
import EditableText from '~components/Edition/Editables/EditableText/EditableText'
import EditableElement from '~components/Edition/Editables/EditableElement/EditableElement'
import EditableImage, {
	editableImage
} from '~components/Edition/Editables/EditableImage/EditableImage'
import { usePageContext } from '~contexts/page/pageContext'
import { v4 as uuidv4 } from 'uuid'

const PartenaireSection: TPartenaireSectionComponent = ({
	section,
	theme,
	onChange
}) => {
	const {
		sectionHeight,
		sectionWidth,
		alignText,
		alignVertical,
		alignHorizontal
	} = section.params
	const pageContext = usePageContext()

	const addPartenaire = () => {
		onChange({
			...section,
			data: {
				...section.data,
				items: [
					...section.data.items,
					{
						id: uuidv4(),
						image: editableImage(
							'https://images.unsplash.com/photo-1593642634367-d91a135587b5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
						)
					}
				]
			}
		})
		pageContext.refresh()
	}

	const removePartenaire = (it) => {
		onChange({
			...section,
			data: {
				...section.data,
				items: section.data.items.filter((item) => item.id != it.id)
			}
		})
		pageContext.refresh()
	}

	const items = section.data.items.map((item, index) => {
		return (
			<div className="list-item-content" key={item.id}>
				<EditableImage field={`items.${index}.image`} />
				{pageContext.editMode && (
					<button className="btn-remove" onClick={() => removePartenaire(item)}>
						<i className="mdi mdi-trash-can"></i>
					</button>
				)}
			</div>
		)
	})

	return (
		<div
			className={`Section PartenaireSection ${generateClassNames({
				alignText,
				sectionWidth,
				sectionHeight,
				alignVertical,
				alignHorizontal
			})}`}
			style={{
				background: theme?.background,
				color: theme?.text
			}}
		>
			<Container>
				<div className="content-wrapper">
					<div className="__content">
						<div className="title-content">
							<EditableElement field="title" tag="h2" />
						</div>
						{section.data.text ? (
							<div className="text-content">
								<EditableText field="text" />
							</div>
						) : (
							''
						)}
						<div className="list-content">
							{items}
							{pageContext.editMode && (
								<div
									className="list-item-content item-add"
									onClick={addPartenaire}
								>
									<i className="mdi mdi-plus"></i>
								</div>
							)}
						</div>
					</div>
				</div>
			</Container>
		</div>
	)
}

export default PartenaireSection
