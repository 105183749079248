import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import Modal from '~components/Common/Modal/Modal'
import Masonry from 'react-responsive-masonry'
import sections from '~core/sections'
import {
	TSectionDefinition,
	TSectionDefinitionPreset
} from '~types/Section.types'

import PlaceholderBox from '~components/Common/PlaceholderBox/PlaceholderBox'
import { useGlobalContext } from '~contexts/globalContext'
import { getColorLuminosity } from '~utils/misc'

import './AddSectionModal.scss'

const SectionPreview = ({
	onClick,
	preset
}: {
	onClick: () => void
	preset: TSectionDefinitionPreset<any>
}) => {
	return (
		<div className="SectionPreview" onClick={onClick}>
			<div className="preview">
				{preset.imagePreview ? preset.imagePreview : <PlaceholderBox />}
			</div>
			{preset.label && <div className="label">{preset.label}</div>}
		</div>
	)
}
const AddSectionModal = ({ isOpen, close, onSelect }) => {
	const [currentCategory, setCurrentCategory] = useState('title')
	const { website } = useGlobalContext()

	const category = (name: string) => ({
		active: name === currentCategory,
		handleClick: () => {
			setCurrentCategory(name)
		}
	})

	const findLightestBgColor = () =>
		website.theme.sectionThemes
			.map(({ background, id }) => {
				return {
					id,
					luminosity: getColorLuminosity(website.getPaletteColor(background))
				}
			})
			.sort((a, b) => b.luminosity - a.luminosity)[0].id

	// const findLightestColor = () =>
	// 	website.theme.sectionThemes
	// 		.map(({ text, id }) => ({
	// 			id,
	// 			luminosity: getColorLuminosity(website.getPaletteColor(text))
	// 		}))
	// 		.sort((a, b) => b.luminosity - a.luminosity)[0].id

	return (
		<Modal
			isOpen={isOpen}
			close={close}
			className="AddSectionModal"
			allowQuitOnClickOverlay={true}
			theme="dark"
		>
			<div className="sections">
				<div className="menu">
					<MenuItem label="Titre" {...category('title')} />
					<MenuItem label="Liste" {...category('list')} />
					<MenuItem label="Image" {...category('image')} />
					<MenuItem label="Texte" {...category('text')} />
					<MenuItem label="Article / Section libre" {...category('article')} />
					<MenuItem label="Citation & Témoignages" {...category('citation')} />
					<MenuItem label="Vidéo" {...category('video')} />
					<MenuItem label="E-commerce" {...category('ecommerce')} />
					<MenuItem label="Contact" {...category('contact')} />
					<MenuItem label="Partenaires" {...category('partenaire')} />
					{/* <MenuItem label="Restaurant" {...category('restaurant')} /> */}
					{/*<hr /><MenuItem label="Réseaux sociaux" {...category('social')} />*/}
				</div>

				<div className="mansory" key={currentCategory}>
					<Masonry columnsCount={2}>
						{Object.keys(sections)
							.map((sectionName) => sections[sectionName])
							.filter(
								({ categories, presets }) =>
									categories.includes(currentCategory) && !!presets
							)
							.reduce(
								(list, section) => [
									...list,
									...section.presets.map((preset) => ({
										section,
										preset
									}))
								],
								[]
							)
							.map(
								({
									section,
									preset
								}: {
									section: TSectionDefinition<any>
									preset: TSectionDefinitionPreset<any>
								}) => (
									<SectionPreview
										key={section.name}
										preset={preset}
										onClick={() => {
											const themeId = findLightestBgColor()
											const sectionId = uuidv4()
											onSelect({
												id: sectionId,
												type: section.name,
												...preset.defaultData,
												params: {
													...preset.defaultData.params,
													theme: themeId
												}
											})
											close()
										}}
									/>
								)
							)}
					</Masonry>
				</div>
			</div>
		</Modal>
	)
}

export default AddSectionModal

const MenuItem = ({ label, active, handleClick }) => {
	return (
		<button
			className={`MenuItem ${active ? 'active' : ''}`}
			onClick={handleClick}
		>
			{label}
		</button>
	)
}
