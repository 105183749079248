import React, { useEffect, useState } from 'react'
import Button from '~components/Common/Button/Button'
import { TModalComponent } from '~components/Common/Modal/Modal'
import EditableParamsModal from '~components/Edition/Common/EditableParamsModal/EditableParamsModal'
import { useGlobalContext } from '~contexts/globalContext'
import { TEditableImage } from '../EditableImage.types'

import './EditableImageParamsModal.scss'

const EditableImageParamsModal: TModalComponent<{
	onChange: (data: TEditableImage) => void
	canBeLink: boolean
	handleRemove: () => void
}> = ({ payload, canBeLink, onChange, handleRemove, ...modalProps }) => {
	const [data, setData] = useState<TEditableImage>(payload)
	const { website } = useGlobalContext()

	useEffect(() => {
		if (modalProps.isOpen) {
			setData(payload)
		} else {
			setData(null)
		}
	}, [modalProps.isOpen])

	const handleConfirm = () => {
		onChange(data)
		modalProps.close()
	}

	const pagesOptions = website.pages
		.filter((x) => x.enabled)
		.map((page) => ({
			name: `/${page.slug}`,
			value: page.id
		}))

	if (!data) return null

	return (
		<EditableParamsModal handleConfirm={handleConfirm} {...modalProps}>
			<EditableParamsModal.Field
				type="input"
				label="Description de l’image (SEO)"
				value={data.params.alt}
				placeholder="Ex : Pot de confiture maison"
				onChange={(alt) => {
					setData({
						...data,
						params: {
							...data.params,
							alt
						}
					})
				}}
			/>

			{canBeLink && (
				<>
					<EditableParamsModal.Field
						type="select"
						label="Pour ajouter un lien de redirection au clic sur l'image :"
						selectOptions={[
							{
								name: 'Lien interne',
								value: 'internal-link'
							},
							{
								name: 'URL',
								value: 'external-link'
							}
						]}
						value={data.params.actionType}
						onChange={(actionType) => {
							setData({
								...data,
								params: {
									...data.params,
									actionType
								}
							})
						}}
					/>
					{data.params.actionType === 'internal-link' && (
						<EditableParamsModal.Field
							type="select"
							label="Choisissez un lien"
							selectOptions={pagesOptions}
							value={data.params.pageLinkId}
							onChange={(pageLinkId) => {
								setData({
									...data,
									params: {
										...data.params,
										pageLinkId
									}
								})
							}}
						/>
					)}
					{data.params.actionType === 'external-link' && (
						<EditableParamsModal.Field
							type="input"
							label="URL de redirection"
							value={data.params.link}
							onChange={(link) => {
								setData({
									...data,
									params: {
										...data.params,
										link
									}
								})
							}}
						/>
					)}
					{handleRemove && (
						<Button
							style={{
								width: '100%'
							}}
							theme="error"
							textAlign="center"
							onClick={() => {
								handleRemove()
							}}
						>
							Supprimer l'image
						</Button>
					)}
				</>
			)}
		</EditableParamsModal>
	)
}

export default EditableImageParamsModal
