import React, { useState } from 'react'
import { usePageContext } from '~contexts/page/pageContext'
import {
	getObjectFieldByStringPath,
	updateObjectFieldByStringPath
} from '~utils/object-utils/object-utils'
import { TSectionContext } from './Section.types'

import './SectionContext.scss'

const sectionContext = React.createContext<TSectionContext>(null)

export const useSectionContext = () => React.useContext(sectionContext)

const SectionContext: TSectionContextComponent = ({
	sectionId,
	onChange,
	children
}) => {
	const pageContext = usePageContext()
	const [needDataUpdate, setneedDataUpdate] = useState(false)

	const getField = (field) => {
		try {
			const freshSection = pageContext.getSectionById(sectionId)

			return getObjectFieldByStringPath(freshSection.data, field)
		} catch (error) {
			setneedDataUpdate(true)
			return null
		}
	}

	const updateField = (field, updateData) => {
		if (!onChange) return

		// Prevent to get not uptodate data.
		const freshSection = pageContext.getSectionById(sectionId)

		onChange({
			...freshSection,
			data: updateObjectFieldByStringPath(freshSection.data, field, updateData)
		})
	}

	const updateSection = () => {
		const sectionDefaultData = pageContext.getSectionDefaultDataById(sectionId)
		const freshSection = pageContext.getSectionById(sectionId)

		const mergeData = (sectionData, defaultData) => {
			if (sectionData.__element) return sectionData
			const updatedSection = { ...sectionData }
			Object.keys(defaultData).forEach((key) => {
				if (!sectionData[key]) updatedSection[key] = defaultData[key]
				else updatedSection[key] = mergeData(sectionData[key], defaultData[key])
			})
			return updatedSection
		}
		const newData = mergeData(freshSection, sectionDefaultData)
		onChange(newData)
		setneedDataUpdate(false)
	}

	return (
		<sectionContext.Provider
			value={{
				getField,
				updateField
			}}
		>
			{needDataUpdate && pageContext.editMode && (
				<SectionDataUpdater onUpdate={updateSection} />
			)}
			{children}
		</sectionContext.Provider>
	)
}

export default SectionContext

type TSectionContextComponent = React.FC<{
	sectionId: string
	onChange?: (data: any) => void
}>

const SectionDataUpdater = ({ onUpdate }) => {
	return (
		<div
			onClick={onUpdate}
			style={{
				cursor: 'pointer',
				background: '#FFEDBD',
				padding: '20px',
				textAlign: 'center'
			}}
		>
			Il semble qu'il y ait un problème sur cette section. <br /> Cliquer ici
			pour la réparer.
		</div>
	)
}
