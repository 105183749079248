/* eslint-disable max-len */
import * as React from 'react';

const FacebookIcon = ({ color = 'white'}) => (
	<svg
		width="36"
		height="36"
		viewBox="0 0 36 36"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18ZM20.5826 8.22455H23.3678C23.4836 8.22486 23.5945 8.27098 23.6763 8.35283C23.7582 8.43468 23.8043 8.5456 23.8046 8.66135V11.9421C23.8043 12.058 23.7582 12.1691 23.6764 12.2511C23.5946 12.3331 23.4837 12.3795 23.3678 12.3801H21.4898C20.1998 12.3801 19.9598 12.8853 19.9598 13.8789V15.9454H23.2358C23.3522 15.9457 23.4637 15.992 23.546 16.0743C23.6283 16.1566 23.6747 16.2682 23.675 16.3846V19.9234C23.6749 19.9809 23.6634 20.0378 23.6412 20.0909C23.6191 20.144 23.5867 20.1922 23.5459 20.2327C23.5051 20.2733 23.4567 20.3054 23.4035 20.3273C23.3503 20.3491 23.2933 20.3603 23.2358 20.3602H19.961V29.2762C19.961 29.3336 19.9497 29.3905 19.9276 29.4436C19.9056 29.4967 19.8733 29.5449 19.8327 29.5855C19.792 29.626 19.7437 29.6582 19.6905 29.6801C19.6374 29.7019 19.5805 29.7131 19.523 29.713H15.8654C15.7496 29.713 15.6385 29.6669 15.5565 29.585C15.4746 29.5031 15.4286 29.392 15.4286 29.2762V20.3602H12.6266C12.5108 20.3602 12.3997 20.3141 12.3177 20.2322C12.2358 20.1503 12.1898 20.0392 12.1898 19.9234V16.3882C12.1896 16.3307 12.2008 16.2738 12.2227 16.2206C12.2446 16.1675 12.2767 16.1192 12.3173 16.0785C12.3579 16.0378 12.4061 16.0055 12.4592 15.9835C12.5122 15.9615 12.5691 15.9502 12.6266 15.9502H15.4286V13.5117C15.4286 10.3005 17.4518 8.22455 20.5826 8.22455Z"
			fill={color}
		/>
	</svg>
)

export default FacebookIcon;
