import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SectionPreviewImage from '~sections/common/SectionPreviewImage'

import CitationTextSection from './CitationTextSection'
import CitationTextSectionParams from './CitationTextSection.params'
import defaultData from './CitationTextSection.defaultData'
import { TCitationTextSection } from '~sections/CitationTextSection/CitationTextSection.types'
import { editableElement } from '~components/Edition/Editables/EditableElement/EditableElement'
import { TSectionDefinition } from '~types/Section.types'
import { generateLoremIpsum } from '~utils/misc'

const citationTextSection: TSectionDefinition<TCitationTextSection> = {
	name: 'citation-text-section',
	Component: CitationTextSection,
	Params: CitationTextSectionParams,
	categories: ['citation'],
	presets: [
		{
			imagePreview: <ImagePreview name="citationTextCenter" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: defaultData.data.items.filter((item, index) => index < 1)
				}
			}
		},
		{
			imagePreview: <ImagePreview name="citationTextTwo" />,
			defaultData: {
				...defaultData
			}
		},
		{
			imagePreview: <ImagePreview name="citationTextLeft" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: defaultData.data.items
						.filter((item, index) => index < 1)
						.map((item) => {
							return {
								...item,
								title: editableElement(generateLoremIpsum('normal'))
							}
						})
				},
				params: {
					...defaultData.params,
					alignText: 'left'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="citationTextCenterBg" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: defaultData.data.items.filter((item, index) => index < 1)
				},
				params: {
					...defaultData.params,
					backgroundImage:
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617901842/leonard-cotte-R5scocnOOdM-unsplash_1_pppnnh.png'
				}
			}
		}
	]
}

export default citationTextSection

export const query = graphql`
	query {
		citationTextCenter: file(
			relativePath: { eq: "section-preview/citationTextCenter.PNG" }
		) {
			...SectionPreviewImage
		}
		citationTextTwo: file(
			relativePath: { eq: "section-preview/citationTextTwo.PNG" }
		) {
			...SectionPreviewImage
		}
		citationTextLeft: file(
			relativePath: { eq: "section-preview/citationTextLeft.PNG" }
		) {
			...SectionPreviewImage
		}
		citationTextCenterBg: file(
			relativePath: { eq: "section-preview/citationTextCenterBg.PNG" }
		) {
			...SectionPreviewImage
		}
	}
`

function ImagePreview({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
