import React from 'react'

const BasketIcon = ({ color = '#172B49', backgroud = "white"}) => (
	<svg
		width="25"
		height="31"
		viewBox="0 0 25 31"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="1"
			y="8.34229"
			width="22.4737"
			height="21.6579"
			rx="2"
			stroke={color}
			strokeWidth="2"
		/>
		<circle
			cx="12.2366"
			cy="6.52632"
			r="5.52632"
			stroke={color}
			strokeWidth="2"
		/>
		<rect
			x="12.5938"
			y="9.34998"
			width="6.78125"
			height="6.78125"
			fill={backgroud}
		/>
	</svg>
)
export default BasketIcon
