import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SectionPreviewImage from '~sections/common/SectionPreviewImage'

import { editableImage } from '~components/Edition/Editables/EditableImage/EditableImage'
import { TSectionDefinition } from '~types/Section.types'
import TitleTextImageButtonSection from './TitleTextImageButtonSection'
import defaultData from './TitleTextImageButtonSection.defaultData'
import TitleTextImageButtonSectionParams from './TitleTextImageButtonSection.params'
import { TTitleTextImageButtonSection } from './TitleTextImageButtonSection.types'

const titleTextImageButtonSection: TSectionDefinition<TTitleTextImageButtonSection> = {
	name: 'title-text-image-button-section',
	Component: TitleTextImageButtonSection,
	Params: TitleTextImageButtonSectionParams,
	categories: ['title'],
	presets: [
		{
			imagePreview: <ImagePreview name="centerCardLeft" />,
			defaultData
		},
		{
			imagePreview: <ImagePreview name="centerCardRight" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					image: editableImage(
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617901726/photo-1523766775147-152d0d6e2adb_tmdq9o.png'
					)
				},
				params: {
					...defaultData.params,
					imagePosition: 'right'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="leftCardLeft" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					image: editableImage(
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617901801/photo-1446071103084-c257b5f70672_eoj2fk.png'
					)
				},
				params: {
					...defaultData.params,
					alignText: 'left'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="leftCardRight" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					image: editableImage(
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617901737/photo-1467196182985-b5de034d6cd4_fjpjwt.png'
					)
				},
				params: {
					...defaultData.params,
					imagePosition: 'right',
					alignText: 'left'
				}
			}
		},

		{
			imagePreview: <ImagePreview name="centerCollegeLeft" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					image: editableImage(
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617901875/annie-spratt-TywjkDHf0Ps-unsplash_duxran.png'
					)
				},
				params: {
					...defaultData.params,
					designLayout: 'collage'
				}
			}
		},

		{
			imagePreview: <ImagePreview name="centerCollegeRight" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					image: editableImage(
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617901875/annie-spratt-TywjkDHf0Ps-unsplash_duxran.png'
					)
				},
				params: {
					...defaultData.params,
					designLayout: 'collage',
					imagePosition: 'right'
				}
			}
		},

		{
			imagePreview: <ImagePreview name="rightCollege" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					image: editableImage(
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617901875/annie-spratt-TywjkDHf0Ps-unsplash_duxran.png'
					)
				},
				params: {
					...defaultData.params,
					designLayout: 'collage',
					imagePosition: 'right',
					alignText: 'left'
				}
			}
		},

		{
			imagePreview: <ImagePreview name="leftCollege" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					image: editableImage(
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617901875/annie-spratt-TywjkDHf0Ps-unsplash_duxran.png'
					)
				},
				params: {
					...defaultData.params,
					designLayout: 'collage',
					imagePosition: 'left',
					alignText: 'left'
				}
			}
		}
	]
}

export default titleTextImageButtonSection

export const query = graphql`
	query {
		centerCardLeft: file(
			relativePath: {
				eq: "section-preview/title-text-image-button-center-card-left.PNG"
			}
		) {
			...SectionPreviewImage
		}
		centerCardRight: file(
			relativePath: {
				eq: "section-preview/title-text-image-button-center-card-right.PNG"
			}
		) {
			...SectionPreviewImage
		}
		leftCardLeft: file(
			relativePath: {
				eq: "section-preview/title-text-image-button-left-card-left.PNG"
			}
		) {
			...SectionPreviewImage
		}
		leftCardRight: file(
			relativePath: {
				eq: "section-preview/title-text-image-button-left-card-right.PNG"
			}
		) {
			...SectionPreviewImage
		}
		centerCollegeLeft: file(
			relativePath: {
				eq: "section-preview/title-text-image-button-center-college-left.PNG"
			}
		) {
			...SectionPreviewImage
		}
		centerCollegeRight: file(
			relativePath: {
				eq: "section-preview/title-text-image-button-center-college-right.PNG"
			}
		) {
			...SectionPreviewImage
		}
		rightCollege: file(
			relativePath: {
				eq: "section-preview/title-text-image-button-right-college.PNG"
			}
		) {
			...SectionPreviewImage
		}
		leftCollege: file(
			relativePath: {
				eq: "section-preview/title-text-image-button-left-college.PNG"
			}
		) {
			...SectionPreviewImage
		}
	}
`

function ImagePreview({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
