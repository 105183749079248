import * as React from 'react'
import './RadioButton.scss'

type TRadioButton = React.DetailedHTMLProps<
	React.InputHTMLAttributes<HTMLInputElement>,
	HTMLInputElement
> & {
	label?: string
}
const RadioButton: React.FC<TRadioButton> = ({ label, ...props }) => {
	return (
		<label className="RadioButton">
			{label}
			<input {...props} type="radio" hidden />
			<span className="checkmark"></span>
		</label>
	)
}

export default RadioButton
