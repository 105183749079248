import { editableElement } from '~components/Edition/Editables/EditableElement/EditableElement'
import { editableText } from '~components/Edition/Editables/EditableText/EditableText'
import { generateLoremIpsum } from '~utils/misc'
import { TListTitleTextSection } from './ListTitleTextSection.types'

const ListTitleTextSectionDefaultData: TListTitleTextSection = {
	data: {
		title: editableElement('Dites-le avec le cœur'),
		items: [
			{
				title: editableElement('Inspirez'),
				text: editableText(generateLoremIpsum('big'))
			},
			{
				title: editableElement('Inspirez'),
				text: editableText(generateLoremIpsum('big'))
			},
			{
				title: editableElement('Inspirez'),
				text: editableText(generateLoremIpsum('big'))
			},
			{
				title: editableElement('Inspirez'),
				text: editableText(generateLoremIpsum('big'))
			},
			{
				title: editableElement('Inspirez'),
				text: editableText(generateLoremIpsum('big'))
			},
			{
				title: editableElement('Inspirez'),
				text: editableText(generateLoremIpsum('big'))
			},
			{
				title: editableElement('Inspirez'),
				text: editableText(generateLoremIpsum('big'))
			},
			{
				title: editableElement('Inspirez'),
				text: editableText(generateLoremIpsum('big'))
			}
		]
	},
	params: {
		sectionHeight: 'medium',
		sectionWidth: 'large',
		alignText: 'center',
		alignHorizontal: 'center',
		alignVertical: 'center',
		designLayout: 'above'
	}
}

export default ListTitleTextSectionDefaultData
