import React from 'react'
import './HeaderRestaurantClassicCover.scss'

const HeaderRestaurantClassicCover = () => {
	return (
		<div className="HeaderRestaurantClassicCover">
			<div className="headerCover--overly"></div>
		</div>
	)
}

export default HeaderRestaurantClassicCover
