import React from 'react'

import { TEditableSeparatorComponent } from './EditableSeparator.types'
import EditableSeparatorToolbar from './EditableSeparatorToolbar/EditableSeparatorToolbar'

import { useSectionContext } from '~components/Edition/SectionEditable/SectionContext/SectionContext'

import './EditableSeparator.scss'
import { usePageContext } from '~contexts/page/pageContext'

export const editableSeparator = () => ({
	__element: 'editable-separator',
	showRule: true
})

const EditableSeparator: TEditableSeparatorComponent = ({
	handleRemove,
	field,
	theme
}) => {
	const sectionContext = useSectionContext()
	const pageContext = usePageContext()

	const { showRule } = sectionContext.getField(field)

	return (
		<div className={`EditableSeparator ${showRule ? 'rule' : ''}`}>
			{pageContext.editMode && (
				<EditableSeparatorToolbar handleRemove={handleRemove} />
			)}
			<hr style={{ backgroundColor: theme.text }} />
		</div>
	)
}

export default EditableSeparator
