import { TContactSection } from './ContactSection.types'

const ContactSectionDefaultData: TContactSection = {
	data: {},
	params: {
		display: 'aside',
		activityIds: []
	}
}

export default ContactSectionDefaultData
