import { getPictureURL } from '~utils/cloudinary'

const itemsFromCategories = (categories: any[]): any[] => {
	// if (!Array.isArray(categories)) return []

	let items = []
	for (const category of categories) {
		if (category.marketItems?.length > 0) {
			category.marketItems.map((item) => {
				items.push(item)
			})
		}
		items = [...items, ...itemsFromCategories(category?.children || [])]
	}
	return items
}
export const getSelectectedMarketitems = (
	categories: any[],
	marketItems?: any[]
) => {
	if (!categories) return []
	const ids: any[] = []

	const itemsFromCat = itemsFromCategories(categories)

	itemsFromCat.map((item) => {
		if (item.selected) ids.push(item.id)
	})

	const allItemIdsFromCats = itemsFromCat.map((x) => x.id)
	if (marketItems && Array.isArray(marketItems)) {
		marketItems.map((item) => {
			if (item.selected && !allItemIdsFromCats.includes(item.id))
				ids.push(item.id)
		})
	}
	return ids
}

const getCategory = (cats: any[], categoryId) => {
	for (const cat of cats) {
		if (cat.id === categoryId) {
			return cat
		} else if (cat.children) {
			const val = getCategory(cat.children, categoryId)
			if (val) return val
		}
	}
	return null
}

const getCategoryProductIds = (category) => {
	let childenIds = []
	childenIds.push(...category.marketItems)
	if (category.children) {
		category?.children?.map((child) => {
			childenIds = [...childenIds, ...getCategoryProductIds(child)]
		})
	}

	return childenIds
}

export const getProductIdsOfCategories = (categories: any[], categoryId) => {
	const category = getCategory(categories, categoryId)

	if (!category) return []

	const rtn = getCategoryProductIds(category)
	return rtn
}

function arrayHasElements(array): array is Array<any> {
	return Array.isArray(array) && array.length > 0
}

const setCategorySelectedValue = (cat, value = false) => {
	cat.selected = value
	if (cat.marketItems?.length > 0) {
		cat.marketItems = cat.marketItems.map((item) => ({
			...item,
			selected: value
		}))
	}
	if (cat.children?.length > 0) {
		cat.children = cat.marketItems.map(
			(item) => (item = setCategorySelectedValue(item, value))
		)
	}
	return cat
}

const copyProperties = (newCat, oldCat) => {
	newCat.selected = oldCat.selected
	newCat.open = false
	if (arrayHasElements(newCat.marketItems)) {
		newCat.marketItems.map((newMaItem) => {
			let found = false
			if (Array.isArray(oldCat.marketItems)) {
				for (const oldMaItem of oldCat.marketItems) {
					if (oldMaItem.id === newMaItem.id) {
						found = true
						newMaItem.selected = oldMaItem.selected
						newMaItem.open = false
					}
				}
			}
			if (!found) {
				//console.log('newMaItem not found', newMaItem)
				newMaItem.selected = oldCat.selected
				newMaItem.open = false
				// console.log('newMaItem not found', newMaItem)
			}
		})
	}

	return newCat
}

export function margeCategories(
	newCats: any[],
	oldCats: any[],
	parentSelected = true
) {
	// if (!oldCats.length) return newCat
	newCats.map((newCat) => {
		let found = false
		for (const oldCat of oldCats) {
			if (oldCat.id === newCat.id) {
				found = true
				newCat = copyProperties(newCat, oldCat)
				if (
					arrayHasElements(newCat.children) &&
					arrayHasElements(oldCat.children)
				) {
					newCat.children = margeCategories(
						newCat.children,
						oldCat.children,
						oldCat.selected
					)
				}
			}
		}
		if (!found && !parentSelected) {
			newCat = setCategorySelectedValue(newCat, false)
			//console.log('Category not found', parentSelected, newCat)
		}
	})

	return newCats
}

export const categoryFormat = (
	input: any[],
	articles: any[],
	selectedValue = true
) => {
	const out = [...input]
	out.map((item) => {
		item.selected = selectedValue
		item.open = false

		const marketItems = []
		if (item.marketItems) {
			item.marketItems.map((miId) => {
				const article = articles.find((x) => x.id === miId)
				if (article) {
					const imageURL = getPictureURL(article.miniature, {
						h: 200,
						c: 'fill'
					})
					marketItems.push({
						id: miId,
						selected: selectedValue,
						title: article.title,
						url: imageURL
					})
				}
			})
			item.marketItems = marketItems
		}
		if (item.children) {
			item.children = categoryFormat(item.children, articles)
		}
		if (marketItems.length === 0 && !(item.children?.length > 0)) {
			item.deleted = true
		}
	})

	return out.filter((x) => !x.deleted)
}

export const formatMarketItems = (
	categories: any[],
	marketItems: any[],
	oldItems: any[],
	_itemType: string
) => {
	let productIds = []
	for (const category of categories) {
		productIds = [...productIds, ...getCategoryProductIds(category)]
	}

	if (marketItems.length > 0) {
		const tab = []
		marketItems.map((item) => {
			if (!productIds.includes(item.id) && item.type === _itemType) {
				const element = {
					selected: true,
					id: item.id,
					title: item.title,
					miniature: item.miniature
				}

				if (oldItems?.length > 0) {
					const oldIndx = oldItems.findIndex((x) => x.id === item.id)
					if (oldIndx >= 0) element.selected = oldItems[oldIndx].selected
				}

				tab.push(element)
			}
		})
		return tab
	}

	return []
}

export const applyProductFilters = (products, categories, filters) => {
	let productIds = []

	if (filters.category)
		productIds = getProductIdsOfCategories(categories, filters.category)

	return products.filter((product) => {
		let filter = true

		const searchFilterActive = !!filters.search
		if (searchFilterActive) {
			filter = product.title
				.toLowerCase()
				.includes(filters.search.toLowerCase())
		}

		const categoryFilterActive = filter && !!filters.category
		if (categoryFilterActive) {
			filter = productIds.findIndex((x) => x.id === product.id) >= 0
		}

		const genderFilterActiver = filter && filters.gender.length > 0
		if (genderFilterActiver) {
			filter = filters.gender.includes(product.informations.gender)
		}

		const whoFilterActive = filter && filters.who.length > 0
		if (whoFilterActive) {
			filter =
				filters.who.findIndex((x) => product.informations.who?.includes(x)) >= 0
		}

		return filter
	})
}
