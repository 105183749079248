import React from 'react'
import TimesIcon from '~components/Icons/TimesIcon'

import './EditableImageParams.scss'

const EditableImageParams = ({ isOpen, onClickOpen }) => {
	return (
		<div className="EditableImageParams">
			<button onClick={onClickOpen}>
				{isOpen ? <TimesIcon /> : <i className="mdi mdi-lead-pencil" />}
			</button>
		</div>
	)
}

export default EditableImageParams
