import React from 'react'
import Img from 'gatsby-image'

const SectionPreviewImage = ({ data }) => {
	const fixed = data?.childImageSharp?.fixed

	return fixed ? (
		<Img fixed={fixed} />
	) : (
		<img src="/images/section-preview/default-preview-section.jpg" />
	)
}

export default SectionPreviewImage
