import { Link } from '@reach/router'
import React from 'react'
import './MenuRestaurantClassic.scss'

const MenuRestaurantClassic = () => {
	return (
		<div className={`MenuRestaurantClassic`}>
			<div className="menuRestaurant--container">
				<div className="menuRestaurant--content">
					<Link to="" className="menuRestaurant__item active">
						Nos entrées
					</Link>
					<Link to="" className="menuRestaurant__item">
						Nos plats
					</Link>
					<Link to="" className="menuRestaurant__item">
						Nos desserts 🍩
					</Link>
				</div>
			</div>
		</div>
	)
}

export default MenuRestaurantClassic
