import React from 'react'
import Container from '~components/Common/Container/Container'
import { TGalerieSectionComponent } from './GalerieSection.types'

import EditableImage from '~components/Edition/Editables/EditableImage/EditableImage'

import './GalerieSection.scss'
import { generateClassNames } from '~utils/misc'

const GalerieSection: TGalerieSectionComponent = ({ section, theme }) => {
	const {
		sectionHeight,
		sectionWidth,
		alignHorizontal,
		designLayout,
		gridItems,
		backgroundWidth,
		backgroundImage,
		alignVertical
	} = section.params

	const items = section.data.items.map((item, index) => {
		return (
			<div className="galery-item" key={index}>
				<EditableImage field={`items.${index}.image`} />
			</div>
		)
	})
	return (
		<div
			className={`Section GalerieSection ${generateClassNames({
				designLayout,
				gridItems,
				sectionHeight,
				sectionWidth,
				alignVertical,
				alignHorizontal,
				backgroundWidth
			})}`}
			style={{
				background: theme?.background,
				color: theme?.text
			}}
		>
			<div
				className="background-image"
				style={{ background: `url("${backgroundImage}")` }}
			></div>
			<Container>
				<div className="content-wrapper">
					<div className="__content">
						{section.data.items.length ? (
							<div className="galery-content">{items}</div>
						) : (
							' '
						)}
					</div>
				</div>
			</Container>
		</div>
	)
}

export default GalerieSection
