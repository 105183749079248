import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SectionPreviewImage from '~sections/common/SectionPreviewImage'

import { TSectionDefinition } from '~types/Section.types'
import defaultData from './TitleTextButtonSection.defaultData'
import TitleTextButtonSection from './TitleTextButtonSection'
import TitleTextButtonSectionParams from './TitleTextButtonSection.params'
import { TTitleTextButtonSection } from './TitleTextButtonSection.types'

const titleTextButtonSection: TSectionDefinition<TTitleTextButtonSection> = {
	name: 'title-text-button-section',
	Component: TitleTextButtonSection,
	Params: TitleTextButtonSectionParams,
	categories: ['title'],
	presets: [
		{
			imagePreview: <PreviewImage name="center" />,
			defaultData
		},
		{
			imagePreview: <PreviewImage name="left" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					backgroundImage:
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617901847/antony-bec-nD9tEn63suc-unsplash_fzxc1s.png',
					alignText: 'left',
					sectionWidth: 'small',
					alignHorizontal: 'left'
				}
			}
		},
		{
			imagePreview: <PreviewImage name="right" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					backgroundImage:
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617901842/leonard-cotte-R5scocnOOdM-unsplash_1_pppnnh.png',
					sectionWidth: 'small',
					alignHorizontal: 'right',
					alignText: 'right'
				}
			}
		}
	]
}

export default titleTextButtonSection

export const query = graphql`
	query {
		center: file(
			relativePath: { eq: "section-preview/title-text-button.PNG" }
		) {
			...SectionPreviewImage
		}

		left: file(
			relativePath: { eq: "section-preview/title-text-button-left.PNG" }
		) {
			...SectionPreviewImage
		}

		right: file(
			relativePath: { eq: "section-preview/title-text-button-right.PNG" }
		) {
			...SectionPreviewImage
		}
	}
`

function PreviewImage({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
