import EditableButton from '~components/Edition/Editables/EditableButton/EditableButton'

import EditableElement from '~components/Edition/Editables/EditableElement/EditableElement'
import EditableImage from '~components/Edition/Editables/EditableImage/EditableImage'
import EditableSeparator from '~components/Edition/Editables/EditableSeparator/EditableSeparator'
import EditableText from '~components/Edition/Editables/EditableText/EditableText'
import { TSectionTheme } from '~types/Website.types'
import { TArticleSectionBlock } from '../ArticleSection.types'

export const getBlockEditableComponent = (block: TArticleSectionBlock) => {
	const EditableComponents = {
		'editable-element': EditableElement,
		'editable-text': EditableText,
		'editable-image': EditableImage,
		'editable-separator': EditableSeparator,
		'editable-button': EditableButton,
		'editable-vertical-spacing': EditableSeparator
	}

	const Component = EditableComponents[block.editable?.__element]

	if (!Component) return EmptyComponent

	return Component
}

export const getBlockEditableComponentProps = (
	block: TArticleSectionBlock,
	theme: TSectionTheme
): any => {
	if (block.editable.__element === 'editable-text') {
		return {
			toolbarConfig: {
				buttons: [
					'bold',
					'italic',
					'underline',
					// 'link',
					'ordered-list',
					'unordered-list',
					'remove'
				]
			}
		}
	}

	if (block.editable.__element === 'editable-element') {
		return {
			availableTags: ['h1', 'h2', 'h3', 'remove'],
			style: {
				color: theme?.title
			}
		}
	}

	if (block.editable.__element === 'editable-button') {
		return {
			theme: theme?.button,
			style: {
				margin: '10px auto'
			}
		}
	}

	if (block.editable.__element === 'editable-separator') {
		return {
			theme
		}
	}

	return {}
}

const EmptyComponent = () => null
