import React from 'react'

import './AddSectionButton.scss'

const AddSectionButton = ({ position, onClick }) => {
	return (
		<div className={`AddSectionButton position-${position}`}>
			<button onClick={onClick}>Ajouter une section</button>
		</div>
	)
}

export default AddSectionButton
