import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SectionPreviewImage from '~sections/common/SectionPreviewImage'

import CitationTitleTextSection from './CitationTitleTextSection'
import CitationTitleTextSectionParams from './CitationTitleTextSection.params'
import defaultData from './CitationTitleTextSection.defaultData'
import { TCitationTitleTextSection } from '~sections/CitationTitleTextSection/CitationTitleTextSection.types'
import { TSectionDefinition } from '~types/Section.types'
import { editableImage } from '~components/Edition/Editables/EditableImage/EditableImage'
import { editableElement } from '~components/Edition/Editables/EditableElement/EditableElement'
import { editableText } from '~components/Edition/Editables/EditableText/EditableText'
import { generateLoremIpsum } from '~utils/misc'

const citationTitleTextSection: TSectionDefinition<TCitationTitleTextSection> = {
	name: 'citation-title-text-section',
	Component: CitationTitleTextSection,
	Params: CitationTitleTextSectionParams,
	categories: ['citation'],
	presets: [
		{
			imagePreview: <ImagePreview name="citationTextTwoRow" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: defaultData.data.items.filter((item, index) => index < 2)
				},
				params: {
					...defaultData.params,
					sectionWidth: 'large',
					alignText: 'left'
				}
			}
		},

		{
			imagePreview: <ImagePreview name="citationTextThreeRow" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: defaultData.data.items.filter((item, index) => index < 3)
				},
				params: {
					...defaultData.params,
					sectionWidth: 'large',
					alignText: 'left',
					gridItems: 'three'
				}
			}
		},

		{
			imagePreview: <ImagePreview name="citationTextThreeAside" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: defaultData.data.items.filter((item, index) => index < 3)
				},
				params: {
					...defaultData.params,
					sectionWidth: 'large',
					designLayout: 'aside',
					alignText: 'left'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="citationTextFourRow" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					sectionWidth: 'large'
				}
			}
		},

		{
			imagePreview: <ImagePreview name="citationTextTwoAvatar" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: [
						{
							avatar: editableImage(
								'https://images.unsplash.com/photo-1599566150163-29194dcaad36?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80'
							),
							title: editableElement(generateLoremIpsum('normal')),
							text: editableText('— Citer la source')
						},
						{
							avatar: editableImage(
								'https://images.unsplash.com/photo-1607746882042-944635dfe10e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80'
							),
							title: editableElement(generateLoremIpsum('normal')),
							text: editableText('— Citer la source')
						}
					]
				},
				params: {
					...defaultData.params,
					sectionWidth: 'large',
					alignText: 'left'
				}
			}
		},

		{
			imagePreview: <ImagePreview name="citationTextFourAvatar" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: [
						{
							avatar: editableImage(
								'https://images.unsplash.com/photo-1599566150163-29194dcaad36?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80'
							),
							title: editableElement(generateLoremIpsum('normal')),
							text: editableText('— Citer la source')
						},
						{
							avatar: editableImage(
								'https://images.unsplash.com/photo-1607746882042-944635dfe10e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80'
							),
							title: editableElement(generateLoremIpsum('normal')),
							text: editableText('— Citer la source')
						},
						{
							avatar: editableImage(
								'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80'
							),
							title: editableElement(generateLoremIpsum('normal')),
							text: editableText('— Citer la source')
						},
						{
							avatar: editableImage(
								'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80'
							),
							title: editableElement(generateLoremIpsum('normal')),
							text: editableText('— Citer la source')
						}
					]
				},
				params: {
					...defaultData.params,
					sectionWidth: 'large'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="citationTextThreeAvatar" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: [
						{
							avatar: editableImage(
								'https://images.unsplash.com/photo-1599566150163-29194dcaad36?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80'
							),
							title: editableElement(generateLoremIpsum('normal')),
							text: editableText('— Citer la source')
						},
						{
							avatar: editableImage(
								'https://images.unsplash.com/photo-1607746882042-944635dfe10e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80'
							),
							title: editableElement(generateLoremIpsum('normal')),
							text: editableText('— Citer la source')
						},
						{
							avatar: editableImage(
								'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80'
							),
							title: editableElement(generateLoremIpsum('normal')),
							text: editableText('— Citer la source')
						}
					]
				},
				params: {
					...defaultData.params,
					sectionWidth: 'large',
					alignText: 'left',
					gridItems: 'three'
				}
			}
		}
	]
}

export default citationTitleTextSection

export const query = graphql`
	query {
		citationTextTwoRow: file(
			relativePath: { eq: "section-preview/citationTextTwoRow.PNG" }
		) {
			...SectionPreviewImage
		}
		citationTextThreeRow: file(
			relativePath: { eq: "section-preview/citationTextThreeRow.PNG" }
		) {
			...SectionPreviewImage
		}
		citationTextThreeAside: file(
			relativePath: { eq: "section-preview/citationTextThreeAside.PNG" }
		) {
			...SectionPreviewImage
		}
		citationTextFourRow: file(
			relativePath: { eq: "section-preview/citationTextFourRow.PNG" }
		) {
			...SectionPreviewImage
		}
		citationTextTwoAvatar: file(
			relativePath: { eq: "section-preview/citationTextTwoAvatar.PNG" }
		) {
			...SectionPreviewImage
		}
		citationTextFourAvatar: file(
			relativePath: { eq: "section-preview/citationTextFourAvatar.PNG" }
		) {
			...SectionPreviewImage
		}
		citationTextThreeAvatar: file(
			relativePath: { eq: "section-preview/citationTextThreeAvatar.PNG" }
		) {
			...SectionPreviewImage
		}
	}
`

function ImagePreview({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
