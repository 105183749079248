import { editableButton } from '~components/Edition/Editables/EditableButton/EditableButton'
import { TLastArticlesSection } from './LastArticlesSection.types'

const LastArticlesSectionDefaultData: TLastArticlesSection = {
	data: {
		button: editableButton('lorem ipsum')
	},
	params: {}
}

export default LastArticlesSectionDefaultData
