import React from 'react'

import './ToolBarForEditable.scss'

const ToolBarForEditable: TToolBarForEditable = ({ children, visible }) => {
	return (
		<div className={`ToolBarForEditable ${visible ? 'visible' : ''}`}>
			{children}
		</div>
	)
}

const ToolBarForEditableButton: ToolBarForEditableButton = ({
	children,
	handleClick,
	active = false
}) => {
	return (
		<button
			className={`${active ? 'active' : ''}`}
			onClick={() => {
				handleClick()
			}}
			onMouseDown={(e) => {
				e.preventDefault()
			}}
		>
			{children}
		</button>
	)
}

ToolBarForEditable.Button = ToolBarForEditableButton

export default ToolBarForEditable

type TToolBarForEditable = React.FC<{ visible: boolean }> & {
	Button: ToolBarForEditableButton
}

type ToolBarForEditableButton = React.FC<{
	handleClick: () => void
	active?: boolean
}>
