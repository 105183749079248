const pathStringToArray = (pathString: string) => pathString.split('.')

export const getObjectFieldByStringPath = (data, path) => {
	const pathArray = pathStringToArray(path)

	let current = data

	pathArray.forEach((field) => {
		current = current[Array.isArray(current) ? parseInt(field, 10) : field]

		if (!current) throw `Can't find path ${path}.`
	})

	return current
}

export const updateObjectFieldByStringPath = (data, field, updateData) => {
	const pathArray = pathStringToArray(field)
	const immutableUpdate = ([key, ...path]: string[], data) => {
		if (!key) return updateData

		if (Array.isArray(data)) {
			const index = parseInt(key, 10)

			if (!data?.[index]) throw "Can't find field"

			return data.map((item, i) =>
				i === index ? immutableUpdate(path, data[index]) : item
			)
		}

		if (!data) throw "Can't find field"

		return {
			...data,
			[key]: immutableUpdate(path, data[key])
		}
	}

	return immutableUpdate(pathArray, data)
}
