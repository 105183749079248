export const setAllSelected = (item: any, value: boolean) => {
	item.selected = value
	if (item.marketItems) {
		item.marketItems.map((markItem) => {
			markItem.selected = value
		})
	}
	if (item.children) {
		item.children.map((_child) => {
			_child = setAllSelected(_child, value)
		})
	}

	return item
}
