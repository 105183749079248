import React, { useRef, useState } from 'react'
import { Link } from 'gatsby'

import EditableImageParams from './EditableImageParams/EditableImageParams'

import useClickOutside from '~hooks/useClickOutside'

import { usePageContext } from '~contexts/page/pageContext'

import { useGlobalContext } from '~contexts/globalContext'

import { postPictureWithProgress } from '~queries/images.queries'
import { useAuthContext } from '~contexts/authContext/authContext'

import EditableImageParamsModal from './EditableImageParamsModal/EditableImageParamsModal'
import { useModalWithPayload } from '~components/Common/Modal/Modal'

import { TEditableImage } from './EditableImage.types'
import { useSectionContext } from '../../SectionEditable/SectionContext/SectionContext'

import './EditableImage.scss'
import { getUrlLink } from '~utils/misc'
import { getPictureURL } from '~utils/cloudinary'

export const editableImage = (url: string): TEditableImage => ({
	__element: 'editable-image',
	url,
	params: {
		alt: '',
		actionType: null,
		link: null,
		pageLinkId: null
	}
})

const EditableImage: TEditableImageComponent = ({
	field,
	canBeLink = true,
	handleRemove
}) => {
	const sectionContext = useSectionContext()
	const value = sectionContext.getField(field)
	const [paramIsOpen, setParamIsOpen] = useState(false)

	const { editMode } = usePageContext()

	const [isLoading, setIsLoading] = useState(false)
	const [loadingProgress, setLoadingProgress] = useState(0.4)

	const rootRef = useRef(null)
	const imageRef = useRef<HTMLImageElement>(null)
	const editableImageParamsModal = useModalWithPayload()

	useClickOutside(paramIsOpen, setParamIsOpen, rootRef)

	if (!value) return null

	return (
		<div
			className={`${editMode ? 'EditableImage' : 'Image'} ${
				paramIsOpen ? 'active' : ''
			}`}
			ref={rootRef}
		>
			{editMode && (
				<div className={`loading-overlay ${isLoading ? 'active' : ''}`}>
					<div
						className="progress"
						style={{
							transform: `scaleX(${1 - loadingProgress})`
						}}
					/>
					<i className="spinner mdi mdi-spin mdi-loading" />
				</div>
			)}

			{editMode && !isLoading && (
				<>
					<ImagePicker
						setLoadingProgress={setLoadingProgress}
						onSelectImage={(url) => {
							sectionContext.updateField(field, {
								__element: 'editable-text',
								...value,
								url
							})
						}}
						onBlobReady={(rawImage) => {
							imageRef.current.src = rawImage
						}}
						setIsLoading={setIsLoading}
					/>
					<EditableImageParamsModal
						onChange={(change) => {
							sectionContext.updateField(field, {
								__element: 'editable-text',
								...value,
								...change
							})
						}}
						canBeLink={canBeLink}
						handleRemove={handleRemove}
						{...editableImageParamsModal}
					/>

					<EditableImageParams
						isOpen={paramIsOpen}
						onClickOpen={() => {
							editableImageParamsModal.open(value)
						}}
					/>
				</>
			)}

			{editMode || !value?.params?.actionType ? (
				<img
					src={getPictureURL(value)}
					alt={value.params?.alt}
					ref={imageRef}
				/>
			) : (
				<ImageContainer imageRef={imageRef} value={value} />
			)}
		</div>
	)
}

export default EditableImage

const ImageContainer = ({ value, imageRef }) => {
	const globalContext = useGlobalContext()

	if (value?.params?.actionType === 'external-link')
		return (
			<a
				href={getUrlLink(value.params.link)}
				target="_blank"
				rel="noopener noreferrer"
			>
				<img src={getPictureURL(value)} alt={value.params.alt} ref={imageRef} />
			</a>
		)
	if (value?.params?.actionType === 'internal-link') {
		const page = globalContext.website.getPage(value.params.pageLinkId)
		if (!page?.enabled)
			return (
				<img src={getPictureURL(value)} alt={value.params.alt} ref={imageRef} />
			)
		return (
			<Link to={`/${page?.slug}`}>
				<img src={getPictureURL(value)} alt={value.params.alt} ref={imageRef} />
			</Link>
		)
	}
	return (
		<img src={getPictureURL(value)} alt={value.params?.alt} ref={imageRef} />
	)
}

type TEditableImageComponent = React.FC<{
	field: string
	canBeLink?: boolean
	handleRemove?: () => void
}>

const ImagePicker = ({
	onSelectImage,
	onBlobReady,
	setIsLoading,
	setLoadingProgress
}) => {
	const { token } = useAuthContext()
	const inputFileRef = useRef<HTMLInputElement>()
	const pageContext = usePageContext()

	const changeImage = async (file: File) => {
		setIsLoading(true)
		pageContext.setIsLoading(true)
		onBlobReady(URL.createObjectURL(file))

		const result = await postPictureWithProgress(
			file,
			token,
			setLoadingProgress
		)

		onSelectImage(result.url)
		setIsLoading(false)
		pageContext.setIsLoading(false)
	}

	return (
		<div className="ImagePicker">
			<input
				ref={inputFileRef}
				tabIndex={-1}
				onChange={(e) => {
					// if (!globalContext.auth.isConnected) return
					const files = Array.from(e.target.files)

					if (files.length === 0) return

					changeImage(files[0])
				}}
				type="file"
				style={{
					position: 'absolute',
					zIndex: 10,
					pointerEvents: 'none',
					opacity: 0,
					left: 0,
					width: 0,
					top: 0
				}}
			/>

			<button
				onClick={() => {
					inputFileRef.current.click()
				}}
			>
				<i className="mdi mdi-image" />
			</button>
		</div>
	)
}
