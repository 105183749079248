import React from 'react'
import TimesIcon from '~components/Icons/TimesIcon'

import './EditableVideoParams.scss'

const EditableVideoParams = ({ isOpen, onClickOpen }) => {
	return (
		<div className="EditableVideoParams">
			<button onClick={onClickOpen}>
				{isOpen ? <TimesIcon /> : <i className="mdi mdi-lead-pencil" />}
			</button>
		</div>
	)
}

export default EditableVideoParams
