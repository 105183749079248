import React, { Fragment, useRef, useState } from 'react'
import uniqid from 'uniqid'

import Container from '~components/Common/Container/Container'

import { TArticleSectionComponent } from './ArticleSection.types'

import ArticleSectionBlock from './ArticleSectionBlock/ArticleSectionBlock'

import { editableText } from '~components/Edition/Editables/EditableText/EditableText'
import { useSectionContext } from '~components/Edition/SectionEditable/SectionContext/SectionContext'

import Button from '~components/Common/Button/Button'

import { usePageContext } from '~contexts/page/pageContext'
import useClickOutside from '~hooks/useClickOutside'

import ArticleSectionBlockCreationPopup from './ArticleSectionBlock/ArticleSectionBlockCreationPopup/ArticleSectionBlockCreationPopup'
import { generateClassNames, getMousePositionFromMouseEvent } from '~utils/misc'
import { getEditableInitializerFromType } from './ArticleSection.utils'

import './ArticleSection.scss'

const ArticleSection: TArticleSectionComponent = ({ section, theme }) => {
	const [blocks, setBlocks] = useState(section.data.blocks)

	const pageContext = usePageContext()

	const sectionContext = useSectionContext()

	const handleUpdate = (blocks) => {
		sectionContext.updateField('blocks', blocks)
		setBlocks(blocks)
	}

	const { containerSize, contentAlign, sectionHeight } = section.params

	return (
		<div
			className={`ArticleSection ${
				pageContext.editMode ? 'edition' : ''
			} ${generateClassNames({ contentAlign, sectionHeight })}`}
			style={{
				background: theme.background,
				color: theme.text,
				textAlign: section.params.contentAlign
			}}
		>
			<Container size={containerSize}>
				<ArticleSectionBlocks
					handleUpdate={handleUpdate}
					blocks={blocks}
					theme={theme}
				/>

				{pageContext.editMode && blocks.length === 0 && (
					<CreateFirstBlockButton
						handleClick={() => {
							handleUpdate([
								{
									id: uniqid(),
									editable: editableText()
								}
							])
						}}
					/>
				)}
			</Container>
		</div>
	)
}

export default ArticleSection

const CreateFirstBlockButton = ({ handleClick }) => (
	<Button theme="blue" onClick={handleClick}>
		Ajouter un élément
	</Button>
)

const ArticleSectionBlocks = ({ handleUpdate, blocks, theme }) => {
	const [isFocused, setIsFocused] = useState(false)
	const rootRef = useRef<HTMLDivElement>()
	const [focusedAddButton, setFocusedAddButton] = useState(null)
	const pageContext = usePageContext()
	const sectionContext = useSectionContext()

	const [creationPopupData, setCreatePopupData] = useState({
		isOpen: false,
		index: null,
		position: null
	})

	useClickOutside(isFocused, setIsFocused, rootRef)

	const handleAddBlock = ({ type, index }) => {
		const editableInitializer = getEditableInitializerFromType(type)

		const blocks = sectionContext.getField('blocks')

		handleUpdate([
			...blocks.slice(0, index),
			{
				id: uniqid(),
				editable: editableInitializer
			},
			...blocks.slice(index)
		])
	}

	return (
		<div
			ref={rootRef}
			className={`ArticleSectionBlocks ${isFocused ? 'focused' : ''}`}
			onClick={() => {
				setIsFocused(
					(document.activeElement as HTMLDivElement)?.contentEditable === 'true'
				)
			}}
		>
			<ArticleSectionBlockCreationPopup
				isVisible={creationPopupData.isOpen}
				handleSelect={(type) => {
					handleAddBlock({
						index: creationPopupData.index,
						type
					})

					setCreatePopupData({
						index: null,
						isOpen: false,
						position: null
					})
				}}
				position={creationPopupData.position}
				handleClose={() => {
					setCreatePopupData({
						index: null,
						isOpen: false,
						position: null
					})
				}}
			/>

			{blocks.map((block, index) => (
				<Fragment key={block.id}>
					{pageContext.editMode && index === 0 && (
						<AddBlockButton
							handleClickWithMousePosition={(mousePosition) => {
								setCreatePopupData({
									index,
									isOpen: true,
									position: {
										x: mousePosition.x - 10,
										y: mousePosition.y - 10
									}
								})
							}}
							state={
								focusedAddButton === index
									? 'focused'
									: focusedAddButton === null
									? 'normal'
									: 'hidden'
							}
							handleMouseEnter={() => {
								setFocusedAddButton(index)
							}}
							handleMouseLeave={() => {
								setFocusedAddButton(null)
							}}
						/>
					)}
					<ArticleSectionBlock
						block={block}
						index={index}
						handleRemove={() => {
							const blocks = sectionContext.getField('blocks')
							handleUpdate(blocks.filter(({ id }) => id !== block.id))
						}}
						theme={theme}
					/>
					{pageContext.editMode && (
						<AddBlockButton
							handleClickWithMousePosition={(mousePosition) => {
								setCreatePopupData({
									index: index + 1,
									isOpen: true,
									position: {
										x: mousePosition.x - 10,
										y: mousePosition.y - 10
									}
								})
							}}
							state={
								focusedAddButton === index + 1
									? 'focused'
									: focusedAddButton === null
									? 'normal'
									: 'hidden'
							}
							handleMouseEnter={() => {
								setFocusedAddButton(index + 1)
							}}
							handleMouseLeave={() => {
								setFocusedAddButton(null)
							}}
						/>
					)}
				</Fragment>
			))}
		</div>
	)
}

const AddBlockButton = ({
	handleMouseEnter,
	handleMouseLeave,
	state,
	handleClickWithMousePosition
}) => {
	return (
		<div className={`AddBlockButton state-${state}`}>
			<button
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()

					const mousePosition = getMousePositionFromMouseEvent(e)

					handleClickWithMousePosition(mousePosition)
				}}
				onMouseEnter={() => {
					handleMouseEnter()
				}}
				onMouseLeave={() => {
					handleMouseLeave()
				}}
			>
				<i className="mdi mdi-plus" />
			</button>
		</div>
	)
}
