import React from 'react'
import { TMarketItem } from '~types/Market.types'
import BoutiqueProductItem from '../BoutiqueProductItem/BoutiqueProductItem'

import './BoutiqueProductList.scss'

const BoutiqueProductList: TBoutiqueProductList = ({ products }) => {
	return (
		<div className="BoutiqueProductList">
			{products.map((product) => (
				<BoutiqueProductItem key={product.id} item={product} />
			))}
		</div>
	)
}

export default BoutiqueProductList

type TBoutiqueProductList = React.FC<{
	products: TMarketItem[]
}>
