import React from 'react'
import Container from '~components/Common/Container/Container'
import { TListTitleTextSectionComponent } from './ListTitleTextSection.types'
import EditableElement from '~components/Edition/Editables/EditableElement/EditableElement'

import EditableText from '~components/Edition/Editables/EditableText/EditableText'

import './ListTitleTextSection.scss'
import { generateClassNames } from '~utils/misc'

const ListTitleTextSection: TListTitleTextSectionComponent = ({
	section,
	theme
}) => {
	const {
		sectionHeight,
		sectionWidth,
		alignText,
		alignHorizontal,
		designLayout,
		alignVertical
	} = section.params

	const items = section.data.items.map((item, index) => {
		return (
			<div className="list-item-content" key={index}>
				<div className="item-content-text">
					<EditableElement field={`items.${index}.title`} tag="h3" />
					<EditableText field={`items.${index}.text`} />
				</div>
			</div>
		)
	})
	return (
		<div
			className={`Section ListTitleTextSection
		${generateClassNames({
			designLayout,
			alignText,
			sectionHeight,
			sectionWidth,
			alignVertical,
			alignHorizontal
		})}`}
			style={{
				background: theme?.background,
				color: theme?.text
			}}
		>
			<Container>
				<div className="content-wrapper">
					<div className="__content">
						<div
							className="title-content"
							style={{
								color: theme?.title
							}}
						>
							<EditableElement field={`title`} tag="h2" />
						</div>
						<div className="list-content">{items}</div>
					</div>
				</div>
			</Container>
		</div>
	)
}

export default ListTitleTextSection
