import React from 'react'

import { formatNumber2digit } from '~utils/misc'
import { flexibleDateParse } from '~utils/dateFilter'

import './ContactSectionSchedule.scss'

const ContactSectionSchedule: React.FC<{
	data: any
	title?: any
	typo: any
}> = ({ data, title, typo }) => {
	const DayElement: React.FC<any> = ({ day }) => {
		if (!data) return <>fermé</>

		const days = [
			'monday',
			'tuesday',
			'wednesday',
			'thursday',
			'friday',
			'saturday',
			'sunday'
		]

		const dayData = data.days[days.indexOf(day)] //.find(d => d.name === day)

		if (!dayData || dayData.options.closed === true) return <>fermé</>

		const formatTime = (t) => {
			if (t.minutes) return `${t.hours}h${formatNumber2digit(t.minutes)}`
			return `${t.hours}h`
		}

		let pause = ''

		if (dayData.start_pause.hours) {
			pause = ` - ${formatTime(dayData.start_pause)} ${pause} / ${formatTime(
				dayData.end_pause
			)}`
		}

		const exist = (value) => !!value || value === 0

		if (
			!exist(dayData?.start?.hours) ||
			!exist(dayData?.start?.minutes) ||
			!exist(dayData?.end?.hours) ||
			!exist(dayData?.end?.minutes)
		)
			return <>Ouvert</>

		return (
			<>{`${formatTime(dayData.start)} ${pause} - ${formatTime(
				dayData.end
			)}`}</>
		)
	}

	return (
		<div className="ContactSectionSchedule">
			<h3 style={{ ...typo }}>
				{title ? (
					<>{title}</>
				) : (
					<>
						{data?.start_date
							? `Horaires du ${flexibleDateParse(data.start_date)?.format(
									'DD/MM/YYYY'
							  )} au ${flexibleDateParse(data.end_date)?.format('DD/MM/YYYY')}`
							: 'Horaire principale'}
					</>
				)}
			</h3>

			<div>
				Lundi : <DayElement day="monday" />
			</div>
			<div>
				Mardi : <DayElement day="tuesday" />
			</div>
			<div>
				Mercredi : <DayElement day="wednesday" />
			</div>
			<div>
				Jeudi : <DayElement day="thursday" />
			</div>
			<div>
				Vendredi : <DayElement day="friday" />
			</div>
			<div>
				Samedi : <DayElement day="saturday" />
			</div>
			<div>
				Dimanche : <DayElement day="sunday" />
			</div>
		</div>
	)
}

export default ContactSectionSchedule
