import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SectionPreviewImage from '~sections/common/SectionPreviewImage'

import { TSectionDefinition } from '~types/Section.types'
import ListTitleTextImageButtonSection from './ListTitleTextImageButtonSection'
import defaultData from './ListTitleTextImageButtonSection.defaultData'
import ListTitleTextImageButtonSectionParams from './ListTitleTextImageButtonSection.params'
import { TListTitleTextImageButtonSection } from './ListTitleTextImageButtonSection.types'

const listTitleTextImageButtonSection: TSectionDefinition<TListTitleTextImageButtonSection> = {
	name: 'list-title-text-image-button-section',
	Component: ListTitleTextImageButtonSection,
	Params: ListTitleTextImageButtonSectionParams,
	categories: ['list'],
	presets: [
		{
			imagePreview: <ImagePreview name="A" />,
			defaultData
		},
		{
			imagePreview: <ImagePreview name="B" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					alignText: 'left'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="C" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					designLayout: 'space'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="D" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					alignText: 'left',
					designLayout: 'space'
				}
			}
		}
	]
}

export default listTitleTextImageButtonSection

export const query = graphql`
	query {
		A: file(
			relativePath: {
				eq: "section-preview/list-title-text-image-button-center.PNG"
			}
		) {
			...SectionPreviewImage
		}

		B: file(
			relativePath: {
				eq: "section-preview/list-title-text-image-button-left.PNG"
			}
		) {
			...SectionPreviewImage
		}

		C: file(
			relativePath: {
				eq: "section-preview/list-title-text-image-button-space.PNG"
			}
		) {
			...SectionPreviewImage
		}

		D: file(
			relativePath: {
				eq: "section-preview/list-title-text-image-button-space-left.PNG"
			}
		) {
			...SectionPreviewImage
		}
	}
`

function ImagePreview({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
