import { useEffect } from 'react'
import { useState } from 'react'
import { isBrowser } from './misc'

import { compress, decompress } from 'lz-string'
import { usePageContext } from '~contexts/page/pageContext'

const setSessionItem = (id, data) => {
	sessionStorage.setItem(id, compress(data))
}

const getSessionItem = (id) => {
	const data = sessionStorage.getItem(id)

	if (!data) return null
	return decompress(data)
}

const getCachedData = (id, bypass) => {
	if (!isBrowser() || bypass) return null
	const data = getSessionItem(id)

	if (!data) return null

	try {
		return JSON.parse(data).data
	} catch {
		return null
	}
}

/*
 * In cache 5 minutes.
 * expiration in ms.
 */
export const needToReload = (id, expiration = 5 * 60 * 1000) => {
	if (!isBrowser()) return true
	const data = getSessionItem(id)

	if (!data) return true
	try {
		return new Date().getTime() - JSON.parse(data).last_update > expiration
	} catch {
		return true
	}
}

export const preloadDataQuery = (query, id, expiration?: number) => {
	if (!isBrowser()) return null
	if (!needToReload(id, expiration)) return

	return query().then((data) => {
		setSessionItem(
			id,
			JSON.stringify({
				data,
				last_update: new Date().getTime()
			})
		)
		return true
	})
}

export const useDataQueryPreloader = (query, id, expiration?: number) => {
	useEffect(() => {
		try {
			if (!needToReload(id, expiration)) return
			query().then((data) => {
				setSessionItem(
					id,
					JSON.stringify({
						data,
						last_update: new Date().getTime()
					})
				)
			})
		} catch (err) {
			console.error(err)
		}
	}, [])
}

export function useDataQuery<T>(
	query: () => Promise<any>,
	id: string,
	defaultValue: T,
	expiration?: number
): TUseDataQueryReturns<T> {
	const pageContext = usePageContext()
	const [isLoading, setIsLoading] = useState(true)
	const [data, setData] = useState<T>(
		getCachedData(id, pageContext.editMode) || defaultValue
	)

	useEffect(() => {
		try {
			if (!pageContext.editMode && !needToReload(id, expiration)) {
				setIsLoading(false)
				return
			}
			query()
				.then((data) => {
					setData(data)
					setSessionItem(
						id,
						JSON.stringify({
							data,
							last_update: new Date().getTime()
						})
					)
				})
				.finally(() => setIsLoading(false))
		} catch (err) {
			console.error(err)
			setIsLoading(false)
		}
	}, [])

	return [data, isLoading, setData]
}

type TUseDataQueryReturns<T> = [T, boolean, (data: T) => void]
