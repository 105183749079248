import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SectionPreviewImage from '~sections/common/SectionPreviewImage'

import { TSectionDefinition } from '~types/Section.types'
import GalerieSection from './GalerieSection'
import defaultData from './GalerieSection.defaultData'
import GalerieSectionParams from './GalerieSection.params'
import { TGalerieSection } from './GalerieSection.types'
import { editableImage } from '~components/Edition/Editables/EditableImage/EditableImage'

const galerieSection: TSectionDefinition<TGalerieSection> = {
	name: 'galerie-section',
	Component: GalerieSection,
	Params: GalerieSectionParams,
	categories: ['image'],
	presets: [
		{
			imagePreview: <ImagePreview name="E" />,
			defaultData: {
				...defaultData,
				data: {
					items: []
				},
				params: {
					...defaultData.params,
					backgroundImage:
						'https://images.unsplash.com/photo-1495171081156-cc1209ddf6a0?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
				}
			}
		},

		{
			imagePreview: <ImagePreview name="F" />,
			defaultData: {
				...defaultData,
				data: {
					items: []
				},
				params: {
					...defaultData.params,
					backgroundWidth: 'inset',
					backgroundImage:
						'https://images.unsplash.com/photo-1517772201340-0656311a16a7?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8\
					MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
				}
			}
		},

		{
			imagePreview: <ImagePreview name="G" />,
			defaultData: {
				...defaultData,
				data: {
					items: []
				},
				params: {
					...defaultData.params,
					backgroundWidth: 'band',
					backgroundImage:
						'https://images.unsplash.com/photo-1517772201340-0656311a16a7?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8\
					MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="A" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: [
						{
							image: editableImage(
								'https://images.unsplash.com/photo-1454391304352-2bf4678b1a7a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80'
							)
						},
						{
							image: editableImage(
								'https://images.unsplash.com/photo-1500375592092-40eb2168fd21?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
							)
						},
						{
							image: editableImage(
								'https://images.unsplash.com/photo-1502943693086-33b5b1cfdf2f?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
							)
						},
						{
							image: editableImage(
								'https://images.unsplash.com/photo-1502827413338-5a45f9a611df?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
							)
						}
					]
				},
				params: {
					...defaultData.params,
					gridItems: 'two'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="B" />,
			defaultData
		},
		{
			imagePreview: <ImagePreview name="C" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: [
						{
							image: editableImage(
								'https://images.unsplash.com/photo-1502943693086-33b5b1cfdf2f?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
							)
						},
						{
							image: editableImage(
								'https://images.unsplash.com/photo-1499202361610-95f5a9ad8345?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
							)
						},
						{
							image: editableImage(
								'https://images.unsplash.com/photo-1499202361610-95f5a9ad8345?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
							)
						},

						{
							image: editableImage(
								'https://images.unsplash.com/photo-1454391304352-2bf4678b1a7a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80'
							)
						}
					]
				},
				params: {
					...defaultData.params,
					gridItems: 'two',
					designLayout: 'grid'
				}
			}
		},

		{
			imagePreview: <ImagePreview name="D" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: [
						{
							image: editableImage(
								'https://images.unsplash.com/photo-1502943693086-33b5b1cfdf2f?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
							)
						},
						{
							image: editableImage(
								'https://images.unsplash.com/photo-1499202361610-95f5a9ad8345?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
							)
						},
						{
							image: editableImage(
								'https://images.unsplash.com/photo-1499202361610-95f5a9ad8345?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
							)
						},

						{
							image: editableImage(
								'https://images.unsplash.com/photo-1454391304352-2bf4678b1a7a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80'
							)
						},

						{
							image: editableImage(
								'https://images.unsplash.com/photo-1502827413338-5a45f9a611df?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
							)
						},
						{
							image: editableImage(
								'https://images.unsplash.com/photo-1495088259459-bc37e1849089?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
							)
						}
					]
				},
				params: {
					...defaultData.params,
					gridItems: 'three',
					designLayout: 'grid'
				}
			}
		}
	]
}

export default galerieSection

export const query = graphql`
	query {
		A: file(relativePath: { eq: "section-preview/galerie-basic-2.PNG" }) {
			...SectionPreviewImage
		}
		B: file(relativePath: { eq: "section-preview/galerie-basic-3.PNG" }) {
			...SectionPreviewImage
		}
		C: file(relativePath: { eq: "section-preview/galerie-2.PNG" }) {
			...SectionPreviewImage
		}
		D: file(relativePath: { eq: "section-preview/galerie-3.PNG" }) {
			...SectionPreviewImage
		}
		E: file(relativePath: { eq: "section-preview/galeryFull.PNG" }) {
			...SectionPreviewImage
		}
		F: file(relativePath: { eq: "section-preview/galeryInset.PNG" }) {
			...SectionPreviewImage
		}
		G: file(relativePath: { eq: "section-preview/galeryBand.PNG" }) {
			...SectionPreviewImage
		}
	}
`

function ImagePreview({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
