/* eslint-disable max-len */
import React from 'react'
import { useGlobalContext } from '~contexts/globalContext'
import { usePageContext } from '~contexts/page/pageContext'
import SocialLinks from '../common/SocialLinks'

import FooterMenu from './FooterMenu/FooterMenu'
import { NewsletterForm } from './NewsletterForm/NewsletterForm'

import './Footer.scss'

type FooterProps = {
	handleEdit?: () => void
	data?: any
	editionOverlay?: boolean
	disableLinks?: boolean
}

const Footer: React.FC<FooterProps> = ({
	handleEdit,
	data,
	editionOverlay = true,
	disableLinks
}) => {
	const pageContext = usePageContext()
	const globalContext = useGlobalContext()
	const website = globalContext?.website

	const footer = website?.theme?.footer

	if (!footer) return null

	const socials = data?.socials || website?.socials
	const showSocialLinks = data?.showSocialLinks || footer?.showSocialLinks

	const showNewsLetter =
		typeof data?.showNewsLetter == 'boolean'
			? data?.showNewsLetter
			: footer?.showNewsLetter

	const theme = data?.theme || website.getSectionTheme(footer.footerTheme)
	const backgroundColor = website.getPaletteColor(theme?.background)
	const textColor = website.getPaletteColor(theme?.text)
	const btnColor = website.getPaletteColor(theme?.button?.color)
	const btnBackgroundColor = website.getPaletteColor(theme?.button?.background)

	const menus = data?.menus || footer?.nav || []
	const showSocialColumn = Object.values(socials).join('').length > 0

	return (
		<div
			className="Footer"
			style={{
				backgroundColor,
				color: textColor
			}}
		>
			{pageContext.editMode && editionOverlay && (
				<FooterEditOverlay onClick={handleEdit} />
			)}

			<div className="columns">
				{showSocialLinks && showSocialColumn && (
					<div className="FooterSocials">
						<div className="title">Nous suivre</div>
						<SocialLinks
							socials={socials}
							color={textColor}
							bgColor={backgroundColor}
							disableLinks={pageContext.editMode}
						/>
					</div>
				)}

				{menus?.map((menu, i: number) => (
					<FooterMenu
						key={i}
						menu={menu.items}
						label={menu.label}
						disableLinks={disableLinks}
					/>
				))}

				{showNewsLetter && (
					<div>
						<div className="title">S’abonner à la newsletter</div>
						<NewsletterForm
							colors={{
								textColor,
								backgroundColor,
								btnColor,
								btnBackgroundColor
							}}
						/>
					</div>
				)}
			</div>
			<div className="copyright">
				<div className="brand">{data?.copyright || footer?.copyright}</div>
				<div className="all-right-reserved">© 2021 Tous droits réservés</div>
			</div>
		</div>
	)
}

export default Footer

const FooterEditOverlay = ({ onClick }) => {
	return (
		<button className="FooterEditOverlay" onClick={onClick}>
			<div>Modifier le pied de page</div>
		</button>
	)
}
