import React from 'react'
import Container from '~components/Common/Container/Container'
import { TListDecorationTitleTextImageButtonSectionComponent } from './ListDecorationTitleTextImageButtonSection.types'
import EditableElement from '~components/Edition/Editables/EditableElement/EditableElement'

import EditableButton from '~components/Edition/Editables/EditableButton/EditableButton'
import EditableImage from '~components/Edition/Editables/EditableImage/EditableImage'
import EditableText from '~components/Edition/Editables/EditableText/EditableText'

import './ListDecorationTitleTextImageButtonSection.scss'
import { generateClassNames } from '~utils/misc'

const ListDecorationTitleTextImageButtonSection: TListDecorationTitleTextImageButtonSectionComponent = ({
	section,
	theme
}) => {
	const {
		sectionHeight,
		sectionWidth,
		alignText,
		alignHorizontal,
		designLayout,
		alignVertical
	} = section.params

	const items = section.data.items.map((item, index) => {
		return (
			<div className="list-item-content" key={item.id}>
				<div className="image-content">
					<EditableImage field={`items.${index}.image`} />
				</div>
				<div className="image-card-wrapper">
					<div className="image-card">
						<EditableElement field={`items.${index}.title`} tag="h3" />
						<EditableText field={`items.${index}.text`} />
						<EditableButton
							field={`items.${index}.button`}
							theme={theme?.button}
						/>
					</div>
				</div>
			</div>
		)
	})
	return (
		<div
			className={`Section ListDecorationTitleTextImageButtonSection 
		${generateClassNames({
			designLayout,
			alignText,
			sectionHeight,
			sectionWidth,
			alignVertical,
			alignHorizontal
		})}`}
			style={{
				background: theme?.background,
				color: theme?.text
			}}
		>
			<Container>
				<div className="content-wrapper">
					<div className="__content">
						<EditableElement
							style={{
								color: theme?.title
							}}
							field={`title`}
							tag="h2"
						/>
						<div className="list-content">{items}</div>
					</div>
				</div>
			</Container>
		</div>
	)
}

export default ListDecorationTitleTextImageButtonSection
