import { gql } from 'apollo-boost'
import { useAuthContext } from '~contexts/authContext/authContext'
import { doGraphqlMutation, doGraphqlQuery } from './common.queries'

export const useNewsletterQueries = () => {
	const authContext = useAuthContext()

	const subscribeToNewsletter = async (email) => {
		const websiteId = process.env.GATSBY_WEBSITE_ID

		const mutation = gql(`
			mutation AddWebsiteNewsLetterEmail($email: String!, $websiteId: ID!) {
				addWebsiteNewsLetterEmail(email:$email, websiteId: $websiteId)
			}
		`)

		const variables = {
			websiteId,
			email
		}

		const data = await doGraphqlMutation({
			mutation,
			variables,
			apiVersion: 2,
			token: authContext.token
		})

		return data
	}

	const removeFromNewsletter = async (email) => {
		const websiteId = process.env.GATSBY_WEBSITE_ID

		const mutation = gql(`
			mutation RemoveWebsiteNewsLetterEmail($email: String!, $websiteId: ID!) {
				removeWebsiteNewsLetterEmail(email:$email, websiteId: $websiteId)
			}
		`)

		const variables = {
			websiteId,
			email
		}

		const data = await doGraphqlMutation({
			mutation,
			variables,
			apiVersion: 2,
			token: authContext.token
		})

		return data
	}

	const getNewsletter = async () => {
		const websiteId = process.env.GATSBY_WEBSITE_ID

		const query = gql(`
			query FetchBoutiqueCategories($websiteId: ID!){
				fetchWebsiteCustomers (websiteId: $websiteId) {
					newsLetterCustomers
					# customersHavingOrdered
					newsLetterCustomersHavingOrdered
				}
			}
		`)

		const variables = {
			websiteId
		}

		const { fetchWebsiteCustomers } = await doGraphqlQuery({
			query,
			variables,
			apiVersion: 2,
			token: authContext.token
		})

		return fetchWebsiteCustomers
	}

	return {
		subscribeToNewsletter,
		getNewsletter,
		removeFromNewsletter
	}
}
