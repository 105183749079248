const api = process.env.GATSBY_ADMIN_URI

export const postPictureWithProgress = (
	picture,
	token,
	progressCallback,
	type:
		| 'gallery'
		| 'icon'
		| 'cover'
		| 'mood'
		| 'filter'
		| 'logo'
		| 'banner' = 'gallery',
	size = 600
): Promise<any> =>
	new Promise((res, reject) => {
		const formData = new FormData()
		formData.append('picture', picture)

		const myHeaders = new Headers()
		myHeaders.append('x-access-token', token)

		const progressHandler = (e) => {
			progressCallback(e.loaded / e.total)
		}

		const completeHandler = () => {
			const { _id: id, ...picture } = JSON.parse(ajax.response)

			res({
				id,
				...picture
			})
		}

		const ajax = new XMLHttpRequest()
		ajax.open('POST', `${api}/pictures?type=${type}&parent=null&size=${size}`) //
		ajax.setRequestHeader('x-access-token', token)

		ajax.upload.addEventListener('progress', progressHandler, false)
		ajax.addEventListener('load', completeHandler, false)
		ajax.addEventListener('error', reject, false)
		ajax.addEventListener('abort', reject, false)
		ajax.send(formData)
	})

export const postPicture = async (
	picture,
	token,
	type:
		| 'gallery'
		| 'icon'
		| 'cover'
		| 'mood'
		| 'filter'
		| 'logo'
		| 'banner' = 'gallery',
	size = 600
) => {
	const formData = new FormData()
	formData.append('picture', picture)

	const myHeaders = new Headers()
	myHeaders.append('x-access-token', token)

	return await fetch(`${api}/pictures?type=${type}&parent=null&size=${size}`, {
		method: 'POST',
		body: formData,
		headers: myHeaders
	})
		.then((result) => result.json())
		.then(({ _id, ...picture }) => ({
			id: _id,
			...picture
		}))
}

export const putPicture = async (pictureID, token) => {
	const myHeaders = new Headers()
	myHeaders.append('x-access-token', token)
	return await fetch(`${api}/pictures/${pictureID}`, {
		method: 'PUT',
		headers: myHeaders
	})
		.then((result) => result.json())
		.then(({ _id, ...picture }) => ({
			id: _id,
			...picture
		}))
}

export const removePicture = async (pictureID, token) => {
	const myHeaders = new Headers()
	myHeaders.append('x-access-token', token)

	return await fetch(`${api}/pictures/${pictureID}`, {
		method: 'DELETE',
		headers: myHeaders
	})
}
