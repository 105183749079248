import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SectionPreviewImage from '~sections/common/SectionPreviewImage'

import { TSectionDefinition } from '~types/Section.types'
import ListTitleTextButtonSection from './ListTitleTextButtonSection'
import defaultData from './ListTitleTextButtonSection.defaultData'
import ListTitleTextButtonSectionParams from './ListTitleTextButtonSection.params'
import { TListTitleTextButtonSection } from './ListTitleTextButtonSection.types'
import { editableText } from '~components/Edition/Editables/EditableText/EditableText'
import { generateLoremIpsum } from '~utils/misc'

const listTitleTextButtonSection: TSectionDefinition<TListTitleTextButtonSection> = {
	name: 'list-title-text-button-section',
	Component: ListTitleTextButtonSection,
	Params: ListTitleTextButtonSectionParams,
	categories: ['list', 'text'],
	presets: [
		{
			imagePreview: <ImagePreview name="A" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: defaultData.data.items.filter((item, index) => index < 2)
				},
				params: {
					...defaultData.params,
					sectionHeight: 'medium',
					sectionWidth: 'medium'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="B" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: defaultData.data.items.filter((item, index) => index < 3)
				},
				params: {
					...defaultData.params,
					sectionHeight: 'medium',
					sectionWidth: 'large'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="C" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: defaultData.data.items.filter((item, index) => index < 6)
				},
				params: {
					...defaultData.params,
					sectionHeight: 'medium',
					sectionWidth: 'large',
					alignText: 'left',
					designLayout: 'wrap3'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="D" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: defaultData.data.items
						.filter((item, index) => index < 4)
						.map((item) => {
							return {
								...item,
								text: editableText(generateLoremIpsum('normal'))
							}
						})
				},
				params: {
					...defaultData.params,
					sectionHeight: 'medium',
					sectionWidth: 'large'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="E" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: defaultData.data.items.filter((item, index) => index < 4)
				},
				params: {
					...defaultData.params,
					sectionHeight: 'medium',
					sectionWidth: 'medium',
					designLayout: 'wrap2'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="F" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: defaultData.data.items.filter((item, index) => index < 6)
				},
				params: {
					...defaultData.params,
					sectionHeight: 'medium',
					sectionWidth: 'large',
					designLayout: 'wrap3'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="G" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: defaultData.data.items
						.filter((item, index) => index < 8)
						.map((item) => {
							return {
								...item,
								text: editableText(generateLoremIpsum('normal'))
							}
						})
				},
				params: {
					...defaultData.params,
					sectionHeight: 'medium',
					sectionWidth: 'large',
					designLayout: 'wrap4'
				}
			}
		}
	]
}

export default listTitleTextButtonSection

export const query = graphql`
	query {
		A: file(
			relativePath: {
				eq: "section-preview/list-title-text-button-two-center.PNG"
			}
		) {
			...SectionPreviewImage
		}
		B: file(
			relativePath: {
				eq: "section-preview/list-title-text-button-three-center.PNG"
			}
		) {
			...SectionPreviewImage
		}
		C: file(
			relativePath: {
				eq: "section-preview/list-title-text-button-six-left.PNG"
			}
		) {
			...SectionPreviewImage
		}
		D: file(
			relativePath: {
				eq: "section-preview/list-title-text-button-four-center.PNG"
			}
		) {
			...SectionPreviewImage
		}
		E: file(
			relativePath: {
				eq: "section-preview/list-title-text-button-four-center-wrap.PNG"
			}
		) {
			...SectionPreviewImage
		}
		F: file(
			relativePath: { eq: "section-preview/list-title-text-button-six.PNG" }
		) {
			...SectionPreviewImage
		}
		G: file(
			relativePath: {
				eq: "section-preview/list-title-text-button-eight-center.PNG"
			}
		) {
			...SectionPreviewImage
		}
	}
`

/*

list-title-text-button-two-center
list-title-text-button-three-center
list-title-text-button-six-left
list-title-text-button-four-center
list-title-text-button-four-center-wrap
list-title-text-button-four-center
list-title-text-button-eight-center

*/

function ImagePreview({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
