import React from 'react'
import Select from '~components/Common/Basics/Select/Select'

export const fonts = [
	{
		fontFamily: "'Montserrat', sans-serif",
		link: '',
		name: 'Montserrat',
		weights: [100, 200, 300, 400, 500, 600, 700, 800, 900]
	},
	{
		fontFamily: "'Gentium Book Basic', serif",
		link: '',
		name: 'Gentium Book Basic',
		weights: [400, 700]
	},
	{
		fontFamily: "'Chakra Petch', sans-serif",
		link: '',
		name: 'Chakra Petch',
		weights: [300, 400, 500, 600, 700]
	},
	{
		fontFamily: "'Dosis', sans-serif",
		link: '',
		name: 'Dosis',
		weights: [200, 300, 400, 500, 600, 700, 800]
	},
	{
		fontFamily: "'Inconsolata', monospace",
		link: '',
		name: 'Inconsolata',
		weights: [200, 300, 400, 500, 600, 700, 800, 900]
	},
	{
		fontFamily: "'Josefin Sans', sans-serif",
		link: '',
		name: 'Josefin Sans',
		weights: [100, 200, 300, 400, 500, 600, 700]
	},
	{
		fontFamily: "'Josefin Slab', serif",
		link: '',
		name: 'Josefin Slab',
		weights: [100, 200, 300, 400, 500, 600, 700]
	},
	{
		fontFamily: "'Lora', serif",
		link: '',
		name: 'Lora',
		weights: [400, 500, 600, 700]
	},
	{
		fontFamily: "'Newsreader', serif",
		link: '',
		name: 'Newsreader',
		weights: [200, 300, 400, 500, 600, 700, 800]
	},
	{
		fontFamily: "'Titillium Web', sans-serif",
		link: '',
		name: 'Titillium Web',
		weights: [200, 300, 400, 600, 700, 900]
	},
	{
		fontFamily: "'Zilla Slab', serif",
		link: '',
		name: 'Zilla Slab',
		weights: [300, 400, 500, 600, 700]
	},
	{
		fontFamily: "'Baloo 2', sans-serif",
		link: '',
		name: 'Baloo 2',
		weights: [400, 500, 600, 700, 800]
	},
	{
		fontFamily: "'Lobster Two', sans-serif",
		link: '',
		name: 'Lobster Two',
		weights: [400, 700]
	},
	{
		fontFamily: "'Parisienne', sans-serif",
		link: '',
		name: 'Parisienne',
		weights: [400]
	},
	{
		fontFamily: "'Permanent Marker', sans-serif",
		link: '',
		name: 'Permanent Marker',
		weights: [400]
	},
	{
		fontFamily: "'Beth Ellen', sans-serif",
		link: '',
		name: 'Beth Ellen',
		weights: [400]
	},
	{
		fontFamily: "'Special Elite', sans-serif",
		link: '',
		name: 'Special Elite',
		weights: [400]
	}
]

const TypoSelector = ({ fontFamily, handleUpdate }) => {
	return (
		<Select
			buttonElement={{
				style: {
					width: 200
				}
			}}
			value={fontFamily}
			onChange={handleUpdate}
			options={fonts.map((font) => ({
				name: font.name,
				value: font.fontFamily,
				element: {
					style: {
						fontFamily: font.fontFamily
					}
				}
			}))}
		/>
	)
}

export default TypoSelector
