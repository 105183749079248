import { editableElement } from '~components/Edition/Editables/EditableElement/EditableElement'
import { editableImage } from '~components/Edition/Editables/EditableImage/EditableImage'
import { editableText } from '~components/Edition/Editables/EditableText/EditableText'
import { generateLoremIpsum } from '~utils/misc'
import { TCitationTextImageSection } from './CitationTextImageSection.types'

const CitationTextImageSectionDefaultData: TCitationTextImageSection = {
	data: {
		title: editableElement(generateLoremIpsum('normal')),
		text: editableText('— Citer la source'),
		image: editableImage(
			'https://images.unsplash.com/photo-1597638620263-dd007c83ce3e?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
		)
	},
	params: {
		sectionHeight: 'large',
		sectionWidth: 'large',
		alignText: 'center',
		alignHorizontal: 'center',
		alignVertical: 'center',
		designLayout: 'card',
		imagePosition: 'left',
		titleTag: 'h2'
	}
}

export default CitationTextImageSectionDefaultData
