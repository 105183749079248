import React from 'react'
import ArrowIcon from '~components/Icons/ArrowIcon'
import { useAuthContext } from '~contexts/authContext/authContext'
import { useBasketContext } from '~contexts/basketContext'
import Button from '../Button/Button'
import Container from '../Container/Container'

import './BasketBottomBar.scss'

const BasketBottomBar: TBasketBottomBar = () => {
	const authContext = useAuthContext()
	const basketContext = useBasketContext()

	if (authContext.isConnected) return null
	const basketHasItems = basketContext.items.length > 0

	if (!basketHasItems) return null

	return (
		<div className="BasketBottomBar">
			<Container>{basketHasItems ? <FullBasket /> : <EmptyBasket />}</Container>
		</div>
	)
}

export default BasketBottomBar

type TBasketBottomBar = React.FC

const FullBasket = () => (
	<div className="FullBasket">
		<div>
			<BasketDetailsDropdown />
		</div>

		<div>
			<Button
				theme="blue"
				textAlign="center"
				style={{
					width: 176
				}}
			>
				Valider
				<ArrowIcon direction="right" color="light" height={20} width={20} />
			</Button>
		</div>
	</div>
)

const BasketDetailsDropdown = () => {
	const { getTotal } = useBasketContext()
	// const [isOpen, setIsOpen] = useState(false)

	return (
		<div className="BasketDetailsDropdown">
			{/* <div className={`dropdown ${isOpen ? 'active' : ''}`}>
				{items.map(({ item, id }) => (
					<div key={id}>{item.item.title}</div>
				))}
			</div> */}

			<button
			// onClick={() => {
			// 	setIsOpen(!isOpen)
			// }}
			>
				<span>Votre panier - {getTotal()}€</span>
				{/* <i className="chevron mdi mdi-chevron-down" /> */}
			</button>
		</div>
	)
}

const EmptyBasket = () => null
// <div className="EmptyBasket">Votre panier est vide</div>
