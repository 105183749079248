import React from 'react'
import Modal, { TModalComponent } from '~components/Common/Modal/Modal'

import EditableParamsModalActions from './EditableParamsModalActions/EditableParamsModalActions'
import EditableParamsModalFields, {
	EditableParamsModalField
} from './EditableParamsModalFields/EditableParamsModalFields'

import './EditableParamsModal.scss'

const EditableParamsModal: TEditableParamsModal = ({
	children,
	className = '',
	handleConfirm,
	...modalProps
}) => {
	const handleCancel = () => {
		modalProps.close()
	}

	return (
		<Modal
			portal={true}
			className={`EditableParamsModal ${className}`}
			{...modalProps}
			showCloseButton={true}
		>
			<EditableParamsModalFields>{children}</EditableParamsModalFields>
			<EditableParamsModalActions
				handleCancel={handleCancel}
				handleConfirm={handleConfirm}
			/>
		</Modal>
	)
}

EditableParamsModal.Field = EditableParamsModalField

export default EditableParamsModal

type TEditableParamsModal = TModalComponent<{
	handleConfirm: () => void
}> & {
	Field: any
}
