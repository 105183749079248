import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SectionPreviewImage from '~sections/common/SectionPreviewImage'

import CitationTitleTextSection from './CitationTextTextSection'
import CitationTitleTextSectionParams from './CitationTextTextSection.params'
import defaultData from './CitationTextTextSection.defaultData'
import { TCitationTitleTextSection } from '~sections/CitationTitleTextSection/CitationTitleTextSection.types'
import { TSectionDefinition } from '~types/Section.types'
import { editableImage } from '~components/Edition/Editables/EditableImage/EditableImage'
import { editableText } from '~components/Edition/Editables/EditableText/EditableText'
import { generateLoremIpsum } from '~utils/misc'

const citationTitleTextSection: TSectionDefinition<TCitationTitleTextSection> = {
	name: 'citation-text-text-section',
	Component: CitationTitleTextSection,
	Params: CitationTitleTextSectionParams,
	categories: ['citation'],
	presets: [
		{
			imagePreview: <ImagePreview name="citationTextTextThreeAvatar" />,
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: [
						{
							avatar: editableImage(
								'https://images.unsplash.com/photo-1599566150163-29194dcaad36?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80'
							),
							title: editableText(generateLoremIpsum('normal')),
							text: editableText('— Citer la source')
						},
						{
							avatar: editableImage(
								'https://images.unsplash.com/photo-1607746882042-944635dfe10e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80'
							),
							title: editableText(generateLoremIpsum('normal')),
							text: editableText('— Citer la source')
						},
						{
							avatar: editableImage(
								'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80'
							),
							title: editableText(generateLoremIpsum('normal')),
							text: editableText('— Citer la source')
						}
					]
				},
				params: {
					...defaultData.params,
					sectionWidth: 'large',
					alignText: 'left',
					gridItems: 'three'
				}
			}
		}
	]
}

export default citationTitleTextSection

export const query = graphql`
	query {
		citationTextTextThreeAvatar: file(
			relativePath: { eq: "section-preview/citationTextTextThreeAvatar.png" }
		) {
			...SectionPreviewImage
		}
	}
`

function ImagePreview({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
