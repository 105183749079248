/* eslint-disable max-len */
import * as React from 'react';

const InstagramIcon = ({ color = 'white'}) => (
	<svg
		width="36"
		height="36"
		viewBox="0 0 36 36"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M21.4459 18.0006C21.4459 19.9034 19.9034 21.4459 18.0006 21.4459C16.0978 21.4459 14.5553 19.9034 14.5553 18.0006C14.5553 16.0977 16.0978 14.5553 18.0006 14.5553C19.9034 14.5553 21.4459 16.0977 21.4459 18.0006Z"
			fill={color}
		/>
		<path
			d="M26.0577 11.9045C25.8921 11.4557 25.6279 11.0495 25.2846 10.7161C24.9511 10.3728 24.5452 10.1085 24.0961 9.94292C23.7319 9.80147 23.1848 9.6331 22.1771 9.58723C21.087 9.53752 20.7601 9.52681 18.0004 9.52681C15.2403 9.52681 14.9135 9.53725 13.8236 9.58696C12.8159 9.6331 12.2685 9.80147 11.9046 9.94292C11.4555 10.1085 11.0493 10.3728 10.7162 10.7161C10.3728 11.0495 10.1086 11.4555 9.94272 11.9045C9.80127 12.2687 9.6329 12.8161 9.58704 13.8238C9.53732 14.9137 9.52661 15.2405 9.52661 18.0006C9.52661 20.7603 9.53732 21.0872 9.58704 22.1773C9.6329 23.185 9.80127 23.7321 9.94272 24.0963C10.1086 24.5454 10.3726 24.9513 10.7159 25.2848C11.0493 25.6281 11.4553 25.8923 11.9043 26.0579C12.2685 26.1997 12.8159 26.368 13.8236 26.4139C14.9135 26.4636 15.2401 26.474 18.0001 26.474C20.7604 26.474 21.0873 26.4636 22.1768 26.4139C23.1845 26.368 23.7319 26.1997 24.0961 26.0579C24.9976 25.7102 25.71 24.9978 26.0577 24.0963C26.1992 23.7321 26.3676 23.185 26.4137 22.1773C26.4634 21.0872 26.4739 20.7603 26.4739 18.0006C26.4739 15.2405 26.4634 14.9137 26.4137 13.8238C26.3678 12.8161 26.1995 12.2687 26.0577 11.9045ZM18.0004 23.3078C15.0689 23.3078 12.6926 20.9317 12.6926 18.0003C12.6926 15.0689 15.0689 12.6928 18.0004 12.6928C20.9315 12.6928 23.3079 15.0689 23.3079 18.0003C23.3079 20.9317 20.9315 23.3078 18.0004 23.3078ZM23.5177 13.7233C22.8327 13.7233 22.2773 13.168 22.2773 12.483C22.2773 11.798 22.8327 11.2426 23.5177 11.2426C24.2027 11.2426 24.7581 11.798 24.7581 12.483C24.7578 13.168 24.2027 13.7233 23.5177 13.7233Z"
			fill={color}
		/>
		<path
			d="M18 0C8.06039 0 0 8.06039 0 18C0 27.9396 8.06039 36 18 36C27.9396 36 36 27.9396 36 18C36 8.06039 27.9396 0 18 0ZM28.2736 22.2613C28.2236 23.3616 28.0486 24.1128 27.7932 24.7703C27.2563 26.1587 26.1587 27.2563 24.7703 27.7932C24.1131 28.0486 23.3616 28.2233 22.2616 28.2736C21.1594 28.3239 20.8073 28.3359 18.0003 28.3359C15.193 28.3359 14.8412 28.3239 13.7387 28.2736C12.6387 28.2233 11.8872 28.0486 11.2299 27.7932C10.54 27.5337 9.91544 27.1269 9.39908 26.6009C8.87338 26.0848 8.46661 25.46 8.20706 24.7703C7.95163 24.1131 7.77667 23.3616 7.72668 22.2616C7.67587 21.1591 7.66406 20.807 7.66406 18C7.66406 15.193 7.67587 14.8409 7.72641 13.7387C7.7764 12.6384 7.95108 11.8872 8.20651 11.2297C8.46606 10.54 8.87311 9.91516 9.39908 9.39908C9.91516 8.87311 10.54 8.46634 11.2297 8.20679C11.8872 7.95135 12.6384 7.77667 13.7387 7.72641C14.8409 7.67615 15.193 7.66406 18 7.66406C20.807 7.66406 21.1591 7.67615 22.2613 7.72668C23.3616 7.77667 24.1128 7.95135 24.7703 8.20651C25.46 8.46606 26.0848 8.87311 26.6012 9.39908C27.1269 9.91544 27.5339 10.54 27.7932 11.2297C28.0489 11.8872 28.2236 12.6384 28.2739 13.7387C28.3241 14.8409 28.3359 15.193 28.3359 18C28.3359 20.807 28.3241 21.1591 28.2736 22.2613Z"
			fill={color}
		/>
	</svg>
)

export default InstagramIcon;
