import React from 'react'
import Container from '~components/Common/Container/Container'
import EditableElement from '~components/Edition/Editables/EditableElement/EditableElement'

import EditableButton from '~components/Edition/Editables/EditableButton/EditableButton'
import { TTitleTextButtonSectionComponent } from './TitleTextButtonSection.types'

import EditableText from '~components/Edition/Editables/EditableText/EditableText'

import './TitleTextButtonSection.scss'
import { generateClassNames } from '~utils/misc'

const TitleTextButtonSection: TTitleTextButtonSectionComponent = ({
	section,
	theme
}) => {
	const {
		backgroundImage,
		sectionHeight,
		sectionWidth,
		alignText,
		alignVertical,
		alignHorizontal
	} = section.params

	return (
		<div
			className={`Section TitleTextButtonSection  ${generateClassNames({
				alignText,
				sectionHeight,
				sectionWidth,
				alignVertical,
				alignHorizontal
			})}`}
			style={{
				background: backgroundImage
					? `url("${backgroundImage}")`
					: theme?.background,
				color: theme?.text
			}}
		>
			<Container>
				<div className="content-wrapper">
					<div className="__content">
						<EditableElement
							style={{
								color: theme?.title
							}}
							field="title"
							tag="h2"
						/>
						<EditableText field="text" />
						<EditableButton theme={theme?.button} field="button" />
					</div>
				</div>
			</Container>
		</div>
	)
}

export default TitleTextButtonSection
