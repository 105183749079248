import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import Container from '~components/Common/Container/Container'

import { TBoutiqueSectionComponent } from './BoutiqueSection.types'

import BoutiqueMenu from './components/BoutiqueMenu/BoutiqueMenu'

import { useDataQuery } from '~utils/useDataQuery'

import {
	fetchWebsiteProducts,
	fetchWebsiteServices,
	getBoutiqueCategories
} from '~queries/boutique.queries'

import { useBoutiqueFilters } from './components/hooks/useBoutiqueFilter'

import BoutiqueResult from './components/BoutiqueResult/BoutiqueResult'

import {
	margeCategories,
	categoryFormat,
	formatMarketItems,
	applyProductFilters,
	getSelectectedMarketitems
} from './BoutiqueSection.utils'

import './BoutiqueSection.scss'

const BoutiqueSectionContainer: TBoutiqueSectionComponent = ({
	section,
	theme
}) => {
	const [loading, setLoading] = useState(true)
	const [articles, articlesLoading] = useDataQuery(
		async () =>
			section.params.type === 'product'
				? fetchWebsiteProducts()
				: fetchWebsiteServices(),
		'boutique-items',
		[],
		2
	)
	// console.log(`section?.params`, section?.params)
	const [categories, setcategories] = useState(
		section?.params?.categories || []
	)
	useEffect(() => {
		setcategories(section?.params?.categories || [])
		setmarketItems(section?.params?.marketItems || [])
	}, [section?.params])

	const [marketItems, setmarketItems] = useState(
		section?.params?.marketItems || []
	)

	const [boutiqueCategories, boutiqCatsLoading] = useDataQuery(
		async () => getBoutiqueCategories(),
		'categoy-items',
		[],
		2
	)

	useEffect(() => {
		if (!articlesLoading && !boutiqCatsLoading && loading) {
			const markItems = formatMarketItems(
				boutiqueCategories,
				articles,
				marketItems,
				section?.params?.type
			)

			const formated = categoryFormat(boutiqueCategories, articles)

			const merged = margeCategories(formated, categories)
			setcategories(merged)
			setmarketItems(markItems)
			setLoading(false)
		}
	}, [articlesLoading, boutiqCatsLoading])

	const sectionType = section.params.type

	const applyFilters = (items: any[]) => {
		if (!section?.params?.categories)
			items.filter((x) => x.type === sectionType)
		const selectedMarketitems = getSelectectedMarketitems(
			categories,
			marketItems
		)

		// console.log(`selectedMarketitems`, selectedMarketitems)

		const filtered = items.filter((product) => {
			return (
				selectedMarketitems.includes(product.id) && product.type === sectionType
			)
		})

		if (filtered.length === 0)
			return items.filter((x) => x.type === sectionType)
		return filtered
	}

	const { type } = section.params
	const fullyLoaded = loading && articlesLoading && boutiqCatsLoading
	return (
		<BoutiqueSection
			loading={fullyLoaded}
			categories={categories}
			articles={loading ? [] : applyFilters(articles)}
			theme={theme}
			type={type}
		/>
	)
}

export default BoutiqueSectionContainer

const BoutiqueSection: React.FC<any> = ({
	articles,
	categories,
	theme,
	type,
	loading
}) => {
	const [filters, updateFilters] = useBoutiqueFilters()

	const [detectorRef, isInViewport] = useInView({
		rootMargin: '10000px 0px -50px 0px'
	})

	return (
		<div
			className={`BoutiqueSection ${isInViewport ? 'visible' : ''}`}
			ref={detectorRef}
			style={{
				background: theme?.background,
				color: theme?.text
			}}
		>
			<Container size="large">
				<BoutiqueMenu
					filters={filters}
					updateFilters={updateFilters}
					categories={categories}
					articles={articles}
				/>

				<BoutiqueResult
					items={applyProductFilters(articles, categories, filters)}
					type={type}
					loading={loading}
				/>
			</Container>
		</div>
	)
}
