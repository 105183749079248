import { editableElement } from '~components/Edition/Editables/EditableElement/EditableElement'
import { editableText } from '~components/Edition/Editables/EditableText/EditableText'
import { generateLoremIpsum } from '~utils/misc'
import { TTextSection } from './TextSection.types'

const TextSectionDefaultData: TTextSection = {
	data: {
		title: editableElement('Dites-le avec le cœur'),
		text: editableText(generateLoremIpsum('big'))
	},
	params: {
		sectionHeight: 'medium',
		sectionWidth: 'medium',
		alignText: 'center',
		alignVertical: 'center',
		alignHorizontal: 'center',
		designLayout: 'above'
	}
}

export default TextSectionDefaultData
