import React, { Fragment } from 'react'
import ToolBarForEditable from '~components/Edition/Common/ToolBarForEditable/ToolBarForEditable'
import {
	TEditableElementParams,
	TEditableElementParamsTag
} from '../EditableElement.types'

const EditableElementToolbar: TEditableElementToolbar = ({
	params,
	onChangeParams,
	availableTags,
	visible,
	handleRemove
}) => {
	const componentPerTags = {
		h1: (
			<ToolBarForEditable.Button
				active={params?.tag === 'h1'}
				handleClick={() => {
					onChangeParams({
						tag: 'h1'
					})
				}}
			>
				<i className="mdi mdi-format-header-1" />
			</ToolBarForEditable.Button>
		),
		h2: (
			<ToolBarForEditable.Button
				active={params?.tag === 'h2'}
				handleClick={() => {
					onChangeParams({
						tag: 'h2'
					})
				}}
			>
				<i className="mdi mdi-format-header-2" />
			</ToolBarForEditable.Button>
		),
		h3: (
			<ToolBarForEditable.Button
				active={params?.tag === 'h3'}
				handleClick={() => {
					onChangeParams({
						tag: 'h3'
					})
				}}
			>
				<i className="mdi mdi-format-header-3" />
			</ToolBarForEditable.Button>
		),
		h4: (
			<ToolBarForEditable.Button
				active={params?.tag === 'h4'}
				handleClick={() => {
					onChangeParams({
						tag: 'h4'
					})
				}}
			>
				<i className="mdi mdi-format-header-4" />
			</ToolBarForEditable.Button>
		),
		remove: (
			<ToolBarForEditable.Button
				handleClick={() => {
					if (handleRemove) handleRemove()
				}}
			>
				<i className="mdi mdi-trash-can-outline" />
			</ToolBarForEditable.Button>
		)
	}

	return (
		<ToolBarForEditable visible={visible}>
			{availableTags.map((tag) => (
				<Fragment key={tag}>{componentPerTags[tag] || null}</Fragment>
			))}
		</ToolBarForEditable>
	)
}

export default EditableElementToolbar

type TEditableElementToolbar = React.FC<{
	params: TEditableElementParams
	onChangeParams: (params: Partial<Omit<TEditableElementParams, ''>>) => void
	availableTags: TEditableElementParamsTag[]
	visible: boolean
	handleRemove?: () => void
}>
