import React from 'react'

import './SectionEditableParamsButton.scss'

const SectionEditableParamsButton = ({
	children,
	onClick = () => {},
	title = '',
	disabled = false
}) => {
	return (
		<button
			className="SectionEditableParamsButton"
			onClick={onClick}
			title={title}
			disabled={disabled}
		>
			{children}
		</button>
	)
}

export default SectionEditableParamsButton
