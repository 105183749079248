import React, { useState } from 'react'
import AddBasketModal from '~sections/RestaurantSection/AddBasketModal/AddBasketModal'
import './ProductItemClassic.scss'

const ProductItemClassic = ({ product }) => {
	const [modal, setModal] = useState(false)

	return (
		<React.Fragment>
			<div className="ProductItemClassic" onClick={() => setModal(true)}>
				<div className="productItem--image">
					<img src={product.image} />
				</div>
				<span className="productItem__select">
					<i className="mdi mdi-plus"></i>
				</span>
				<div className="productItem--details">
					<p className="productItem__title">{product.title}</p>
					<p className="productItem__price">{product.price}€</p>
					<p className="productItem__desc">{product.description}</p>
				</div>
			</div>
			{modal && (
				<AddBasketModal active={modal} value={product} onChange={setModal} />
			)}
		</React.Fragment>
	)
}

export default ProductItemClassic
