import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import SectionPreviewImage from '~sections/common/SectionPreviewImage'

import { TSectionDefinition } from '~types/Section.types'
import ArticleSection from './ArticleSection'
import ArticleSectionParams from './ArticleSection.params'
import defaultData from './ArticleSection.defaultData'

import { TArticleSection } from './ArticleSection.types'

export const articleSectionName = 'article-section'

const articleSection: TSectionDefinition<TArticleSection> = {
	name: articleSectionName,
	Params: ArticleSectionParams,
	Component: ArticleSection,
	categories: ['article'],
	presets: [
		{
			imagePreview: <PreviewImage name="sectionLibre" />,
			defaultData
		}
	]
}

export default articleSection

export const query = graphql`
	query {
		sectionLibre: file(
			relativePath: { eq: "section-preview/sectionLibre.PNG" }
		) {
			...SectionPreviewImage
		}
	}
`

function PreviewImage({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
