import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SectionPreviewImage from '~sections/common/SectionPreviewImage'

import { TSectionDefinition } from '~types/Section.types'
import ListTitleTextImageButtonSection from './ListDecorationTitleTextImageButtonSection'
import defaultData, {
	aditionalDecorationSectionTitleTextButtonItem
} from './ListDecorationTitleTextImageButtonSection.defaultData'
import ListDecorationTitleTextImageButtonSectionParams from './ListDecorationTitleTextImageButtonSection.params'
import { TListDecorationTitleTextImageButtonSection } from './ListDecorationTitleTextImageButtonSection.types'

const listDecorationTitleTextImageButtonSection: TSectionDefinition<TListDecorationTitleTextImageButtonSection> = {
	name: 'list-Decoration-title-text-image-button-section',
	Component: ListTitleTextImageButtonSection,
	Params: ListDecorationTitleTextImageButtonSectionParams,
	categories: ['list'],
	presets: [
		{
			imagePreview: <ImagePreview name="column" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					designLayout: 'card'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="left" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					alignText: 'left'
				}
			}
		},
		{
			imagePreview: (
				<img src="/images/section-preview/list-decoration-title-text-image-centered.png" />
			),
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params
				}
			}
		},
		{
			imagePreview: (
				<img src="/images/section-preview/list-decoration-title-text-image-button-three-centered.png" />
			),
			defaultData: {
				...defaultData,
				data: {
					...defaultData.data,
					items: [
						...defaultData.data.items,
						aditionalDecorationSectionTitleTextButtonItem
					]
				}
			}
		},
		{
			imagePreview: <ImagePreview name="college" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					designLayout: 'collage'
				}
			}
		}
	]
}

export default listDecorationTitleTextImageButtonSection

export const query = graphql`
	query {
		column: file(
			relativePath: {
				eq: "section-preview/list-decoration-title-text-image-button-center.PNG"
			}
		) {
			...SectionPreviewImage
		}

		left: file(
			relativePath: {
				eq: "section-preview/list-decoration-title-text-image-button.PNG"
			}
		) {
			...SectionPreviewImage
		}

		college: file(
			relativePath: {
				eq: "section-preview/list-decoration-title-text-image-button-college.PNG"
			}
		) {
			...SectionPreviewImage
		}

		double: file(
			relativePath: {
				eq: "section-preview/list-decoration-title-text-image-centered.png"
			}
		) {
			...SectionPreviewImage
		}

		triple: file(
			relativePath: {
				eq: "section-preview/list-decoration-title-text-image-button-three-centered.png"
			}
		) {
			...SectionPreviewImage
		}
	}
`

function ImagePreview({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
