import React from 'react'
import { convertFromRaw, EditorState, CompositeDecorator } from 'draft-js'
import useWebsite from '~hooks/useWebsite'

export const getEditorStateFromRaw = (raw: Draft.RawDraftContentState) => {
	const decorator = new CompositeDecorator([
		{
			strategy: findLinkEntities,
			component: Link
		}
	])

	if (!raw) return EditorState.createEmpty(decorator)

	try {
		return EditorState.createWithContent(
			convertFromRaw({
				entityMap: {},
				// Because graphql will remove entityMap.
				// Without entityMap, draftjs fails.
				...raw
			}),
			decorator
		)
	} catch (err) {
		return EditorState.createEmpty(decorator)
	}
}
function findLinkEntities(contentBlock, callback, contentState) {
	contentBlock.findEntityRanges((character) => {
		const entityKey = character.getEntity()
		return (
			entityKey !== null &&
			contentState.getEntity(entityKey).getType() === 'LINK'
		)
	}, callback)
}

const Link = (props) => {
	const website = useWebsite()
	const { actionType, pageLinkId, link } = props.contentState
		.getEntity(props.entityKey)
		.getData()
	// console.log(`data`, pageLinkId, link, actionType)
	let theLink = ''
	if (actionType === 'internal-link') {
		theLink = website.getPage(pageLinkId)?.slug
	} else if (actionType === 'external-link') {
		theLink =
			link.startsWith('http:') || link.startsWith('//') ? link : '//' + link
	}

	return (
		<a
			href={theLink}
			target="_blank"
			rel="noreferrer"
			style={{
				color: 'inherit',
				textDecoration: 'underline #66a3ff 2px'
			}}
		>
			{props.children}
		</a>
	)
}
