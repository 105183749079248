import React from 'react'

const BoutiqueUserIcon = () => (
	<svg width="8" height="13" viewBox="0 0 8 13" className="icon">
		<path
			d="M3.95222 5.06337C5.35043 5.06337 6.4839 3.92989 6.4839 2.53168C6.4839 1.13347 5.35043 0 3.95222 0C2.55401 0 1.42053 1.13347 1.42053 2.53168C1.42053 3.92989 2.55401 5.06337 3.95222 5.06337Z"
			fill="#172B49"
		/>
		<path
			d="M4.64507 6.06437C3.37271 5.95824 2.26677 6.06559 1.31285 6.85297C-0.283478 8.13161 0.0237288 9.85324 0.0237288 11.9594C0.0237288 12.5166 0.477104 12.9785 1.0428 12.9785C7.18525 12.9785 7.42972 13.1766 7.79396 12.37C7.91341 12.0972 7.88068 12.1839 7.88068 9.57438C7.88068 7.50172 6.086 6.06437 4.64507 6.06437Z"
			fill="#172B49"
		/>
	</svg>
)
export default BoutiqueUserIcon
