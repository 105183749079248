import React from 'react'

const ContactPhoneIcon = ({ color = '#F4F5FD', bgColor = '#172B49' }) => (
	<svg width="30" height="30" viewBox="0 0 30 30" fill="none" className="icon">
		<circle cx="15" cy="15" r="15" fill={bgColor} />
		<path
			d="M18.9864 20.4944L18.0919 18.0277C17.9131 17.5377 17.3544 17.2895 16.8463 17.4752L15.6048 17.9285C15.5651 17.8741 15.5241 17.8174 15.4812 17.7576C15.0852 17.2116 14.5435 16.4639 14.1339 15.3331C13.7227 14.1996 13.6588 13.2761 13.6118 12.6017C13.6069 12.5303 13.6024 12.4616 13.597 12.3957L14.4299 12.0922L14.8398 11.9422C15.3486 11.7563 15.617 11.2057 15.4394 10.7151L14.545 8.24813C14.3672 7.75804 13.8082 7.50974 13.2994 7.69566L12.6035 7.95209L12.6105 7.97099C12.3571 8.09997 12.1153 8.27224 11.8988 8.47868C11.6958 8.67657 11.5322 8.87948 11.3991 9.0972C10.3181 10.8365 10.3729 12.9125 11.5874 16.261C13.2663 20.8896 15.9175 21.8353 16.0326 21.8748C16.2845 21.9575 16.5399 22.007 16.8145 22.0249C17.1098 22.0436 17.4052 22.0197 17.6816 21.9553L17.6876 21.9693L18.3867 21.7223C18.8947 21.5361 19.1636 20.9855 18.9864 20.4944Z"
			fill={color}
		/>
	</svg>
)
export default ContactPhoneIcon
