import * as React from 'react'
import './CheckBox.scss'

type TCheckBox = React.DetailedHTMLProps<
	React.InputHTMLAttributes<HTMLInputElement>,
	HTMLInputElement
> & {
	label?: string
}
const CheckBox: React.FC<TCheckBox> = ({ label, ...props }) => {
	return (
		<label className="CheckBox">
			<input {...props} type="checkbox" />
			{label && <>&nbsp; {label}</>}
		</label>
	)
}

export default CheckBox
