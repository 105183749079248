import React from 'react'
import Container from '~components/Common/Container/Container'

import EditableElement from '~components/Edition/Editables/EditableElement/EditableElement'

import { TCitationTextTextSectionComponent } from './CitationTextTextSection.types'

import EditableText from '~components/Edition/Editables/EditableText/EditableText'

import './CitationTextTextSection.scss'
import { generateClassNames } from '~utils/misc'
import EditableImage from '~components/Edition/Editables/EditableImage/EditableImage'

const CitationTextTextSection: TCitationTextTextSectionComponent = ({
	section,
	theme
}) => {
	const {
		sectionHeight,
		sectionWidth,
		alignText,
		alignVertical,
		alignHorizontal,
		designLayout,
		gridItems
	} = section.params

	const items = section.data.items.map((item, index) => {
		return (
			<div className="list-item-content" key={index}>
				{item.avatar && (
					<div className="avatar-round">
						<EditableImage field={`items.${index}.avatar`} />
					</div>
				)}
				<div className="item-content-text">
					<EditableText field={`items.${index}.title`} />
					<EditableText field={`items.${index}.text`} />
				</div>
			</div>
		)
	})

	return (
		<div
			className={`Section CitationTextTextSection  ${generateClassNames({
				designLayout,
				alignText,
				sectionHeight,
				sectionWidth,
				alignVertical,
				alignHorizontal,
				gridItems
			})}`}
			style={{
				background: theme?.background,
				color: theme?.text
			}}
		>
			<Container>
				<div className="content-wrapper">
					<div className="__content">
						<div className="title-section">
							<EditableElement field="title" tag="h2" />
						</div>
						<div className="list-content">{items}</div>
					</div>
				</div>
			</Container>
		</div>
	)
}

export default CitationTextTextSection
