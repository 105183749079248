import React from 'react'
import ParamAlignVertical from '~components/Edition/ParamsPresets/ParamAlignVertical/ParamAlignVertical'
import ParamHauteur from '~components/Edition/ParamsPresets/ParamHauteur/ParamHauteur'
import ParamLargeur from '~components/Edition/ParamsPresets/ParamLargeur/ParamLargeur'

import Params from '~components/Edition/ParamsPresets/Params/Params'
import { THeaderSectionParamsComponent } from './HeaderSection.types'

const HeaderSectionParams: THeaderSectionParamsComponent = ({
	params,
	onChange
}) => {
	return (
		<Params className="HeaderSectionParams">
			<ParamHauteur
				label="Hauteur de section"
				onChange={(sectionHeight) => {
					onChange({
						...params,
						sectionHeight
					})
				}}
				value={params.sectionHeight}
				options={[
					{
						label: 'S',
						value: 'small'
					},
					{
						label: 'M',
						value: 'medium'
					},
					{
						label: 'L',
						value: 'large'
					}
				]}
			/>
			<ParamLargeur
				label="Largeur de section"
				onChange={(sectionWidth) => {
					onChange({
						...params,
						sectionWidth
					})
				}}
				value={params.sectionWidth}
				options={[
					{
						label: 'S',
						value: 'small'
					},
					{
						label: 'M',
						value: 'medium'
					},
					{
						label: 'L',
						value: 'large'
					}
				]}
			/>

			<ParamAlignVertical
				label="Alignement du contenu"
				onChange={(alignVertical) => {
					onChange({
						...params,
						alignVertical
					})
				}}
				value={params.alignVertical}
				options={[
					{
						label: 'mdi-arrow-collapse-up',
						value: 'top'
					},
					{
						label: 'mdi-arrow-collapse-vertical',
						value: 'center'
					},
					{
						label: 'mdi-arrow-collapse-down',
						value: 'bottom'
					}
				]}
			/>
		</Params>
	)
}

export default HeaderSectionParams
