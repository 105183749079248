import { editableButton } from '~components/Edition/Editables/EditableButton/EditableButton'
import { editableElement } from '~components/Edition/Editables/EditableElement/EditableElement'
import { editableImage } from '~components/Edition/Editables/EditableImage/EditableImage'
import { editableText } from '~components/Edition/Editables/EditableText/EditableText'
import { generateLoremIpsum } from '~utils/misc'
import { TListDecorationTitleTextImageButtonSection } from './ListDecorationTitleTextImageButtonSection.types'
import { v4 as uuidv4 } from 'uuid'

const ListDecorationTitleTextImageButtonSectionDefaultData: TListDecorationTitleTextImageButtonSection = {
	data: {
		title: editableElement('Dites-le avec le cœur'),
		items: [
			{
				id: uuidv4(),
				image: editableImage(
					'https://images.unsplash.com/photo-1593642634315-48f5414c3ad9?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
				),
				title: editableElement('Inspirez'),
				text: editableText(generateLoremIpsum('big')),
				button: editableButton('Bouton')
			},
			{
				id: uuidv4(),
				image: editableImage(
					'https://images.unsplash.com/photo-1593642532842-98d0fd5ebc1a?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
				),
				title: editableElement('Inspirez'),
				text: editableText(generateLoremIpsum('big')),
				button: editableButton('Bouton')
			}
		]
	},
	params: {
		sectionHeight: 'medium',
		sectionWidth: 'large',
		alignText: 'center',
		alignHorizontal: 'center',
		alignVertical: 'center',
		designLayout: 'row'
	}
}

export const aditionalDecorationSectionTitleTextButtonItem = {
	id: uuidv4(),
	image: editableImage(
		'https://images.unsplash.com/photo-1593642532454-e138e28a63f4?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80'
	),
	title: editableElement('Inspirez'),
	text: editableText(generateLoremIpsum('big')),
	button: editableButton('Bouton')
}

export default ListDecorationTitleTextImageButtonSectionDefaultData
