import { useEffect } from 'react'

export type THandleReceivedMessageData = {
	type: string
	from?: string
	payload?: any
}

export type THandleReceivedMessage = (data: THandleReceivedMessageData) => void

const useMessageListener = (handleReceivedMessage: THandleReceivedMessage) => {
	useEffect(() => {
		window.addEventListener('message', (e) => {
			if (e.origin === location.origin) {
				handleReceivedMessage(e.data)
			}
		})
	}, [])
}

export default useMessageListener
