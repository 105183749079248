import React from 'react'

const ContactMailIcon = ({ color = '#F4F5FD', bgColor = '#172B49' }) => (
	<svg width="30" height="30" viewBox="0 0 30 30" fill="none" className="icon">
		<circle cx="15" cy="15" r="15" fill={bgColor} />
		<path
			d="M9.5611 12.9989C9.73364 13.1179 10.2538 13.4713 11.1215 14.0589C11.9892 14.6466 12.6539 15.099 13.1157 15.4164C13.1665 15.4511 13.2742 15.5267 13.4391 15.6433C13.6041 15.7599 13.7411 15.8542 13.8501 15.926C13.9592 15.9979 14.0912 16.0785 14.246 16.1677C14.4008 16.2569 14.5467 16.324 14.6837 16.3684C14.8207 16.4131 14.9476 16.4353 15.0643 16.4353H15.0719H15.0796C15.1963 16.4353 15.3232 16.4131 15.4602 16.3684C15.5972 16.324 15.7432 16.2568 15.8979 16.1677C16.0526 16.0784 16.1845 15.9979 16.2937 15.926C16.4028 15.8542 16.5397 15.7599 16.7047 15.6433C16.8695 15.5266 16.9775 15.4511 17.0282 15.4164C17.495 15.099 18.6825 14.2931 20.5904 12.9987C20.9608 12.7459 21.2703 12.4409 21.5189 12.0838C21.7677 11.7269 21.8919 11.3525 21.8919 10.9607C21.8919 10.6334 21.7714 10.3532 21.5303 10.1201C21.2893 9.887 21.0038 9.77051 20.6741 9.77051H9.46973C9.07903 9.77051 8.77835 9.89943 8.56777 10.1573C8.3572 10.4152 8.25192 10.7375 8.25192 11.1243C8.25192 11.4367 8.39152 11.7753 8.67057 12.1397C8.94959 12.5042 9.24653 12.7907 9.5611 12.9989Z"
			fill={color}
		/>
		<path
			d="M21.1291 13.7951C19.4648 14.8959 18.2011 15.7515 17.3386 16.3615C17.0494 16.5697 16.8147 16.7322 16.6345 16.8487C16.4543 16.9652 16.2147 17.0843 15.9152 17.2057C15.6159 17.3273 15.3369 17.3879 15.0781 17.3879H15.0703H15.0627C14.8039 17.3879 14.5247 17.3273 14.2254 17.2057C13.9261 17.0843 13.6863 16.9652 13.5061 16.8487C13.326 16.7322 13.0913 16.5697 12.8021 16.3615C12.117 15.8706 10.856 15.015 9.01913 13.7951C8.72981 13.6068 8.47355 13.3909 8.25027 13.1479V19.0541C8.25027 19.3816 8.36947 19.6617 8.608 19.8948C8.84648 20.1279 9.13323 20.2445 9.46816 20.2445H20.6725C21.0074 20.2445 21.2941 20.1279 21.5326 19.8948C21.7712 19.6616 21.8903 19.3817 21.8903 19.0541V13.1479C21.6721 13.3859 21.4184 13.6017 21.1291 13.7951Z"
			fill={color}
		/>
	</svg>
)
export default ContactMailIcon
