import React from 'react'

import './ParamLargeur.scss'

const ParamLargeur = ({ options, label, value, onChange }) => {
	return (
		<section className="ParamLargeur">
			<div className="label">{label}</div>

			<div className="options">
				{options.map((option) => (
					<button
						key={option.value}
						className={value === option.value ? 'active' : ''}
						onClick={() => {
							onChange(option.value)
						}}
					>
						{option.label}
					</button>
				))}
			</div>
		</section>
	)
}

export default ParamLargeur
