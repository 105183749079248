import React from 'react'

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError() {
		return { hasError: true }
	}

	// componentDidCatch(error, errorInfo) {
	//   logErrorToMyService(error, errorInfo);
	// }

	render() {
		//   if (this.state.hasError) {
		// 	// Vous pouvez afficher n'importe quelle UI de repli.
		// 	return <h1>Something went wrong.</h1>;
		//   }

		return this.props.children
	}
}
