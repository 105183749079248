import React, { useEffect, useState } from 'react'

import { TModalComponent } from '~Common/Modal/Modal'

import { TEditableButton } from '../EditableButton.types'
import { useGlobalContext } from '~contexts/globalContext'
import ToggleButton from '~components/Common/Basics/ToggleButton/ToggleButton'
import EditableParamsModal from '~components/Edition/Common/EditableParamsModal/EditableParamsModal'
import Button from '~components/Common/Button/Button'

const EditableButtonParamsModal: TModalComponent<{
	onChange: (data: TEditableButton) => void
	handleRemove?: () => void
}> = ({ payload, onChange, handleRemove, ...modalProps }) => {
	const [data, setData] = useState<TEditableButton>(payload)
	const { website } = useGlobalContext()

	useEffect(() => {
		if (modalProps.isOpen) {
			setData(payload)
		} else {
			setData(null)
		}
	}, [modalProps.isOpen])

	const handleConfirm = () => {
		onChange(data)
	}

	const pagesOptions = website.pages
		.filter((x) => x.enabled)
		.map((page) => ({
			name: `/${page.slug}`,
			value: page.id
		}))

	if (!data) return null

	return (
		<EditableParamsModal
			handleConfirm={handleConfirm}
			payload={payload}
			className="EditableButtonParamsModal"
			{...modalProps}
		>
			{!handleRemove && (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						marginBottom: '35px'
					}}
				>
					<ToggleButton
						value={data.params.visible}
						onChange={(visible) => {
							setData({
								...data,
								params: {
									...data.params,
									visible
								}
							})
						}}
					/>
					<span
						style={{
							marginLeft: 8
						}}
					>
						Afficher un bouton
					</span>
				</div>
			)}
			{(data.params.visible || handleRemove) && (
				<>
					<EditableParamsModal.Field
						type="input"
						label="Texte du bouton"
						value={data.text}
						onChange={(text) => {
							setData({
								...data,
								text
							})
						}}
					/>
					<EditableParamsModal.Field
						type="select"
						label="Redirection du bouton"
						selectOptions={[
							{
								name: 'Lien interne',
								value: 'internal-link'
							},
							{
								name: 'URL',
								value: 'external-link'
							}
						]}
						value={data.params.actionType}
						onChange={(actionType) => {
							setData({
								...data,
								params: {
									...data.params,
									actionType
								}
							})
						}}
					/>
					{data.params.actionType === 'internal-link' && (
						<EditableParamsModal.Field
							type="select"
							label="Choisissez un lien"
							selectOptions={pagesOptions}
							value={data.params.pageLinkId}
							onChange={(pageLinkId) => {
								setData({
									...data,
									params: {
										...data.params,
										pageLinkId
									}
								})
							}}
						/>
					)}
					{data.params.actionType === 'external-link' && (
						<EditableParamsModal.Field
							type="input"
							label="URL de redirection"
							value={data.params.link}
							onChange={(link) => {
								setData({
									...data,
									params: {
										...data.params,
										link
									}
								})
							}}
						/>
					)}
				</>
			)}

			{handleRemove && (
				<Button
					style={{
						width: '100%'
					}}
					textAlign="center"
					theme="error"
					onClick={() => {
						handleRemove()
					}}
				>
					Supprimer
				</Button>
			)}
		</EditableParamsModal>
	)
}

export default EditableButtonParamsModal
