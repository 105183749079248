import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import useClickOutside from '~hooks/useClickOutside'
import { TPosition } from '~types/common.types'
import { TArticleSectionBlockType } from '../ArticleSectionBlock'

import './ArticleSectionBlockCreationPopup.scss'

const ArticleSectionBlockCreationPopup: TArticleSectionBlockCreationPopup = ({
	handleSelect,
	isVisible,
	handleClose,
	position
}) => {
	const rootRef = useRef<HTMLDivElement>()

	useEffect(() => {
		if (position) {
			rootRef.current.style.top = position?.y + 'px'
			rootRef.current.style.left = position?.x + 'px'
		}
	}, [position])

	useClickOutside(isVisible, handleClose, rootRef)
	return ReactDOM.createPortal(
		<div
			ref={rootRef}
			className={`ArticleSectionBlockCreationPopup ${
				isVisible ? 'visible' : ''
			}`}
		>
			<header>
				<u>Ajouter un élément</u> :
			</header>

			<BlockTypes
				handleClick={(type: TArticleSectionBlockType) => {
					handleSelect(type)
				}}
			/>
		</div>,
		document.getElementById('modal-root')
	)
}

export default ArticleSectionBlockCreationPopup

const BlockTypes = ({ handleClick }) => (
	<div className="BlockTypes">
		<button
			onClick={() => {
				handleClick('editable-element')
			}}
		>
			<i className="icon mdi mdi-format-title" />
			<span>Titre</span>
		</button>

		<button
			onClick={() => {
				handleClick('editable-text')
			}}
		>
			<i className="icon mdi mdi-format-textbox" />
			<span>Text</span>
		</button>

		<button
			onClick={() => {
				handleClick('editable-image')
			}}
		>
			<i className="icon mdi mdi-image" />
			<span>Image</span>
		</button>

		<button
			onClick={() => {
				handleClick('editable-button')
			}}
		>
			<i className="icon mdi mdi-rectangle-outline" />
			<span>Bouton</span>
		</button>

		<button
			onClick={() => {
				handleClick('editable-separator')
			}}
		>
			<span
				className="icon"
				style={{
					transform: 'rotate(90deg)'
				}}
			>
				<i
					className="mdi mdi-power-on"
					style={{
						marginBottom: -6,
						marginLeft: 3
					}}
				/>
			</span>
			<span>Trait</span>
		</button>

		<button
			onClick={() => {
				handleClick('editable-vertical-spacing')
			}}
		>
			<i className="icon mdi mdi-arrow-expand-vertical" />
			<span>Espacement</span>
		</button>
	</div>
)

type TArticleSectionBlockCreationPopup = React.FC<{
	handleSelect: (type: TArticleSectionBlockType) => void
	isVisible: boolean
	payload?: {
		position: 'before' | 'after'
	}
	handleClose: () => void
	position: TPosition
}>
