import { editableButton } from '~components/Edition/Editables/EditableButton/EditableButton'
import { editableElement } from '~components/Edition/Editables/EditableElement/EditableElement'
import { editableImage } from '~components/Edition/Editables/EditableImage/EditableImage'
import { editableText } from '~components/Edition/Editables/EditableText/EditableText'
import { generateLoremIpsum } from '~utils/misc'
import { TTitleTextImageButtonBasicSection } from './TitleTextImageButtonBasicSection.types'

const TitleTextImageButtonBasicSectionDefaultData: TTitleTextImageButtonBasicSection = {
	data: {
		title: editableElement('Dites-le avec le cœur'),
		text: editableText(generateLoremIpsum('normal')),
		button: editableButton('Bouton'),
		image: editableImage(
			'https://res.cloudinary.com/duopq85lp/image/upload/v1617901875/annie-spratt-TywjkDHf0Ps-unsplash_duxran.png'
		)
	},
	params: {
		sectionHeight: 'medium',
		sectionWidth: 'small',
		alignText: 'center',
		alignHorizontal: 'center',
		alignVertical: 'center'
	}
}

export default TitleTextImageButtonBasicSectionDefaultData
