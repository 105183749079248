// Precache page to make page loading faster.
// export const usePagePrecache = (pageId: string) => {
// 	//
// }

// Get page cache in localstorage.
export const getCachedPage = (pageId: string) => {
	try {
		const data = localStorage.getItem(pageId)

		if (!data) return null

		return JSON.parse(data)
	} catch {
		return null
	}
}

//
export const saveCachePage = (pageId: string, data: any) => {
	try {
		localStorage.setItem(pageId, JSON.stringify(data))
	} catch {
		//
	}
}
