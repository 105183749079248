import moment from 'moment'

// We set year with current year, but may be we need year in source data ?
export const parseStringDate = (string) => {
	if (string === null) return null

	const [date, month] = string.split('/')
	return new Date(`${month}/${date}/${new Date().getFullYear()}`)
}

/**
 *
 */
export const flexibleDateParse = (s) => {
	if (new Date(s) instanceof Date && !isNaN(new Date(s).valueOf())) {
		return moment(new Date(s))
	}

	if (typeof s === 'string') {
		if (!s.match(/[^0-9]/)) {
			return moment(new Date(parseInt(s, 10)))
		} else if (moment.isDate(s)) {
			return moment(s)
		}
	}

	return moment()
}

/**
 *
 * @param {number} date
 * @param {{
 *   startDate: number
 *   endDate: number
 * }} range
 */
export const dateInPeriod = (date, { startDate, endDate }) => {
	const _startDate =
		typeof startDate === 'number' ? startDate : parseStringDate(startDate)
	const _endDate =
		typeof startDate === 'number' ? endDate : parseStringDate(endDate)

	if (!_startDate || !_startDate) return false

	return date >= _startDate && date <= _endDate
}

/**
 *
 * @param {number} date
 * @param {[{
 *   startDate: number
 *   endDate: number
 * }]} periods
 */
export const dateInPeriods = (date, periods) => {
	for (let i = 0; i < periods.length; i++) {
		if (dateInPeriod(date, periods[i])) return true
	}
	return false
}

export const activityIsAvailableAtRange = (schedules, [from, to]) => {
	// Round is supposed to be useless. But in case of..
	const n_days = Math.round(
		(new Date(to).getTime() - new Date(from).getTime()) / (1000 * 3600 * 24)
	)

	const table = [...new Array(n_days + 1)].map((_, i) => {
		const date = new Date(from)
		date.setDate(date.getDate() + i)
		return {
			available: true,
			date: date.getTime()
		}
	})

	// First check if some dates are in closePeriods
	table.forEach((scheduleDay) => {
		if (dateInPeriods(scheduleDay.date, schedules.closedPeriods)) {
			scheduleDay.available = false
		}
	})

	if (!table.map((a) => a.available).includes(true)) return false

	// Then check if some dates are in specific periods of in main schedule.

	for (let i = 0; i < table.length; i++) {
		const scheduleDay = table[i]

		if (scheduleDay.available) {
			let isInSpecitivSchedule = false

			for (let j = 0; j < schedules.schedulesTable.length; j++) {
				const specificSchedule = schedules.schedulesTable[j]

				if (dateInPeriod(scheduleDay.date, specificSchedule)) {
					isInSpecitivSchedule = true

					const dayInWeek = new Date(scheduleDay.date).getDay()
					if (!specificSchedule.days_bool[dayInWeek]) {
						scheduleDay.available = false
					}
				}
			}

			if (!isInSpecitivSchedule) {
				if (schedules.mainSchedule === null) return false

				const dayInWeek = new Date(scheduleDay.date).getDay()
				if (!schedules.mainSchedule[dayInWeek]) {
					scheduleDay.available = false
				}
			}
		}
	}

	return table.map((a) => a.available).includes(true)
}

export const formatDate = (date) =>
	`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
