import { editableElement } from '~components/Edition/Editables/EditableElement/EditableElement'
import { editableImage } from '~components/Edition/Editables/EditableImage/EditableImage'
import { editableText } from '~components/Edition/Editables/EditableText/EditableText'
import { generateLoremIpsum } from '~utils/misc'
import { THeaderSection } from './HeaderSection.types'

// console.log('editableElement',{editableElement,editableImage,editableText,generateLoremIpsum})

const HeaderSectionDefaultData: THeaderSection = {
	data: {
		title: editableElement('Bienvenue sur mon site !', 'h1'),
		subtitle: editableText(generateLoremIpsum('normal')),
		sections: [
			{
				title: editableElement('lorem ipsum', 'h3'),
				image: editableImage('/images/salon1.jpg'),
				link: null
			},
			{
				title: editableElement('lorem ipsum', 'h3'),
				image: editableImage('/images/salon2.jpg'),
				link: null
			}
		]
	},
	params: {
		sectionHeight: 'small',
		sectionWidth: 'medium',
		alignVertical: 'center'
	}
}

export default HeaderSectionDefaultData
