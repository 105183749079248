import React, { useRef } from 'react'
import { useAuthContext } from '~contexts/authContext/authContext'
import { usePageContext } from '~contexts/page/pageContext'
import { postPicture } from '~queries/images.queries'

import './SectionEditableParamsImage.scss'

const SectionEditableParamsImage = ({ value, onChange, setIsLoading }) => {
	const imageRef = useRef<HTMLImageElement>(null)

	return (
		<section className="SectionEditableParamsImage">
			<div className="Image">
				<div className="previewImage">
					<img src={value} ref={imageRef} />
				</div>
				<ImageButton
					setIsLoading={setIsLoading}
					onSelectImage={(url) => {
						onChange(url)
					}}
					onBlobReady={(rawImage) => {
						imageRef.current.src = rawImage
					}}
				/>
			</div>
		</section>
	)
}

export default SectionEditableParamsImage

const ImageButton = ({ onSelectImage, onBlobReady, setIsLoading }) => {
	const fileInputRef = useRef<HTMLInputElement>()
	const { token } = useAuthContext()
	const pageContext = usePageContext()

	const changeImage = async (file: File) => {
		setIsLoading(true)
		pageContext.setIsLoading(true)
		onBlobReady(URL.createObjectURL(file))
		const result = await postPicture(file, token)
		onSelectImage(result.url)
		setIsLoading(false)
		pageContext.setIsLoading(false)
	}

	return (
		<div className="ImageButton">
			<input
				type="file"
				ref={fileInputRef}
				onChange={(e) => {
					// if (!globalContext.auth.isConnected) return
					const files = Array.from(e.target.files)

					if (files.length === 0) return

					changeImage(files[0])
				}}
			/>
			<button
				onClick={() => {
					fileInputRef.current.click()
				}}
			>
				Modifier l'image
			</button>
		</div>
	)
}
