import React, { useEffect, useState } from 'react'

import Params from '~components/Edition/ParamsPresets/Params/Params'
import MarketCategoryItems from './components/CategoryFilterItems/CategoryFilterItems'

import {
	TBoutiqueSectionParams,
	TBoutiqueSectionParamsComponent
} from './BoutiqueSection.types'
import {
	getBoutiqueCategories,
	getBoutiqueItems
} from '~queries/boutique.queries'
import {
	categoryFormat,
	margeCategories,
	formatMarketItems
} from './BoutiqueSection.utils'
import { useDataQuery } from '~utils/useDataQuery'
import useWebsite from '~hooks/useWebsite'
import { getPictureURL } from '~utils/cloudinary'

const BoutiqueSectionParams: TBoutiqueSectionParamsComponent = ({
	params,
	onChange
}) => {
	const [sectionParams, setSectionParams] = useState<TBoutiqueSectionParams>({
		categories: params?.categories || [],
		marketItems: params?.marketItems || [],
		type: params.type
	})
	const { activitiesId } = useWebsite()
	const [loaded, setLoaded] = useState(false)
	const [articles, articlesLoading] = useDataQuery(
		async () => getBoutiqueItems(activitiesId),
		'boutique-items',
		[]
	)
	const [boutiqueCats, categoriesLoading] = useDataQuery(
		async () => getBoutiqueCategories(),
		'categoy-items',
		[],
		0
	)

	const getCategories = () => {
		const resCats = [...boutiqueCats]
		const marketItems = formatMarketItems(
			resCats,
			articles,
			params.marketItems,
			params.type
		)
		let categories = categoryFormat(
			boutiqueCats,
			articles.filter((x) => x.type === params.type)
		)

		if (params.categories) {
			categories = margeCategories(categories, params.categories)
		}

		const newParams = {
			...sectionParams,
			categories,
			marketItems
		}

		setSectionParams(newParams)
		if (params.categories?.length === 0 && params.marketItems?.length === 0) {
			onChange(newParams)
		}
		setLoaded(true)
	}

	useEffect(() => {
		if (!loaded && !articlesLoading && !categoriesLoading) {
			getCategories()
		}
		// onChange({ marketItems: [], categories: [], type: 'product'})
	}, [categoriesLoading, articlesLoading])

	useEffect(() => {
		setSectionParams(params)
	}, [params.categories])

	const { categories } = sectionParams
	return (
		<Params className="BoutiqueSectionParams">
			<span className="title">
				{categories?.length > 0 ? (
					<>Sélectionnez les offres affichées sur cette page :</>
				) : (
					<>
						Sélectionnez les offres affichées sur cette page (attribuez des
						catégories à vos offres pour faciliter cela) :
					</>
				)}
			</span>
			<div className="category_selector">
				{categories?.length > 0 && (
					<MarketCategoryItems
						sectionType={params.type}
						items={sectionParams.categories}
						onChange={(data) => {
							onChange({
								...sectionParams,
								categories: data
							})
						}}
					/>
				)}
				{sectionParams?.marketItems?.map((item) => {
					return (
						<MarketSelectItem
							key={item.id}
							item={item}
							onSelect={() => {
								item.selected = !item.selected
								onChange({
									...sectionParams,
									marketItems: sectionParams.marketItems
								})
							}}
						/>
					)
				})}
			</div>
		</Params>
	)
}

export default BoutiqueSectionParams

const MarketSelectItem = ({ item, onSelect }) => {
	const imageURL = getPictureURL(item.miniature, {
		w: 40,
		h: 40,
		c: 'fill'
	})
	return (
		<div className="MarketSelectItem" onClick={onSelect}>
			<span className={'selector' + (!item.selected ? ' inactive' : '')}>
				<i className="fa fa-check"></i>
			</span>
			<div className="drop">
				<span className="label">{item.title}</span>
				<span className={'arrow' + (open ? ' opened' : '')}>
					<img src={imageURL} alt="" />
				</span>
			</div>
		</div>
	)
}
