import React, { useRef, useState } from 'react'

import SectionEditableParamsButton from './SectionEditableParamsButton/SectionEditableParamsButton'

import {
	SectionEditableParamsConfigLeft,
	SectionEditableParamsConfigRight
} from './SectionEditableParamsConfig/SectionEditableParamsConfig'
import useClickOutside from '~hooks/useClickOutside'

import './SectionEditableParams.scss'

const SectionEditableParams = ({
	handleMoveSectionUp,
	handleMoveSectionDown,
	canMoveUp,
	canMoveDown,
	children,
	handleChangeTheme,
	params,
	onChangeParams,
	currentTheme
}) => {
	const [configRightIsOpen, setConfigRightIsOpen] = useState(false)
	const [configLeftIsOpen, setConfigLeftIsOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const rootRef = useRef(null)
	useClickOutside(configRightIsOpen, setConfigRightIsOpen, rootRef)
	useClickOutside(configLeftIsOpen, setConfigLeftIsOpen, rootRef)

	return (
		<div
			style={{
				position: 'absolute',
				inset: 0,
				pointerEvents: 'none',
				zIndex: 400
			}}
		>
			<div
				className={`SectionEditableParams ${
					configRightIsOpen || configLeftIsOpen ? 'active' : ''
				}`}
				ref={rootRef}
			>
				{params.backgroundImage && (
					<>
						<div className="buttons-left">
							{isLoading ? (
								<span className="loading-spin">
									<i className="mdi mdi-spin mdi-loading" />
								</span>
							) : (
								<SectionEditableParamsButton
									title="Image la section"
									onClick={() => {
										setConfigLeftIsOpen(!configLeftIsOpen)
										setConfigRightIsOpen(false)
									}}
								>
									<i className="mdi mdi-image"></i>
								</SectionEditableParamsButton>
							)}
						</div>
						<SectionEditableParamsConfigLeft
							isOpen={configLeftIsOpen}
							setIsLoading={setIsLoading}
							imageUrl={params.backgroundImage}
							onChange={(backgroundImage) => {
								onChangeParams({
									...params,
									backgroundImage
								})
							}}
						/>
					</>
				)}
				<div className="buttons-right">
					<SectionEditableParamsButton
						title="Monter la section"
						onClick={handleMoveSectionUp}
						disabled={!canMoveUp}
					>
						<i className="fas fa-chevron-up" />
					</SectionEditableParamsButton>
					<SectionEditableParamsButton
						title="Descendre la section"
						onClick={handleMoveSectionDown}
						disabled={!canMoveDown}
					>
						<i className="fas fa-chevron-down" />
					</SectionEditableParamsButton>
					<SectionEditableParamsButton
						title="Paramètres de la section"
						onClick={() => {
							setConfigRightIsOpen(!configRightIsOpen)
							setConfigLeftIsOpen(false)
						}}
					>
						<i className="fas fa-cog" />
					</SectionEditableParamsButton>
				</div>

				<SectionEditableParamsConfigRight
					isOpen={configRightIsOpen}
					handleChangeTheme={handleChangeTheme}
					currentTheme={currentTheme}
				>
					{children}
				</SectionEditableParamsConfigRight>
			</div>
		</div>
	)
}

export default SectionEditableParams
