import React from 'react'
import ErrorBoundary from '~components/Common/ErrorBoundary/ErrorBoundary'
import { usePageContext } from '~contexts/page/pageContext'
import { TArticleSectionBlock } from '../ArticleSection.types'

import {
	getBlockEditableComponent,
	getBlockEditableComponentProps
} from './ArticleSectionBlock.utils'

import { TSectionTheme } from '~types/Website.types'

import './ArticleSectionBlock.scss'

const ArticleSectionBlock: TArticleSectionBlockComponent = ({
	block,
	index,
	handleRemove,
	theme
}) => {
	const pageContext = usePageContext()
	const Editable = getBlockEditableComponent(block)
	const editableProps = getBlockEditableComponentProps(block, theme)

	return (
		<div
			className={`ArticleSectionBlock ${pageContext.editMode ? 'edition' : ''}`}
		>
			<Editable
				field={`blocks.${index}.editable`}
				{...editableProps}
				handleRemove={handleRemove}
			/>
		</div>
	)
}

const ArticleSectionBlockContainer: TArticleSectionBlockComponent = (props) => (
	<ErrorBoundary>
		<ArticleSectionBlock {...props} />
	</ErrorBoundary>
)

export default ArticleSectionBlockContainer

type TArticleSectionBlockComponent = React.FC<{
	block: TArticleSectionBlock
	index: number
	handleRemove: () => void
	theme: TSectionTheme
}>

export type TArticleSectionBlockType =
	| 'editable-element'
	| 'editable-text'
	| 'editable-image'
	| 'editable-separator'
	| 'editable-button'
