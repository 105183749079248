import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SectionPreviewImage from '~sections/common/SectionPreviewImage'

import { TSectionDefinition } from '~types/Section.types'
import ContactSection from './ContactSection'
import ContactSectionParams from './ContactSection.params'
import defaultData from './ContactSection.defaultData'
import { TContactSection } from './ContactSection.types'

export const contactSectionName = 'contact-section'

const contactSection: TSectionDefinition<TContactSection> = {
	name: contactSectionName,
	Component: ContactSection,
	Params: ContactSectionParams,
	categories: ['contact'],
	presets: [
		{
			imagePreview: <ImagePreview name="aside" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					display: 'aside'
				}
			}
		},
		{
			imagePreview: <ImagePreview name="above" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					display: 'above'
				}
			}
		}
	]
}

export default contactSection

export const query = graphql`
	query {
		aside: file(relativePath: { eq: "section-preview/contact.PNG" }) {
			...SectionPreviewImage
		}
		above: file(relativePath: { eq: "section-preview/contact_above.PNG" }) {
			...SectionPreviewImage
		}
	}
`

function ImagePreview({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
