import * as React from 'react'
import useClickOutside from '~hooks/useClickOutside'
import { extractGendersOptions, extractWhoOptions } from '../BoutiqueMenu.utils'
import './BoutiqueMenuFilter.scss'

export const BoutiqueMenuFilter = ({ label, icon, onChange, articles }) => {
	const [isOpen, setIsOpen] = React.useState(false)
	const buttonRef = React.useRef(null)
	useClickOutside(isOpen, setIsOpen, buttonRef)

	const [values, setValues] = React.useState<{
		who: string[]
		gender: string[]
	}>({
		who: [],
		gender: []
	})

	const lengths = values.gender.length + values.who.length
	const genderOptions = extractGendersOptions(articles)
	const whoOptions = extractWhoOptions(articles)
	if (genderOptions.length + whoOptions.length === 0) return null

	return (
		<div className="BoutiqueMenuFilter" ref={buttonRef}>
			<button className="BoutiqueMenuFilter" onClick={() => setIsOpen(true)}>
				<span className="label">
					{label} {lengths > 0 && 'x' + lengths}
				</span>
				<span className="icon-container">{icon}</span>
			</button>
			<div className={'dropdown ' + (isOpen ? ' show' : '')}>
				<div className="genders">
					{genderOptions.length > 0 && <h4>Genre</h4>}
					{genderOptions.map((opt, i) => {
						const selected = values.gender.includes(opt.value)
						return (
							<div
								key={i}
								className={'filter_option' + (selected ? ' selected' : '')}
								onClick={() => {
									let { gender } = values
									if (gender.includes(opt.value)) {
										gender = gender.filter((x) => x !== opt.value)
									} else gender.push(opt.value)
									setValues({ ...values, gender })
									onChange({ ...values, gender })
								}}
							>
								<span>
									<i className="mdi mdi-check-bold"></i>
								</span>
								<option value={opt.value}>{opt.name}</option>
							</div>
						)
					})}
				</div>
				{whoOptions.length > 0 && <h4>Âge</h4>}
				{whoOptions.map((opt, i) => {
					const selected = values.who.includes(opt.value)
					return (
						<div
							key={i}
							className={'filter_option' + (selected ? ' selected' : '')}
							onClick={() => {
								let { who } = values
								if (who.includes(opt.value)) {
									who = who.filter((x) => x !== opt.value)
								} else who.push(opt.value)
								setValues({ ...values, who })
								onChange({ ...values, who })
							}}
						>
							<span>
								<i className="mdi mdi-check-bold"></i>
							</span>
							<option value={opt.value}>{opt.name}</option>
						</div>
					)
				})}
			</div>
		</div>
	)
}
