import * as React from 'react'
import useClickOutside from '~hooks/useClickOutside'
import useWebsite from '~hooks/useWebsite'
import { Link } from '~sections/common/SectionLink'
import { TNavItem } from '~types/Website.types'

type TMenuItem = {
	menuItem: TNavItem
	disableLinks?: boolean
}

const MenuItem: React.FC<TMenuItem> = ({ menuItem, disableLinks }) => {
	switch (menuItem.type) {
		case 'internalLink':
			return (
				<InternalLinkItem menuItem={menuItem} disableLinks={disableLinks} />
			)

		case 'externalLink':
			return (
				<ExternalLinkItem menuItem={menuItem} disableLinks={disableLinks} />
			)

		case 'menu':
			return <MenuLinkItem menuItem={menuItem} disableLinks={disableLinks} />

		default:
			return null
	}
}

export default MenuItem

const ExternalLinkItem: React.FC<TMenuItem> = ({ menuItem, disableLinks }) => {
	let url = menuItem?.url || ''
	if (!(url.startsWith('http') || url.startsWith('//'))) {
		url = '//' + url
	}
	return (
		<a
			className="ExternalLinkItem"
			href={url}
			rel="noopener noreferrer"
			target={menuItem.targetBlank ? '_blank' : '_self'}
			onClick={(e) => {
				if (disableLinks) e.preventDefault()
			}}
		>
			{menuItem.label}
		</a>
	)
}

const InternalLinkItem: React.FC<TMenuItem> = ({ menuItem, disableLinks }) => {
	const website = useWebsite()
	const page = website.pages.find((x) => x.id === menuItem?.pageId)
	if (!page || !page.enabled) return <></>
	return (
		<Link
			className="InternalLinkItem"
			to={`/${page.slug}`}
			onClick={(e) => {
				disableLinks && e.preventDefault()
			}}
		>
			{menuItem.label}
		</Link>
	)
}

const MenuLinkItem: React.FC<TMenuItem> = ({ menuItem, disableLinks }) => {
	const [isOpen, setIsOpen] = React.useState(false)
	const rootRef = React.useRef(null)

	useClickOutside(isOpen, setIsOpen, rootRef)

	return (
		<div className="MenuItem" ref={rootRef}>
			<button
				onClick={() => {
					setIsOpen(!isOpen)
				}}
			>
				<span>{menuItem.label}</span>
				<i className={`fas fa-caret-${isOpen ? 'up' : 'down'}`} />
			</button>
			{isOpen && (
				<div className="children">
					{menuItem?.children?.map((child) => (
						<MenuItem
							key={child.id}
							menuItem={child}
							disableLinks={disableLinks}
						/>
					))}
				</div>
			)}
		</div>
	)
}
