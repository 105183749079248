const boutiqueFilterOptions = {
	gender: [
		{ name: 'Unisexe', value: 'unisex' },
		{ name: 'Pour elle', value: 'female' },
		{ name: 'Pour lui', value: 'male' }
	],
	age: [
		{
			name: 'Nouveau-né',
			value: 'newborn'
		},
		{
			name: 'Jeunes enfants (0-3 ans)',
			value: 'youngchild'
		},
		{
			name: 'Enfant',
			value: 'child'
		},
		{
			name: 'Ado',
			value: 'teenager'
		},
		{
			name: 'Adulte',
			value: 'adult'
		},
		{
			name: 'Pour tous',
			value: 'everyone'
		}
	]
}

export const extractGendersOptions = (products: any[]) => {
	const genders = []
	products.map((prod) => {
		if (
			!genders.includes(prod.informations.gender) &&
			prod.informations.gender
		) {
			genders.push(prod.informations.gender)
		}
	})
	return boutiqueFilterOptions.gender.filter((x) => genders.includes(x.value))
}

export const extractWhoOptions = (products: any[]) => {
	let whos = []
	products.map((prod) => {
		if (prod.informations.who?.length > 0) {
			whos = [...whos, ...prod.informations.who]
		}
	})
	const arr = Array.from(new Set(whos))
	return boutiqueFilterOptions.age.filter((x) => arr.includes(x.value))
}

type TGetCat = {
	categories: any[]
	parentId: string
	parentName: string
}

export const getCategoryInfo = (
	categories: any[],
	categoryId: string | undefined,
	parentCatId?: any,
	parentName?: string
): TGetCat | undefined => {
	if (!categoryId) {
		return {
			categories,
			parentId: parentCatId,
			parentName
		}
	}
	for (const category of categories) {
		if (category.id === categoryId) {
			return {
				categories: category.children,
				parentId: parentCatId ? parentCatId : 'up',
				parentName: category.label
			}
		} else if (category.children) {
			const val = getCategoryInfo(
				category.children,
				categoryId,
				category.id,
				category.label
			)
			if (val) return val
		}
	}

	return undefined
}
