import React from 'react'
import './HeaderRestaurantCover.scss'

const HeaderRestaurantCover = () => {
	return (
		<div className="HeaderRestaurantCover">
			<div className="headerCover--overly"></div>
		</div>
	)
}

export default HeaderRestaurantCover
