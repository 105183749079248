import React, { useEffect, useState } from 'react'
import CheckBox from '~components/Common/CheckBox/CheckBox'
import useWebsite from '~hooks/useWebsite'
import { getActivityContactInfo } from '~queries/activity.queries'
import { TContactSectionParamsComponent } from './ContactSection.types'

const ContactSectionParams: TContactSectionParamsComponent = ({
	params,
	onChange
}) => {
	const website = useWebsite()
	const [activities, setActivities] = useState([])

	useEffect(() => {
		if (website.activitiesId) {
			getActivityContactInfo(website.activitiesId).then((res) => {
				if (Array.isArray(res)) {
					setActivities(res)
				}
			})
		}
	}, [])

	const isEmpty = !(params.activityIds?.length > 0)

	return (
		<div className="ContactSectionParams">
			<div className="title_">Selectionnez des fiches:</div>
			{activities.map((activity) => {
				return (
					<CheckBox
						checked={isEmpty || params.activityIds?.includes(activity._id)}
						key={activity._id}
						label={activity.title}
						onChange={() => {
							if (isEmpty) {
								const ids = activities
									.filter((x) => x._id !== activity._id)
									.map((x) => x._id)
								onChange({
									...params,
									activityIds: ids
								})
							} else {
								if (params.activityIds?.includes(activity._id)) {
									onChange({
										...params,
										activityIds: params.activityIds.filter(
											(x) => x !== activity._id
										)
									})
								} else {
									onChange({
										...params,
										activityIds: [...params.activityIds, activity._id]
									})
								}
							}
						}}
					/>
				)
			})}
		</div>
	)
}

export default ContactSectionParams
