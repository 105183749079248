import React from 'react'

import './Params.scss'

const Params: TParams = ({ children, className = '' }) => {
	return <div className={`Params ${className}`}>{children}</div>
}
export default Params

type TParams = (props: { children?: any; className?: string }) => JSX.Element
