import React from 'react'

// import { Link } from '~sections/common/SectionLink'
import MenuItem from '~components/Template/common/MenuItem'

import './FooterMenu.scss'

type FooterMenuProps = { menu: any[]; disableLinks: boolean; label?: string }
const FooterMenu: React.FC<FooterMenuProps> = ({
	menu,
	disableLinks,
	label
}) => {
	return (
		<div className="FooterMenu">
			<div className="title">{label}</div>
			{menu?.map((menuItem) => (
				<MenuItem
					key={menuItem.id}
					menuItem={menuItem}
					disableLinks={disableLinks}
				/>
			))}
		</div>
	)
}

export default FooterMenu
