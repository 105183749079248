import React from 'react'
import { useGlobalContext } from '~contexts/globalContext'
import { TSectionTheme } from '~types/Website.types'

import './ThemeSelector.scss'

type TThemeSelector = {
	value?: string
	handleChange: (theme: TSectionTheme) => void
}
const ThemeSelector: React.FC<TThemeSelector> = ({ value, handleChange }) => {
	const { website } = useGlobalContext()
	const themes = website.theme.sectionThemes

	return (
		<div className="ThemeSelector">
			{themes.map((theme) => (
				<ThemeItem
					theme={theme}
					key={theme.id}
					active={value === theme?.id}
					onClick={() => {
						handleChange(theme)
					}}
				/>
			))}
		</div>
	)
}

export default ThemeSelector

const ThemeItem = ({
	theme,
	active,
	onClick
}: {
	theme: TSectionTheme
	active: boolean
	onClick: () => void
}) => {
	const { website } = useGlobalContext()

	const { getPaletteColor } = website

	return (
		<button
			className={`ThemeItem${active ? ' active' : ''}`}
			style={{
				background: getPaletteColor(theme.background),
				color: getPaletteColor(theme.text)
			}}
			onClick={() => {
				onClick()
			}}
		>
			<div>
				<span
					className="title"
					style={{
						color: getPaletteColor(theme.title),
						fontFamily: website.theme.typo.h1.fontFamily
					}}
				>
					Titre
				</span>
				<span
					className="text"
					style={{
						fontFamily: website.theme.typo.p.fontFamily
					}}
				>
					Texte
				</span>
			</div>
			<span
				className="button"
				style={{
					background: getPaletteColor(theme?.button.background),
					color: getPaletteColor(theme?.button.color),
					fontFamily: website.theme.typo.p.fontFamily
				}}
			>
				bouton
			</span>
		</button>
	)
}
