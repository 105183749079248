import React, { useState } from 'react'

import BasketIcon from '~components/Icons/BasketIcon'

import { useBasketContext } from '~contexts/basketContext'

import { useGlobalContext } from '~contexts/globalContext'

import { usePageContext } from '~contexts/page/pageContext'

import TopBarMenu from './TopBarMenu/TopBarMenu'
import { Link } from '~sections/common/SectionLink'
import SocialLinks from '../common/SocialLinks'
import { TLogo } from '~types/Website.types'
import BurgerIcon from '../common/BurgerIcon'
import CloseNavIcon from '../common/CloseNavIcon'

import './TopBar.scss'

type TTopBar = {
	disableLinks?: boolean
	editionOverlay?: boolean
	handleEdit?: () => void
	data: any
	style?: any
	logo?: TLogo
}

const TopBar: React.FC<TTopBar> = ({
	disableLinks,
	editionOverlay = true,
	handleEdit,
	data,
	style = {},
	logo
}) => {
	const pageContext = usePageContext()
	const website = useGlobalContext()?.website
	const basket = useBasketContext()

	const textColor = style?.color || website.getPaletteColor(data?.textColor)
	const backgroundColor =
		style?.backgroundColor || website.getPaletteColor(data?.backgroundColor)

	const socials = data?.socials || website?.socials
	const showSocialLinks = [true, false].includes(data?.showSocialLinks)
		? data?.showSocialLinks
		: website?.theme?.topbar?.showSocialLinks

	const menu = data?.menu || []

	const TopBarMenu_ = <TopBarMenu disableLinks={disableLinks} menu={menu} />

	return (
		<>
			{data.fixed && <div className="fix_header_place_holder" />}
			<div
				className={'TopBar' + (data.fixed ? ' fixed_menu' : '')}
				style={{
					backgroundColor,
					color: textColor
				}}
			>
				<TopBarMenuResponsive
					color={textColor}
					backgroundColor={backgroundColor}
				>
					<TopBarBrand
						disableLinks={disableLinks}
						logo={logo || website.theme?.logo}
					/>
					{TopBarMenu_}
				</TopBarMenuResponsive>

				{pageContext.editMode && editionOverlay && (
					<TopBarEditOverLay onClick={handleEdit} />
				)}

				<TopBarBrand
					disableLinks={disableLinks}
					logo={logo || website.theme?.logo}
				/>

				<nav>{TopBarMenu_}</nav>

				<div className="TopBarRight">
					{showSocialLinks && (
						<div className="TopBarSocials">
							<SocialLinks
								disableLinks={disableLinks}
								socials={socials}
								color={textColor}
								bgColor={backgroundColor}
							/>
						</div>
					)}
					<div className={'basket-icon' + (disableLinks ? ' disabled' : '')}>
						{basket?.items?.length > 0 && (
							<Link
								to="/mon-panier"
								style={{ display: 'block' }}
								onClick={(e) => {
									if (disableLinks) {
										e.preventDefault()
									}
								}}
							>
								<span className="count">{basket.items.length}</span>

								<BasketIcon color={textColor} backgroud={backgroundColor} />
							</Link>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default TopBar

type TopBarBrandProps = {
	disableLinks: boolean
	logo?: TLogo
}

export const TopBarBrand: React.FC<TopBarBrandProps> = ({
	disableLinks = false,
	logo
}) => {
	let fontSize = '28px'
	if (logo?.text?.length > 20) fontSize = '20px'
	else if (logo?.text?.length > 13) fontSize = '24px'
	return (
		<Link
			className="TopBarBrand"
			to="/"
			onClick={(e) => {
				if (disableLinks) {
					e.preventDefault()
				}
			}}
			style={{ fontSize }}
		>
			{!logo ? (
				'F&L'
			) : logo.type === 'image' ? (
				<img src={logo.url} />
			) : (
				logo.text
			)}
		</Link>
	)
}

const TopBarEditOverLay = ({ onClick }) => {
	return (
		<button className="TopBarEditOverLay" onClick={onClick}>
			<div>Modifier l'en-tête du site</div>
		</button>
	)
}

const TopBarMenuResponsive: React.FC<{
	color?: string
	backgroundColor?: string
}> = ({ children, color, backgroundColor }) => {
	const [close, setClose] = useState(' close')
	return (
		<div className="TopBarMenuResponsive">
			<button onClick={() => setClose(close === '' ? ' close' : '')}>
				<BurgerIcon color={color} />
			</button>

			<div
				className={'main' + close}
				style={{
					backgroundColor,
					color
				}}
			>
				<button
					className="close_nav"
					onClick={() => setClose(close === '' ? ' close' : '')}
				>
					<CloseNavIcon />
				</button>

				<div className="main_content">{children}</div>
			</div>
		</div>
	)
}
