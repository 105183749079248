import { useEffect, useState } from 'react'
import { TNavItem } from '~types/Website.types'
import { isLink } from '~utils/misc'

const uniqid = require('uniqid')

export const useMenuEditor = (data, onChange?: (menu: any) => void) => {
	const [menu, setMenu] = useState(data)

	const addItem = () => {
		setMenu([
			{
				id: uniqid(),
				type: 'internalLink',
				label: '',
				pageId: '',
				url: ''
			},
			...(menu ? menu : [])
		])
	}

	useEffect(() => {
		onChange?.(menu)
	}, [menu])

	return {
		menu,
		addItem,
		menuIsValid: () => menuIsValid(menu),
		item: (id) => {
			const handleUpdate = (update) => {
				setMenu(
					menu?.map((item) =>
						item.id === id
							? {
									...item,
									...update
							  }
							: item
					)
				)
			}

			const handleRemove = () => {
				setMenu(menu.filter((item) => item.id !== id))
			}

			const handleMoveUp = () => {
				const index = menu.findIndex((_) => _.id === id)

				setMenu([
					...menu.slice(0, index - 1),
					menu[index],
					menu[index - 1],
					...menu.slice(index + 1)
				])
			}
			const canMoveUp = menu.findIndex((_) => _.id === id) > 0
			const handleMoveDown = () => {
				const index = menu.findIndex((_) => _.id === id)

				setMenu([
					...menu.slice(0, index),
					menu[index + 1],
					menu[index],
					...menu.slice(index + 2)
				])
			}
			const canMoveDown = menu.findIndex((_) => _.id === id) < menu.length - 1

			return {
				handleUpdate,
				addItem,
				handleRemove,
				handleMoveUp,
				canMoveUp,
				handleMoveDown,
				canMoveDown
			}
		}
	}
}

export const menuIsValid = (menu: TNavItem | TNavItem[]): boolean => {
	if (!menu) return false
	let rtn = true
	if (Array.isArray(menu)) {
		menu.forEach((item) => {
			if (!menuItemIsValid(item)) {
				rtn = false
			} else if (Array.isArray(item.children)) {
				if (!menuIsValid(item.children)) rtn = false
			}
		})
	} else rtn = menuItemIsValid(menu)
	return rtn
}

const menuItemIsValid = (menu: TNavItem) => {
	return (
		!(
			menu.type === 'externalLink' &&
			(!menu.label?.trim() || !isLink(menu.url))
		) &&
		!(
			menu.type === 'internalLink' &&
			(!menu.label?.trim() || !menu?.pageId?.trim())
		) &&
		!(menu.type === 'menu' && !menu?.label.trim())
	)
}
