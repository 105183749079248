import React from 'react'
import Container from '~components/Common/Container/Container'

import { Link } from '~components/Sections/common/SectionLink'
import useWebsite from '~hooks/useWebsite'
import { useInView } from 'react-intersection-observer'
import { useDataQuery } from '~utils/useDataQuery'
import { getBoutiqueItems } from '~queries/boutique.queries'
import { getPictureURL } from '~utils/cloudinary'

import { TLastArticlesSectionComponent } from './LastArticlesSection.types'

import { getMarketItemMinPrice } from '~utils/market'

import EditableButton from '~components/Edition/Editables/EditableButton/EditableButton'

import './LastArticlesSection.scss'

const LastArticlesSection = ({ articles = [], theme }) => {
	const [detectorRef, isInViewport] = useInView({
		rootMargin: '10000px 0px -50px 0px'
	})

	return (
		<div
			className={`LastArticlesSection ${isInViewport ? 'visible' : ''}`}
			ref={detectorRef}
		>
			<Container>
				<div className="grid">
					<SeeAllItem theme={theme} />
					{articles.map((article) => (
						<ArticleItem article={article} key={article.id} />
					))}
				</div>
			</Container>
		</div>
	)
}

const LastArticlesSectionContrainer: TLastArticlesSectionComponent = ({
	theme
}) => {
	const { activitiesId } = useWebsite()
	const [articles] = useDataQuery(
		async () => getBoutiqueItems(activitiesId),
		'boutique-items',
		[]
	)

	return <LastArticlesSection articles={articles?.slice(0, 7)} theme={theme} />
}

export default LastArticlesSectionContrainer

const SeeAllItem = ({ theme }) => {
	const website = useWebsite()
	return (
		<div className="SeeAllItem">
			<h2 style={{ ...website.theme?.typo?.h2 }}>Les dernières offres</h2>

			<EditableButton field="button" theme={theme?.button} />
		</div>
	)
}

const ArticleItem = ({ article }) => {
	const imageURL = getPictureURL(article.miniature, {
		w: 235,
		h: 210,
		c: 'fill'
	})
	const minPrice = getMarketItemMinPrice(article)
	const priceUnique = !!article.pricing.unique
	return (
		<Link
			className="ArticleItem"
			to={`/a/${article.id}`}
			state={{ item: article, fromResult: true }}
		>
			<div className="image">
				<img
					src={imageURL || '/images/empty.png'}
					onLoad={(e) => {
						// @ts-ignore
						e.target.classList.add('loaded')
					}}
				/>
			</div>
			<div className="title">{article.title}</div>
			<div className="price">
				{priceUnique ? `${minPrice}€` : `À partir de ${minPrice}€`}
			</div>
		</Link>
	)
}
