import React, { useState } from 'react'

import {
	Editor,
	convertToRaw,
	RichUtils,
	ContentState,
	EditorState
} from 'draft-js'
import 'draft-js/dist/Draft.css'

import EditableTextToolBar from './EditableTextToolBar/EditableTextToolBar'

import { TEditableTextComponent } from './EditableText.types'
import { getEditorStateFromRaw } from './EditableText.utils'
import { usePageContext } from '~contexts/page/pageContext'

import { useSectionContext } from '../../SectionEditable/SectionContext/SectionContext'
import { generateLoremIpsum } from '~utils/misc'

import './EditableText.scss'
import { useModalWithPayload } from '~components/Common/Modal/Modal'
import LinkEditionModal from './LinkEditionModal/LinkEditionModal'

const EditableText: TEditableTextComponent = ({
	field,
	toolbarConfig = {
		buttons: [
			'bold',
			'italic',
			'underline',
			'link',
			'ordered-list',
			'unordered-list'
		]
	},
	handleRemove
}) => {
	const sectionContext = useSectionContext()
	const value = sectionContext.getField(field)
	const [toobarIsVisible, setToobarIsVisible] = useState(false)
	const [state, setState] = useState({
		editorState: getEditorStateFromRaw(value.raw)
	})
	const linkEditionModal = useModalWithPayload()

	const pageContext = usePageContext()

	const handleChange = (editorState: Draft.EditorState) => {
		setState({ editorState })
		sectionContext.updateField(field, {
			__element: 'editable-text',
			raw: convertToRaw(editorState.getCurrentContent())
		})
	}

	const handleKeyCommand = (command, editorState: Draft.EditorState) => {
		if (!['bold', 'italic'].includes(command)) {
			return
		}

		const newState = RichUtils.handleKeyCommand(editorState, command)

		if (newState) {
			handleChange(newState)
			return 'handled'
		}

		return 'not-handled'
	}

	const handleEditLinkCommand = () => {
		const { editorState } = state
		const selection = editorState.getSelection()
		if (!selection.isCollapsed()) {
			const contentState = editorState.getCurrentContent()
			const startKey = editorState.getSelection().getStartKey()
			const startOffset = editorState.getSelection().getStartOffset()
			const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey)
			const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset)

			let data
			if (linkKey) {
				const linkInstance = contentState.getEntity(linkKey)
				data = linkInstance.getData()
			}
			linkEditionModal.open(data)
		}
	}

	const bindLinkToText = (data) => {
		const { editorState } = state
		const contentState = editorState.getCurrentContent()
		const contentStateWithEntity = contentState.createEntity(
			'LINK',
			'MUTABLE',
			{ ...data }
		)
		const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
		const newEditorState = EditorState.set(editorState, {
			currentContent: contentStateWithEntity
		})
		setState({
			editorState: RichUtils.toggleLink(
				newEditorState,
				newEditorState.getSelection(),
				entityKey
			)
		})
	}

	const handleRemoveLink = () => {
		const { editorState } = state
		const selection = editorState.getSelection()
		if (!selection.isCollapsed()) {
			setState({
				editorState: RichUtils.toggleLink(editorState, selection, null)
			})
		}
		linkEditionModal.close()
	}

	const showToolbar = () => setToobarIsVisible(true)
	const hideToolbar = () => setToobarIsVisible(false)

	return (
		<div className={`EditableText ${toobarIsVisible ? 'active' : ''}`}>
			{pageContext.editMode && (
				<>
					<EditableTextToolBar
						state={state}
						onChange={handleChange}
						isVisible={toobarIsVisible}
						config={toolbarConfig}
						handleRemove={handleRemove}
						editLink={handleEditLinkCommand}
					/>
					<LinkEditionModal
						onChange={bindLinkToText}
						handleRemove={handleRemoveLink}
						{...linkEditionModal}
					/>
				</>
			)}
			<div className="editor">
				<Editor
					readOnly={!pageContext.editMode}
					onFocus={showToolbar}
					onBlur={hideToolbar}
					editorState={state.editorState}
					onChange={handleChange}
					handleKeyCommand={handleKeyCommand}
				/>
			</div>
		</div>
	)
}

export default EditableText

export const editableText = (defaultText = generateLoremIpsum('normal')) => ({
	__element: 'editable-text',
	raw: convertToRaw(ContentState.createFromText(defaultText))
})
