import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SectionPreviewImage from '~sections/common/SectionPreviewImage'

import { TSectionDefinition } from '~types/Section.types'
import HeaderSection from './HeaderSection'
import HeaderSectionParams from './HeaderSection.params'
import defaultData from './HeaderSection.defaultData'
import { THeaderSection } from './HeaderSection.types'

export const headerSectionName = 'header-section'

const headerSection: TSectionDefinition<THeaderSection> = {
	name: headerSectionName,
	Component: HeaderSection,
	Params: HeaderSectionParams,
	categories: ['title'],
	presets: [
		{
			imagePreview: <ImagePreview />,
			defaultData
		}
	]
}

export default headerSection

export const query = graphql`
	query {
		file(relativePath: { eq: "section-preview/header-section.jpg" }) {
			...SectionPreviewImage
		}
	}
`

function ImagePreview({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
