export const getYoutubeIdFromURL = (url: string) => {
	const regex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
	const match = url.match(regex)
	if (match !== null) {
		return match[7]
	}
	return null
}

export const getDailymotionIdFromURL = (url: string) => {
	const regex = /^.+(dailymotion.com|dai.ly)(\/embed)*(\/(video|hub|[\d\w]*))*[\d\w?=]*/
	const match = url.match(regex)
	if (match !== null) return match[4]

	return null
}

//.
export const getVimeoIdFromURL = (url: string) => {
	const regex = /^.*((vimeo.com\/)|(channels\/)|(groups\/)|(documentaryfilm\/)|(musicvideo\/)|(videos\/))([^#&?]*).*/
	const match = url.match(regex)
	if (match !== null) return match[8]
	return null
}
