import React, { useEffect, useState } from 'react'
import Button from '~components/Common/Button/Button'
import { TModalComponent } from '~components/Common/Modal/Modal'
import EditableParamsModal from '~components/Edition/Common/EditableParamsModal/EditableParamsModal'
import { useGlobalContext } from '~contexts/globalContext'

import './LinkEditionModal.scss'

const LinkEditionModal: TModalComponent<{
	onChange: (data: any) => void
	handleRemove: () => void
}> = ({ payload, onChange, handleRemove, ...modalProps }) => {
	const [data, setData] = useState<any>(payload)
	const { website } = useGlobalContext()

	useEffect(() => {
		if (modalProps.isOpen) {
			setData(payload || {})
		} else {
			setData(null)
		}
	}, [modalProps.isOpen])

	const handleConfirm = () => {
		onChange(data)
		modalProps.close()
	}

	const pagesOptions = website.pages
		.filter((x) => x.enabled)
		.map((page) => ({
			name: `/${page.slug}`,
			value: page.id
		}))

	if (!data) return null

	return (
		<EditableParamsModal
			className="LinkEditionModal"
			handleConfirm={handleConfirm}
			{...modalProps}
		>
			<EditableParamsModal.Field
				type="select"
				label="Pour ajouter un lien de redirection au clic sur l'image :"
				selectOptions={[
					{
						name: 'Lien interne',
						value: 'internal-link'
					},
					{
						name: 'URL',
						value: 'external-link'
					}
				]}
				value={data.actionType}
				onChange={(actionType) => {
					setData({
						...data,
						actionType
					})
				}}
			/>
			{data.actionType === 'internal-link' && (
				<EditableParamsModal.Field
					type="select"
					label="Choisissez un lien"
					selectOptions={pagesOptions}
					value={data.pageLinkId}
					onChange={(pageLinkId) => {
						setData({
							...data,
							pageLinkId
						})
					}}
				/>
			)}
			{data.actionType === 'external-link' && (
				<EditableParamsModal.Field
					type="input"
					label="URL de redirection"
					value={data.link}
					onChange={(link) => {
						setData({
							...data,
							link
						})
					}}
				/>
			)}

			{payload && (
				<Button
					style={{
						width: '100%'
					}}
					theme="error"
					textAlign="center"
					onClick={() => {
						handleRemove()
					}}
				>
					Supprimer le lien
				</Button>
			)}
		</EditableParamsModal>
	)
}

export default LinkEditionModal
