import React from 'react'
import Select from '~components/Common/Basics/Select/Select'

import './EditableParamsModalFields.scss'

const EditableParamsModalFields: TEditableParamsModalFields = ({
	children
}) => {
	return <div className="EditableParamsModalFields">{children}</div>
}

export default EditableParamsModalFields

export const EditableParamsModalField = ({
	label,
	placeholder = '',
	type,
	selectOptions = null,
	value,
	onChange
}) => {
	return (
		<div className="EditableParamsModalField">
			<div className="label">{label}</div>
			{type === 'input' ? (
				<input
					className="input"
					value={value}
					placeholder={placeholder}
					onChange={(e) => {
						onChange(e.target.value)
					}}
				/>
			) : (
				<Select
					options={selectOptions}
					value={value}
					onChange={onChange}
					buttonElement={{
						style: {
							width: '100%'
						}
					}}
				/>
			)}
		</div>
	)
}

type TEditableParamsModalFields = React.FC
