import React from 'react'
import { TTitleTextImageButtonBasicSectionComponent } from './TitleTextImageButtonBasicSection.types'
import EditableElement from '~components/Edition/Editables/EditableElement/EditableElement'

import EditableButton from '~components/Edition/Editables/EditableButton/EditableButton'
import EditableImage from '~components/Edition/Editables/EditableImage/EditableImage'
import EditableText from '~components/Edition/Editables/EditableText/EditableText'
import { generateClassNames } from '~utils/misc'

import './TitleTextImageButtonBasicSection.scss'
import Container from '~components/Common/Container/Container'

const TitleTextImageButtonBasicSection: TTitleTextImageButtonBasicSectionComponent = ({
	section,
	theme
}) => {
	const {
		sectionHeight,
		sectionWidth,
		alignText,
		alignHorizontal,
		alignVertical
	} = section.params

	return (
		<div
			className={`Section TitleTextImageButtonBasicSection ${generateClassNames(
				{
					alignText,
					sectionHeight,
					sectionWidth,
					alignVertical,
					alignHorizontal
				}
			)}`}
			style={{
				background: theme?.background,
				color: theme?.text
			}}
		>
			<Container>
				<div className="content-wrapper">
					<div className="__content">
						<EditableElement
							style={{
								color: theme?.title
							}}
							field="title"
							tag="h2"
						/>

						<EditableText field="text" />

						<div className="image-content">
							<EditableImage field="image" />
						</div>

						<EditableButton theme={theme?.button} field="button" />
					</div>
				</div>
			</Container>
		</div>
	)
}

export default TitleTextImageButtonBasicSection
