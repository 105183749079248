import React from 'react'

export const YoutubeIcon = ({ color = '#172b49', bgColor = '#FFFFFF' }) => {
	return (
		<svg
			width="36"
			height="36"
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 512 512"
			xmlSpace="preserve"
		>
			<circle fill={color} cx="256" cy="256" r="256" />

			<path
				fill={bgColor}
				d="M341.649,152.333H170.351c-33.608,0-60.852,27.245-60.852,60.852v85.632
           c0,33.608,27.245,60.852,60.852,60.852h171.298c33.608,0,60.852-27.245,60.852-60.852v-85.632
           C402.501,179.578,375.256,152.333,341.649,152.333L341.649,152.333z M300.494,260.167l-80.12,38.212
           c-2.136,1.019-4.603-0.536-4.603-2.901v-78.814c0-2.4,2.532-3.955,4.67-2.87l80.12,40.601
           C302.947,255.602,302.904,259.019,300.494,260.167L300.494,260.167z"
			/>
		</svg>
	)
}
