import React from 'react'
import { TSectionDefinition } from '~types/Section.types'
import BoutiqueSection from './BoutiqueSection'
import BoutiqueSectionParams from './BoutiqueSection.params'
import defaultData from './BoutiqueSection.defaultData'
import { graphql, useStaticQuery } from 'gatsby'
import { TBoutiqueSection } from './BoutiqueSection.types'
import SectionPreviewImage from '~sections/common/SectionPreviewImage'

export const boutiqueSectionName = 'market-item-section'

const boutiqueSection: TSectionDefinition<TBoutiqueSection> = {
	name: boutiqueSectionName,
	Params: BoutiqueSectionParams,
	Component: BoutiqueSection,
	categories: ['ecommerce'],
	presets: [
		{
			imagePreview: <HeaderSectionPreview name="product" />,
			label: 'Vente de produits',
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					type: 'product'
				}
			}
		},
		{
			imagePreview: <HeaderSectionPreview name="service" />,
			label: 'Vente de services',
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					type: 'service'
				}
			}
		}
	]
}

export default boutiqueSection

export const query = graphql`
	query {
		product: file(relativePath: { eq: "section-preview/boutique.png" }) {
			...SectionPreviewImage
		}

		service: file(relativePath: { eq: "section-preview/boutique.png" }) {
			...SectionPreviewImage
		}
	}
`

function HeaderSectionPreview({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
