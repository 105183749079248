import React from 'react'

import './EditableSeparatorToolbar.scss'

const EditableSeparatorToolbar: TEditableSeparatorToolbar = ({
	handleRemove
}) => {
	return (
		<div className="EditableSeparatorToolbar">
			<button
				onClick={() => {
					handleRemove()
				}}
			>
				<i className="mdi mdi-trash-can-outline" />
			</button>
		</div>
	)
}

export default EditableSeparatorToolbar

type TEditableSeparatorToolbar = React.FC<{
	handleRemove: () => void
}>
