import React, { useEffect, useState } from 'react'
// import BoutiqueEuroIcon from '../icons/BoutiqueEuroIcon'
import BoutiqueSearchIcon from '../icons/BoutiqueSearchIcon'
import BoutiqueUserIcon from '../icons/BoutiqueUserIcon'
import './BoutiqueMenu.scss'
import { getCategoryInfo } from './BoutiqueMenu.utils'
import { BoutiqueMenuFilter } from './BoutiqueMenuFilter/BoutiqueMenuFilter'

import './BoutiqueMenu.scss'
import { usePageContext } from '~contexts/page/pageContext'

const BoutiqueMenu = ({
	filters,
	updateFilters,
	categories = [],
	articles = []
}) => {
	const [catManager, setCatManager] = useState<any>({})

	useEffect(() => {
		if (categories.length === 0) return
		const cat = getCategoryInfo(categories, filters.category)
		if (cat) {
			setCatManager({ ...cat })
		} else {
			setCatManager({
				categories: [],
				parentId: filters.category ? filters.category : undefined
			})
		}
	}, [filters.category, categories])
	const isCategory = categories.filter((x) => x.selected).length > 0
	const { editMode } = usePageContext()

	return (
		<div className="BoutiqueMenu">
			<div className="group">
				<div className="title">Filtres</div>
				<div className="filters">
					<BoutiqueSearchFilter
						filters={filters}
						updateFilters={updateFilters}
					/>
					{/* <BoutiqueMenuFilter label="Budget" icon={<BoutiqueEuroIcon />} /> */}

					<BoutiqueMenuFilter
						label="Pour qui"
						icon={<BoutiqueUserIcon />}
						articles={articles}
						onChange={(filter) => {
							updateFilters({
								...filter
							})
						}}
					/>
				</div>
			</div>
			{!isCategory ? (
				<>
					{editMode && (
						<div className="group">
							<div className="return_btn base">
								<button className="BoutiqueMenuCategory">Catégories</button>
							</div>
							Sur la page de création de vos offres, vous pouvez leur attribuer
							une catégorie propre à votre site.
						</div>
					)}
				</>
			) : (
				<div className="group">
					<div className={'return_btn' + (catManager.parentId ? '' : ' base')}>
						<BoutiqueMenuCategory
							label={
								catManager.parentId ? (
									<>
										<i className="mdi mdi-arrow-left"></i>{' '}
										{catManager.parentName}
									</>
								) : (
									'Catégories'
								)
							}
							active
							onClick={() => {
								updateFilters({
									category:
										catManager.parentId !== 'up' ? catManager.parentId : null
								})
							}}
						/>
					</div>
					<div className="categories">
						{catManager.categories?.map((category) => {
							if (category.selected)
								return (
									<BoutiqueMenuCategory
										label={category.label}
										active={filters.category === category.id}
										key={category.id}
										onClick={() => {
											updateFilters({ category: category.id })
										}}
									/>
								)
							return null
						})}
					</div>
				</div>
			)}
		</div>
	)
}

export default BoutiqueMenu

const BoutiqueMenuCategory = ({ label, active, onClick }) => (
	<button
		className={`BoutiqueMenuCategory ${active ? 'active' : ''}`}
		onClick={onClick}
	>
		{label}
	</button>
)

const BoutiqueSearchFilter = ({ filters, updateFilters }) => {
	return (
		<div className="BoutiqueSearchFilter">
			<input
				placeholder="Rechercher"
				value={filters.search}
				onChange={(e) => {
					updateFilters({ search: e.target.value })
				}}
			/>
			<button>
				<BoutiqueSearchIcon />
			</button>
		</div>
	)
}
