/*
 * Functions used for page editions.
 */
import useRefresh from '~hooks/useRefresh'
import { usePageContext } from './pageContext'

const usePageEditor = () => {
	const { dataRef, setModified } = usePageContext()

	const [refresh] = useRefresh()

	const handleChange = () => {
		setModified(true)
	}

	// Move section to index
	const moveSection = (index, delta) => {
		const {id} = dataRef.current.sections[index]

		if (
			index + delta < 0 ||
			index + delta > dataRef.current.sections.length - 1
		)
			return

		const cur = dataRef.current.sections[index]

		dataRef.current.sections[index] = dataRef.current.sections[index + delta]
		dataRef.current.sections[index + delta] = cur

		refresh()

		window.requestAnimationFrame(() => {
			const div = document.getElementById(id)
			if (!div) return

			window.scrollTo({
				top: div.offsetTop - 40,
				behavior: 'smooth'
			})
		})
	}

	const onChange = (index) => (data, doRefresh = false) => {
		dataRef.current.sections[index] = {
			...dataRef.current.sections[index],
			...data
		}
		handleChange()

		if (doRefresh) {
			refresh()
		}
	}

	const handleAddBefore = (index) => (data) => {
		dataRef.current.sections = [
			...dataRef.current.sections.slice(0, index),
			data,
			...dataRef.current.sections.slice(index)
		]
		refresh()
		handleChange()
	}

	const handleAddAfter = (index) => (data) => {
		dataRef.current.sections = [
			...dataRef.current.sections.slice(0, index + 1),
			data,
			...dataRef.current.sections.slice(index + 1)
		]
		refresh()
		handleChange()
	}

	const handleRemove = (index) => () => {
		const section = dataRef.current.sections[index]

		dataRef.current.sections = dataRef.current.sections.filter(
			(_) => _.id !== section.id
		)
		refresh()
		handleChange()
	}

	const handleMoveSectionDown = (index) => () => {
		moveSection(index, 1)
		handleChange()
	}

	const handleMoveSectionUp = (index) => () => {
		moveSection(index, -1)
		handleChange()
	}

	return {
		section: (section) => {
			const index = dataRef.current.sections.findIndex(
				({ id }) => section.id === id
			)

			const canMoveDown = index < dataRef.current.sections.length - 1
			const canMoveUp = index > 0

			return {
				onChange: onChange(index),
				handleAddBefore: handleAddBefore(index),
				handleAddAfter: handleAddAfter(index),
				handleRemove: handleRemove(index),
				handleMoveSectionDown: handleMoveSectionDown(index),
				handleMoveSectionUp: handleMoveSectionUp(index),
				canMoveDown,
				canMoveUp
			}
		}
	}
}

export default usePageEditor
