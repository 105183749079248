import React, { useEffect, useState, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

import { OSMap } from '~components/Common/Map/OSMap'

import ContactSectionInformations from './ContactSectionInformations/ContactSectionInformations'
import ContactSectionSchedule from './ContactSectionSchedule/ContactSectionSchedule'

import { getActivityContactInfo } from '~queries/activity.queries'
import useWebsite from '~hooks/useWebsite'
import { TContactSectionComponent } from './ContactSection.types'

import './ContactSection.scss'
import { postEditorMessage, usePageContext } from '~contexts/page/pageContext'
import { useNotificationContext } from '~components/Common/Notification/Notification'

const ContactSection: TContactSectionComponent = ({ section, theme }) => {
	const [detectorRef, isInViewport] = useInView({
		rootMargin: '100000px 0px -100px 0px'
	})

	const website = useWebsite()

	const activitiesRef = useRef<any[]>([])
	const [activities, setActivities] = useState([])
	const [state, setState] = useState<{
		loaded: boolean
		locations?: any[]
	}>({
		loaded: false,
		locations: []
	})

	useEffect(() => {
		if (website.activitiesId) {
			getActivityContactInfo(website.activitiesId.filter(id => id!=="608389bbaa084b081edcd3b5")).then((res) => {
				const tabs = []
				res.map((activity) => {
					tabs.push({
						...activity.address?.location,
						title: activity.title
					})
				})
				if (res.length > 0) orderShedules(res)

				activitiesRef.current = res

				setState({
					loaded: true,
					locations: tabs
				})
			})
		} else {
			setState({
				loaded: true,
				locations: [
					{
						lat: 45.75,
						lng: 4.85,
						title: 'Ici'
					}
				]
			})
		}
	}, [])

	const { loaded, locations } = state

	useEffect(() => {
		if (activitiesRef.current.length > 0) {
			if (section.params.activityIds?.length > 0) {
				setActivities(
					activitiesRef.current.filter((x) =>
						section.params.activityIds.includes(x._id)
					)
				)
			} else {
				setActivities(activitiesRef.current)
			}
		}
	}, [loaded, section.params.activityIds])

	const btnColor = website.getPaletteColor(theme?.button?.color)
	const btnBackgroundColor = website.getPaletteColor(theme?.button?.background)
	const { editMode } = usePageContext()
	const notifiction = useNotificationContext()

	const scheduleUrl = 'https://activite.coq-trotteur.com/fiche'
	const contactUrl = 'https://activite.coq-trotteur.com/fiche/apidae/'
	const hasMany = activities?.length > 1
	return (
		<div
			className={`ContactSection ${isInViewport ? 'active' : ''} display-${
				section?.params?.display
			}`}
			style={{ background: theme?.background, color: theme?.text }}
			ref={detectorRef}
		>
			<div className="text">
				<div>
					{activities.map((fiche, i) => {
						return (
							<div key={i} className="activity_item">
								<div className={'content' + (editMode ? ' editmode' : '')}>
									{editMode && (
										<a
											className="edit"
											onClick={() => {
												if (!fiche.apidae.editable) {
													return notifiction?.push?.({
														theme: 'warning',
														text:
															'Vous ne pouvez malheureusement pas modifier ces données vous-même. Seul votre Office de Tourisme peut modifier votre fiche. Contactez-les directement pour leur demander les modifications souhaitées.'
													})
												}
												postEditorMessage('GOTO', {
													url: contactUrl + fiche.apidae.id + '?ref=edition'
												})
											}}
										>
											<i className="mdi mdi-lead-pencil" />
										</a>
									)}
									<ContactSectionInformations
										data={fiche}
										colors={{ btnColor, btnBackgroundColor }}
										title={
											hasMany ? (
												<>
													Contact de <u>{fiche.title}</u>
												</>
											) : undefined
										}
										typo={website.theme?.typo?.h3}
									/>
								</div>
								<br />

								{fiche.updt?.show !== false && (
									<div className={'content' + (editMode ? ' editmode' : '')}>
										{editMode && (
											<a
												className="edit"
												onClick={() => {
													postEditorMessage('GOTO', {
														url:
															scheduleUrl +
															(fiche.updt?.merged
																? ''
																: '/schedules/' + fiche._id + '?ref=edition')
													})
												}}
											>
												<i className="mdi mdi-lead-pencil" />
											</a>
										)}
										{fiche.schedules?.enabled ? (
											fiche.schedules?.schedules_table?.map((schedule, i) => {
												return (
													<ContactSectionSchedule
														key={i}
														data={schedule}
														title={
															hasMany ? (
																<>
																	Horaires de{' '}
																	<u>
																		{fiche.updt?.title || fiche.schedules.title}
																	</u>
																</>
															) : undefined
														}
														typo={website.theme?.typo?.h3}
													/>
												)
											})
										) : (
											<h3 style={{ ...website.theme?.typo?.h3 }}>
												Fermé temporairement
											</h3>
										)}
									</div>
								)}
							</div>
						)
					})}
				</div>
			</div>
			<div className="map">
				{loaded && (
					<OSMap
						center={locations[0]}
						markers={locations}
						options={{ gestureHandling: 'no-scroll-no-warning' }}
					/>
				)}
			</div>
		</div>
	)
}

export default ContactSection

const orderShedules = (shdls: any[]) => {
	const editedIds = []
	for (let i = shdls.length - 1; i >= 0; i--) {
		for (const schl of shdls) {
			if (schl._id !== shdls[i]._id && !editedIds.includes(shdls[i]._id)) {
				const hasEqualSchedules =
					JSON.stringify(schl.schedules) === JSON.stringify(shdls[i].schedules)
				if (hasEqualSchedules) {
					// Update the current schedule (do not detroy the old value if exist)
					if (!shdls[i].updt) {
						shdls[i].updt = {}
					}
					shdls[i].updt.show = true
					shdls[i].updt.merged = true
					if (!shdls[i].updt.title) {
						shdls[i].updt.title = shdls[i].title + ', ' + schl.title
					} else {
						shdls[i].updt.title += ', ' + schl.title
					}
					// hide the other schedule
					schl.updt = {}
					schl.updt.show = false
					schl.updt.merged = true
				} else {
					schl.updt = {}
					schl.updt.show = true
					schl.updt.merged = false
					schl.updt.title = schl.title
				}
				editedIds.push(schl._id)
			}
		}
		editedIds.push(shdls[i]._id)
	}
	return shdls
}
