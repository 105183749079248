import { gql } from 'apollo-boost'
import { doGraphqlQuery } from './common.queries'

export const getActivity = async (id: string) => {
	const query = gql`
    query FetchActivityQuery($id: ID!) {
        fetchActivity(id: $id) {
            ${activityFragment}
        }
    }
`

	const variables = {
		id
	}

	const { fetchActivity } = await doGraphqlQuery({
		query,
		variables,
		apiVersion: 2
	})

	return fetchActivity
}

export const getActivityContactInfo = async (ids: string[]) => {
	const query = gql`
    query FetchActivitiesByIds($ids: [ID!]!) {
        fetchActivitiesByIds(ids: $ids) {
            _id
            title
            apidae {
                id
                failed
                editable
            }
            ${address}
            ${contact}
            ${schedules}
        }
    }
`

	const variables = {
		ids
	}

	const { fetchActivitiesByIds } = await doGraphqlQuery({
		query,
		variables,
		apiVersion: 2
	})

	return fetchActivitiesByIds
}

export const getActivityContact = async (id: string) => {
	const query = gql`
    query FetchActivityQuery($id: ID!) {
        fetchActivity(id: $id) {
            ${contact}
        }
    }
`

	const variables = {
		id
	}

	const { fetchActivity } = await doGraphqlQuery({
		query,
		variables,
		apiVersion: 2
	})

	return fetchActivity
}

const address = `address {
    postal_code
    locality
    country
    full_address
    place_id
    location {
        lng
        lat
    }
}`
const contact = `
contacts {
    mail
    phone
    socials {
        instagram
        twitter
        facebook
    }
    website
}`

const schedules = `
schedules {
    enabled
    same_schedule
    description
    closed_periods {
        start_date
        end_date
    }
    schedules_table {
        start_date
        end_date
        global
        days {
            name
            start {
                hours
                minutes
            }
            end {
                hours
                minutes
            }
            start_pause {
                hours
                minutes
            }
            end_pause {
                hours
                minutes
            }
            options {
                closed
                has_pause
            }
        }
    }
}
`

const activityFragment = `
id
slug
miniature {
    public_id
    url
}
free
title
min_price
reservable
description
duration {
    days
    hours
    minutes
}
ticketing {
    enabled
    done
    enabled_date
}
constraints {
    id
    name
    parent {
        id
        name
    }
    sub_constraint
}
story_telling {
    enabled
    story
}
gallery {
    url
    public_id
}
about {
    strong_points
    note {
        description
        send_mail
    }
    inclusions {
        enabled
        has
        hasnt
    }
}
informations {
    atYouDisposal {
        name
        value
        type
    }
    keepOnMind {
        name
        value
        type
    }
    outdoorFacilities {
        name
        value
        type
    }
    languages {
        name
        value {
            name
        }
        type
    }
    paymentMethod {
        name
        value
        type
    }
    parkingFacilities {
        name
        value
        type
    }
    theme {
        name
        value
        type
    }
    visits {
        name
        value
        type
    }
    labels {
        name
        value
        type
        name_eng
        ui_id
        category
    }
    foodProducts {
        name
        value
        type
    }
    equestrianActivities {
        name
        value
        type
    }
}
${address}

accessibility {
    indoor
    restrictions
    ticket {
        type
        line_cutting
    }
    public_transports {
        accessibility
        informations {
            bus
            tram
            subway
        }
    }
    starting_location {
        enabled
        address
        note
    }
}
${contact}
${schedules}
booking {
    confirmation_method
    confirmation_mail
    cancellation {
        unique
        type
        weather
    }
    informations {
        phone
        mail
    }
    each_person_identity
    allow_message
}

pricings {
    enabled
    same_pricing
    informations
    pricings_table {
        id
        name
        description
        cancellation_type
        confirmation_method
        schedule_type
        time_limit
        enabled_date
        starting_location {
            enabled
            address
            note
        }
        lang {
            force
            selection
        }
        spots {
            set
            max
        }
        inclusions {
            has
            hasnt
        }
        tva
        pricings {
            selling_price
            final_price
            name
        }
        options {
            selling_price
            final_price
            name
        }
        schedules {
            same
            list {
                lang
                closed_periods {
                    start_date
                    end_date
                }
                use_global_closed_periods
                unique_period
                periods {
                    start_date
                    end_date
                    days {
                        name
                        slots {
                            start {
                                hours
                                minutes
                            }
                            end {
                                hours
                                minutes
                            }
                        }
                    }
                }
            }
        }
    }
}
`
