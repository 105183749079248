import React from 'react'
import Container from '~components/Common/Container/Container'
import { TCitationTextSectionComponent } from './CitationTextSection.types'
import EditableElement from '~components/Edition/Editables/EditableElement/EditableElement'

import EditableText from '~components/Edition/Editables/EditableText/EditableText'

import './CitationTextSection.scss'
import { generateClassNames } from '~utils/misc'

const CitationTextSection: TCitationTextSectionComponent = ({
	section,
	theme
}) => {
	const {
		backgroundImage,
		sectionHeight,
		sectionWidth,
		alignText,
		alignVertical,
		alignHorizontal
	} = section.params

	const items = section.data.items.map((item, index) => {
		return (
			<div className="list-item-content" key={index}>
				<div className="item-content-text">
					<EditableElement field={`items.${index}.title`} tag="h3" />
					<EditableText field={`items.${index}.text`} />
				</div>
			</div>
		)
	})

	return (
		<div
			className={`Section CitationTextSection  ${generateClassNames({
				alignText,
				sectionHeight,
				sectionWidth,
				alignVertical,
				alignHorizontal
			})}`}
			style={{
				background: backgroundImage
					? `url("${backgroundImage}")`
					: theme?.background,
				color: theme?.text
			}}
		>
			<Container>
				<div className="content-wrapper">
					<div className="__content">
						<div className="list-content">{items}</div>
					</div>
				</div>
			</Container>
		</div>
	)
}

export default CitationTextSection
