import React from 'react'
import './AddBasketModal.scss'

const AddBasketModal = ({ active, value, onChange }) => {
	return (
		<div className={`AddBasketModal ${active ? 'active' : ''}`}>
			<div className="modal-content">
				<div className="basketModal--image">
					<img src={value.image} />
				</div>
				<span className="button-close" onClick={() => onChange(false)}>
					<i className="mdi mdi-close"></i>
				</span>
				<div className="basketModal--details">
					<h3 className="basketProduct__title">{value.title}</h3>
					<p className="basketProduct__desc">{value.description}</p>
					<div className="sectionOption">
						<div className="sectionOption__title">
							Section 1 <span className="sectionOption__type">Obligatoire</span>
						</div>
						<div className="sectionOption--values">
							<div className="option--item">
								<span className="option__button">
									<svg
										width="22"
										height="22"
										viewBox="0 0 22 22"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<circle
											cx="11"
											cy="11"
											r="9.5"
											stroke="#5F6777"
											strokeOpacity="0.5"
											strokeWidth="3"
										/>
									</svg>
								</span>
								<span className="option__desc">Options 1</span>
							</div>
							<div className="option--item">
								<span className="option__button">
									<svg
										width="22"
										height="22"
										viewBox="0 0 22 22"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<circle
											cx="11"
											cy="11"
											r="7"
											stroke="#FF658A"
											strokeOpacity="0.95"
											strokeWidth="8"
										/>
									</svg>
								</span>
								<span className="option__desc">Options 2</span>
							</div>
							<div className="option--item">
								<span className="option__button">
									<svg
										width="22"
										height="22"
										viewBox="0 0 22 22"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<circle
											cx="11"
											cy="11"
											r="9.5"
											stroke="#5F6777"
											strokeOpacity="0.5"
											strokeWidth="3"
										/>
									</svg>
								</span>
								<span className="option__desc">Options 3</span>
								<span className="option__price">+1,50€</span>
							</div>
						</div>
					</div>
					<div className="sectionOption">
						<div className="sectionOption__title">
							Section 2 <span className="sectionOption__type">Optionnel</span>
						</div>
						<div className="sectionOption--values">
							<div className="option--item">
								<span className="option__button">
									<svg
										width="22"
										height="22"
										viewBox="0 0 22 22"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<rect
											width="22"
											height="22"
											rx="4"
											fill="#FF658A"
											fillOpacity="0.95"
										/>
										<path
											d="M8.44912 14.4207L5.13019 11.1018L4 12.224L8.44912 16.6731L18 7.12223L16.8778 6L8.44912 14.4207Z"
											fill="white"
											stroke="white"
										/>
									</svg>
								</span>
								<span className="option__desc">Options 1</span>
								<span className="option__price">3,50€</span>
							</div>
							<div className="option--item">
								<span className="option__button">
									<svg
										width="22"
										height="22"
										viewBox="0 0 22 22"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<rect
											x="1.5"
											y="1.5"
											width="19"
											height="19"
											rx="2.5"
											stroke="#5F6777"
											strokeOpacity="0.5"
											strokeWidth="3"
										/>
									</svg>
								</span>
								<span className="option__desc">Options 2</span>
								<span className="option__price">0,50€</span>
							</div>
							<div className="option--item">
								<span className="option__button">
									<svg
										width="22"
										height="22"
										viewBox="0 0 22 22"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<rect
											x="1.5"
											y="1.5"
											width="19"
											height="19"
											rx="2.5"
											stroke="#5F6777"
											strokeOpacity="0.5"
											strokeWidth="3"
										/>
									</svg>
								</span>
								<span className="option__desc">Options 3</span>
								<span className="option__price">1,40€</span>
							</div>
						</div>
						<div className="hr-separator"></div>
						<div className="button-actions">
							<div className="actions-count">
								<span className="count__iconLeft">
									<i className="mdi mdi-minus"></i>
								</span>
								<span className="count__number">2</span>
								<span className="count__iconRight">
									<i className="mdi mdi-plus"></i>
								</span>
							</div>
							<span className="button-count-left">
								<i className="mdi mdi-minus"></i>
							</span>
							<span className="button-add">
								En ajouter 1 au panier
								<span>4,50€</span>
							</span>
							<span className="button-count-right">
								<i className="mdi mdi-plus"></i>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AddBasketModal
