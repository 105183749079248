import React from 'react'
import { useGlobalContext } from '~contexts/globalContext'
import { TSectionTheme } from '~types/Website.types'
import sections from '../../../core/sections'
import SectionContext from './SectionContext/SectionContext'
import { TSectionTestProps } from './SectionStatic.type'

const sectionMap = sections

const SectionStatic = ({ section }: TSectionTestProps) => {
	const globalContext = useGlobalContext()

	const { getPaletteColor, getSectionTheme } = globalContext.website

	if (!sectionMap[section.type]) return <></>

	const { Component } = sectionMap[section.type]

	const currentTheme = section.params?.theme
	const _theme = getSectionTheme(currentTheme)

	const theme: TSectionTheme = {
		..._theme,
		title: getPaletteColor(_theme?.title),
		text: getPaletteColor(_theme?.text),
		background: getPaletteColor(_theme?.background),
		button: {
			background: getPaletteColor(_theme?.button?.background),
			color: getPaletteColor(_theme?.button?.color)
		}
	}

	return (
		<SectionContext sectionId={section.id}>
			<Component
				section={section}
				globalContext={globalContext}
				theme={theme}
			/>
		</SectionContext>
	)
}

export default SectionStatic
