import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SectionPreviewImage from '~sections/common/SectionPreviewImage'

import { TSectionDefinition } from '~types/Section.types'
import defaultData from './TitleButtonSection.defaultData'
import TitleButtonSection from './TitleButtonSection'
import TitleButtonSectionParams from './TitleButtonSection.params'
import { TTitleButtonSection } from './TitleButtonSection.types'

const titleTextButtonSection: TSectionDefinition<TTitleButtonSection> = {
	name: 'title-button-section',
	Component: TitleButtonSection,
	Params: TitleButtonSectionParams,
	categories: ['title'],
	presets: [
		{
			imagePreview: <HeaderSectionPreview name="center" />,
			defaultData
		},
		{
			imagePreview: <HeaderSectionPreview name="left" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					alignText: 'left',
					backgroundImage:
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617901847/antony-bec-nD9tEn63suc-unsplash_fzxc1s.png'
				}
			}
		},
		{
			imagePreview: <HeaderSectionPreview name="right" />,
			defaultData: {
				...defaultData,
				params: {
					...defaultData.params,
					alignText: 'right',
					backgroundImage:
						'https://res.cloudinary.com/duopq85lp/image/upload/v1617901842/leonard-cotte-R5scocnOOdM-unsplash_1_pppnnh.png'
				}
			}
		}
	]
}

export default titleTextButtonSection

export const query = graphql`
	query {
		center: file(
			relativePath: { eq: "section-preview/title-button-center-section.PNG" }
		) {
			...SectionPreviewImage
		}
		left: file(
			relativePath: { eq: "section-preview/title-button-left-section.PNG" }
		) {
			...SectionPreviewImage
		}
		right: file(
			relativePath: { eq: "section-preview/title-button-right-section.PNG" }
		) {
			...SectionPreviewImage
		}
	}
`

function HeaderSectionPreview({ name = 'file' }) {
	const data = useStaticQuery(query)
	return <SectionPreviewImage data={data[name]} />
}
