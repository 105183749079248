import React from 'react'

import './ParamAlignVertical.scss'

const ParamAlignVertical = ({ options, label, value, onChange }) => {
	return (
		<section className="ParamAlignVertical">
			<div className="label">{label}</div>

			<div className="options">
				{options.map((option) => (
					<button
						key={option.value}
						className={value === option.value ? 'active' : ''}
						onClick={() => {
							onChange(option.value)
						}}
					>
						<i className={`mdi ${option.label}`}></i>
					</button>
				))}
			</div>
		</section>
	)
}

export default ParamAlignVertical
