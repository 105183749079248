import { TWebsiteThemeTypo } from '~types/Website.types'

export const getWebsiteTypefacesLinkHref = (font: any) => {
	return `https://fonts.googleapis.com/css2?family=${
		font.name
	}:wght@${font.weights.join(';')}&display=swap`
}

export const getWebsiteTypefaces = (typo: TWebsiteThemeTypo) => {
	const typos: string[] = []
	Object.values(typo).forEach((elt) => {
		if (!typos.includes(elt.fontFamily)) typos.push(elt.fontFamily)
	})

	return typos
}
